import React from 'react'
import { FormattedNumber } from 'react-intl';
type Props = {
    value?: number
}

const Percent = ({ value = 0 }: Props) => {
    return (
        <>
            {/* eslint-disable-next-line react/style-prop-object */}
            <FormattedNumber value={value * 100} maximumFractionDigits={2} />%
        </>
    )
}

export default Percent