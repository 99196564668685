import { useLayoutEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import ChartTheme from "../../../theme/amcharts";

type IDataSourceItem = {
  age: number;
  year: number;
  rate: number;
};

type Props = {
  data: IDataSourceItem[];
};

const AverageRecoveryChart = ({ data }: Props) => {
  useLayoutEffect(() => {
    let root = am5.Root.new("averageRecoveryChart");

    root.setThemes([am5themes_Animated.new(root), ChartTheme.new(root)]);

    let chart = root.container.children.push(am5xy.XYChart.new(root, {}));

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        numberFormat: "#'%'",
        maxPrecision: 0,
        renderer: am5xy.AxisRendererY.new(root, {
          strokeOpacity: 0.5,
          strokeWidth: 1,
        }),
      })
    );

    let xRenderer = am5xy.AxisRendererX.new(root, {
      strokeOpacity: 0.5,
      strokeWidth: 1,
      minGridDistance: 12,
    });
    xRenderer.grid.template.set("visible", false);

    let xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        maxPrecision: 0,
        min: 1,
        max: 12,
        renderer: xRenderer,
      })
    );

    let series = chart.series.push(
      am5xy.LineSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "rate",
        valueXField: "age",

        tooltip: am5.Tooltip.new(root, {
          labelText: "{valueY}",
        }),
      })
    );

    series.strokes.template.setAll({
      strokeWidth: 3,
    });

    series.fills.template.setAll({
      fillOpacity: 0.3,
      visible: true,
    });

    series.set("fill", am5.color("#287A92"));
    series.set("stroke", am5.color("#287A92"));

    series.data.processor = am5.DataProcessor.new(root, {
      numericFields: ["value"],
      dateFields: ["date"],
      dateFormat: "yyyy-MM-dd",
    });

    series.data.setAll(data);

    let cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "none",
      })
    );
    cursor.lineY.set("visible", false);

    return () => {
      root.dispose();
    };
  });

  return (
    <div
      id="averageRecoveryChart"
      style={{ width: "100%", height: "400px" }}
    ></div>
  );
};

export default AverageRecoveryChart;
