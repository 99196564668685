import React from 'react'
import { FormattedNumber } from 'react-intl';
type Props = {
    value?: number,
    currency?: string
}

function Money({ value = 0, currency = "EUR" }: Props) {
    return (
        <FormattedNumber
            value={value}
            // eslint-disable-next-line react/style-prop-object
            style="currency"
            currency={currency}
            maximumFractionDigits={2}
        />
    )
}

export default Money