import React, { useLayoutEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import { Box } from "@chakra-ui/react";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import ChartsTheme from "../../theme/amcharts";
import {
  IDeploymentEmployeesValue,
  IDeploymentIndustriesValue,
  IDeploymentTurnoverValue,
} from "../types";

type Props = {
  by: string;
  label: string;
  data:
    | IDeploymentEmployeesValue[]
    | IDeploymentIndustriesValue[]
    | IDeploymentTurnoverValue[];
  onSliceClick?: (segment: string | undefined) => void;
  industry?: string;
  employees?: string;
  turnover?: string;
};

function DistributionOfDeploymentChart({
  by,
  label,
  data,
  onSliceClick,
  industry,
  employees,
  turnover,
}: Props) {
  const chartContainerId = `distributionOfDeploymentBy${by}`;
  const legendContainerId = `distributionOfDeploymentBy${by}Legend`;

  useLayoutEffect(() => {
    let root = am5.Root.new(chartContainerId);
    let legendRoot = am5.Root.new(legendContainerId);

    root.setThemes([am5themes_Animated.new(root), ChartsTheme.new(root)]);
    legendRoot.setThemes([am5themes_Animated.new(root), ChartsTheme.new(root)]);

    let chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        radius: am5.percent(80),
        innerRadius: am5.percent(73),
        layout: root.horizontalLayout,
      })
    );

    let series = chart.series.push(
      am5percent.PieSeries.new(root, {
        categoryField: "translated_bucket",
        valueField: "percent",
        legendLabelText: "{percent}%",
        legendValueText: "{translated_bucket}",
      })
    );

    series.ticks.template.set("visible", false);
    series.labels.template.set("visible", false);

    let bgColor = root.interfaceColors.get("background");

    series.slices.template.setAll({
      tooltipText:
        "[fontSize: 14px]{translated_bucket}[/] \n[bold]{percent}%[/]",
      templateField: "columnSettings",
      stroke: bgColor,
      strokeWidth: 2,
    });

    if (onSliceClick && data.length > 1) {
      series.slices.template.events.on("click", function (ev) {
        series.slices.each(function (slice) {
          if (slice !== ev.target && slice.get("active")) {
            slice.set("active", false);
          }
        });
        const dataContext = ev.target?.dataItem?.dataContext as
          | IDeploymentEmployeesValue
          | IDeploymentIndustriesValue
          | IDeploymentTurnoverValue;
        const clickedSegment = !ev.target.get("active")
          ? dataContext.bucket.toLowerCase()
          : undefined;
        onSliceClick(clickedSegment);
      });
    }

    series.slices.template.adapters.add("active", function (active, target) {
      if (data.length <= 1) {
        return false;
      }
      const sliceTarget = target as am5.Slice | undefined;
      const dataContext = sliceTarget?.dataItem?.dataContext as {
        bucket: string;
        percent: number;
        translated_bucket: string;
        by: "employees" | "industry" | "turnover";
      };
      if (
        (dataContext.bucket === industry && dataContext.by === "industry") ||
        (dataContext.bucket === employees && dataContext.by === "employees") ||
        (dataContext.bucket === turnover && dataContext.by === "turnover")
      ) {
        return true;
      }
      return false;
    });

    series.data.setAll(data);

    chart.seriesContainer.children.push(
      am5.Label.new(root, {
        textAlign: "center",
        centerY: am5.p50,
        centerX: am5.p50,
        text: `[fontWeight:600]${label}[/]`,
      })
    );

    let legend = legendRoot.container.children.push(
      am5.Legend.new(legendRoot, {
        centerY: am5.percent(50),
        y: am5.percent(50),
        x: am5.percent(0),
        layout: root.verticalLayout,
        width: am5.percent(100),
        clickTarget: "none",
      })
    );

    legend.labels.template.setAll({
      fontSize: 12,
      fontWeight: "300",
      width: 30,
      textAlign: "right",
    });

    legend.valueLabels.template.setAll({
      forceHidden: false,
      fontSize: 12,
      fontWeight: "600",
      maxWidth: 150,
      oversizedBehavior: "truncate",
    });

    legend.data.setAll(series.dataItems);

    return () => {
      root.dispose();
      legendRoot.dispose();
    };
  });

  return (
    <>
      <Box
        width="100%"
        height="100%"
        display="flex"
        flexWrap="wrap"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          width={{ base: "100%", "2xl": "50%" }}
          height="300px"
          id={chartContainerId}
        />
        <Box width={{ base: "100%", "2xl": "50%" }}>
          <Box
            height="300px"
            px={{ base: "10px !important", "2xl": "0" }}
            id={legendContainerId}
          />
        </Box>
      </Box>
    </>
  );
}

export default DistributionOfDeploymentChart;
