import { useIntl } from "react-intl";
import { Text, Box } from "@chakra-ui/react";
import CohortBalanceChart from "../charts/CohortBalanceChart";
import { useGetRepaymentByAgeCohortQuery } from "../service";
import { ICohortBalanceParams } from "../types";
import { useContext } from "react";
import { AccessTokenContext } from "../../context/contextProvider";
import { IResolution } from "../../bucket/types";
import { getPlottableData } from "./dataSource";
import Loader from "../../shared/Loader";

type Props = {
  params: ICohortBalanceParams & { resolution?: IResolution };
};

const CohortBalance = ({ params }: Props) => {
  const intl = useIntl();
  const accessToken = useContext(AccessTokenContext).accessToken;

  const { resolution, ...queryParams } = params;

  const { data, isFetching } = useGetRepaymentByAgeCohortQuery({
    ...queryParams,
    accessToken,
  });

  const plottableData = data?.data ? getPlottableData(data.data) : [];

  return (
    <Box>
      <Text fontSize="14px" fontWeight="600" px="6" pt="4">
        {intl.formatMessage({ id: "deployment_cohort_balance" })}
      </Text>
      {isFetching && <Loader />}
      {!isFetching && <CohortBalanceChart data={plottableData} />}
    </Box>
  );
};

export default CohortBalance;
