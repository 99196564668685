import { useIntl } from "react-intl";
import AnnualizedDefaultRatesChart from "./AnnualizedDefaultRatesChart";
import { Box, Text } from "@chakra-ui/react";
import { dataSource } from "../../../stubs/annualizedDefaultRates";
import useHasFeatureFlag from "../../../user/session/hooks/useHasFeatureFlag";
import ChartPlaceholder from "../ChartPlaceholder";

const AnnualizedDefaultRates = () => {
  const intl = useIntl();
  const isDemoMode = useHasFeatureFlag("demo_mode");

  return (
    <Box>
      {isDemoMode ? (
        <AnnualizedDefaultRatesChart
          title={intl.formatMessage({ id: "risk_annualized_default_rates" })}
          data={dataSource}
        />
      ) : (
        <>
          <Text fontSize="14px" fontWeight="600" px="4" pt="4">
            {intl.formatMessage({ id: "risk_annualized_default_rates" })}
          </Text>
          <ChartPlaceholder type="lineChart" />
        </>
      )}
    </Box>
  );
};

export default AnnualizedDefaultRates;
