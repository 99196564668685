import React from "react";
import RiskTotalsView from "./RiskTotalsView";
import { useGetRiskTotalsQuery } from "../services";
import { IRiskFilters } from "../types";
import Loader from "../../shared/Loader";

type Props = {
  params: IRiskFilters;
};

const RiskTotals = ({ params }: Props) => {
  const { data, isFetching, error } = useGetRiskTotalsQuery(params);

  const totals = data?.data ? data.data.totals : undefined;

  return (
    <>
      {!isFetching && data && !error && <RiskTotalsView totals={totals} />}
      {isFetching && <Loader />}
    </>
  );
};

export default RiskTotals;
