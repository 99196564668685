const generateDataForYear = (year: number) => {
  const dataForYear = [];
  let value = 1;

  for (let i = 1; i <= 12; i = i + 1) {
    let newRate: number = 100
      ? Math.floor(Math.random() * 10 + value + 10)
      : 100;

    dataForYear.push({
      rate: newRate < 100 ? newRate : 100,
      age: i,
      year: year,
    });
    value = newRate < 100 ? newRate : 100;
  }

  return dataForYear;
};

export { generateDataForYear };
