import React from 'react'
import AuthLayout from '../../shared/AuthLayout';
import { Box} from "@chakra-ui/react";
import DashboardCard from "../../dashboard/DashboardCard";
import FileUploader from "../file-upload"
type Props = {}

const DataIngestion = (props: Props) => {
    return (
        <AuthLayout>
            <DashboardCard title="Data sourcing" w="full">
                <Box p="4">
                    <FileUploader />
                </Box>
            </DashboardCard>
        </AuthLayout>
    )
}

export default DataIngestion