import {
  IRiskGroupDistributionValue,
  IRiskGroupDistributionRating,
  IRiskGroupDistributionPlottableData,
} from "../../types";

export const getPlottableData = (data: IRiskGroupDistributionValue[]): IRiskGroupDistributionPlottableData[] => {
  let dataSource: IRiskGroupDistributionPlottableData[] = [];
  if (!data) return [];
  data.forEach((dataItem) => {
    let dataSourceItem = {
      bucket: dataItem.bucket,
      in: dataItem.in,
    } as IRiskGroupDistributionPlottableData;
    dataItem.series.rating.forEach((series: IRiskGroupDistributionRating) => {
      dataSourceItem = {
        ...dataSourceItem,
        [series.id.toUpperCase()]: series.value,
      };
      return dataSourceItem;
    });
    dataSource.push(dataSourceItem);
  });

  return dataSource;
};
