const cellStyles = {
  observed_at: {
    minWidth: "200px",
  },
  months_elapsed: {
    minWidth: "150px",
    fontFamily: "mono",
    textAlign: "right",
  },
  payments_elapsed: {
    minWidth: "150px !important",
    fontFamily: "mono",
    textAlign: "right",
  },
  credit_performance: {
    minWidth: "150px !important",
    textAlign: "right",
  },
  payments_overdue: {
    minWidth: "150px !important",
    fontFamily: "mono",
    textAlign: "right",
  },
  days_overdue: {
    minWidth: "150px !important",
    textAlign: "right",
  },
  principal_paid: {
    minWidth: "150px !important",
    fontFamily: "mono",
    textAlign: "right",
  },
  outstanding: {
    minWidth: "200px",
    fontFamily: "mono",
    textAlign: "right",
  },
  interest_paid: {
    minWidth: "150px !important",
    fontFamily: "mono",
    textAlign: "right",
  },
};
const headStyles = {
  observed_at: {
    minWidth: "200px !important",
  },
  months_elapsed: {
    minWidth: "150px !important",
    textAlign: "right",
  },
  payments_elapsed: {
    minWidth: "150px !important",
    textAlign: "right",
  },
  credit_performance: {
    minWidth: "150px !important",
    textAlign: "right",
  },
  payments_overdue: {
    minWidth: "150px !important",
    textAlign: "right",
  },
  days_overdue: {
    minWidth: "150px !important",
    textAlign: "right",
  },
  principal_paid: {
    minWidth: "150px !important",
    textAlign: "right",
  },
  outstanding: {
    minWidth: "200px !important",
    textAlign: "right",
  },
  interest_paid: {
    minWidth: "150px !important",
    textAlign: "right",
  },
};

export { cellStyles, headStyles };
