// const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN ? process.env.REACT_APP_AUTH0_DOMAIN : ""
// const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID ? process.env.REACT_APP_AUTH0_CLIENT_ID : ""

// const BASE_URL = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://localhost:3000"
// const API_BASE_URL = process.env.REACT_APP_API_BASE_URL ? process.env.REACT_APP_API_BASE_URL : "http://localhost/pp/api/"
// const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE ? process.env.REACT_APP_AUTH0_AUDIENCE : "https://app.scaylcap.com"

const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN ? process.env.REACT_APP_AUTH0_DOMAIN : "dev-scayl.eu.auth0.com"
const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID ? process.env.REACT_APP_AUTH0_CLIENT_ID : "VqP5gIah9cU2M2URzM5Q2BpmZqCHQy5j"

const BASE_URL = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://localhost:3000"
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL ? process.env.REACT_APP_API_BASE_URL : "http://localhost/pp/api/"
const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE ? process.env.REACT_APP_AUTH0_AUDIENCE : "https://scaylcap.com"

const AM_CHARTS_LICENSE = {
    charts: process.env.REACT_APP_AM_CHARTS_LICENSE ? process.env.REACT_APP_AM_CHARTS_LICENSE : "",
    maps: process.env.REACT_APP_AM_MAPS_LICENSE ? process.env.REACT_APP_AM_MAPS_LICENSE : ""
}


export { AUTH0_DOMAIN, AUTH0_CLIENT_ID, AUTH0_AUDIENCE, BASE_URL, API_BASE_URL, AM_CHARTS_LICENSE }