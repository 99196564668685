import { useState, useContext } from "react";
import { useIntl } from "react-intl";
import { useNavigate, URLSearchParamsInit } from "react-router-dom";
import { IconButton, Box } from "@chakra-ui/react";
import { ArrowLeft20 } from "@carbon/icons-react";
import useQueryParams from "../../shared/hooks/useQuery";
import useHasAccess from "../../user/session/hooks/useHasAccess";
import AuthLayout from "../../shared/AuthLayout";
import {
  AccessTokenContext,
  OriginatorIdContext,
} from "../../context/contextProvider";
import DashboardCard from "../../dashboard/DashboardCard";
import { IPerformanceTriggersOverTimeParams } from "../types";
import moment from "moment";
import CovenantsLatest from "../Covenants";
import PortfolioNavigation from "../../portfolio-visualizer/PortfolioNavigation";
import LoansNo from "../../deployment/LoansNo";
import PerformanceTriggers from "../charts/PerformanceTriggers";

interface GlobalParams
  extends IPerformanceTriggersOverTimeParams,
    Record<string, string | number | undefined> {}

const Covenants = () => {
  const navigate = useNavigate();
  const intl = useIntl();

  const defaultParams: IPerformanceTriggersOverTimeParams = {
    from: moment().subtract(90, "days").format("YYYY-MM-DD") + "T00:00:00",
    to: moment().format("YYYY-MM-DD") + "T00:00:00",
    originatorId: useContext(OriginatorIdContext).originatorId,
    accessToken: useContext(AccessTokenContext).accessToken,
    asset_class: "all",
    viewport_from:
      moment().subtract(5, "years").format("YYYY-MM-DD") + "T00:00:00",
    viewport_to: moment().format("YYYY-MM-DD") + "T00:00:00",
  };

  const queryParams: GlobalParams = useQueryParams() as GlobalParams;

  const [params] = useState<GlobalParams>({
    ...defaultParams,
    ...queryParams,
  });

  const hasDashboardAccess = useHasAccess("dashboard");

  return (
    <AuthLayout>
      <DashboardCard
        overflow="hidden"
        w="full"
        title={intl.formatMessage({ id: `covenants_view_page_title` })}
        cardSubtitle={<LoansNo params={params} />}
        linkBack={
          hasDashboardAccess ? (
            <IconButton
              colorScheme="credo"
              _hover={{ bg: "credo.10" }}
              _active={{ bg: "credo.10" }}
              variant="outline"
              mr="12px"
              aria-label="Back"
              icon={<ArrowLeft20 />}
              w="40px"
              h="40px"
              minWidth="auto"
              onClick={() => navigate("/dashboard")}
            />
          ) : undefined
        }
        actions={
          <PortfolioNavigation
            params={params}
            queryParams={queryParams as URLSearchParamsInit}
          />
        }
      >
        <>
          <CovenantsLatest params={params} />
          <Box
            w="full"
            display="flex"
            flexWrap="wrap"
            borderColor="gray.200"
            borderBottomWidth="1px"
          >
            <Box
              mt="4"
              w="full"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box
                p="8px 16px"
                minW="170px"
                borderRadius="24px"
                fontWeight="600"
                bg="#FBFDFE"
                borderColor="gray.200"
                borderWidth="1px"
                textAlign="center"
              >
                Performance Triggers
              </Box>
            </Box>
            <PerformanceTriggers params={params} />
          </Box>
        </>
      </DashboardCard>
    </AuthLayout>
  );
};

export default Covenants;
