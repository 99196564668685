import { useState } from "react";
import { useIntl } from "react-intl";
import { Box, Text } from "@chakra-ui/react";
import DragAndDrop from "./DragAndDrop";
import { IFile } from "./types";
import IndividualUploader from "./IndividualUploader";
import { CloudUpload20, DocumentAdd20 } from "@carbon/icons-react";
type Props = {};

const FileUploader = (props: Props) => {
  const intl = useIntl();

  const [filesToUpload, setFilesToUpload] = useState<IFile[]>([]);

  const onFileUpdated = (files: File[]) => {
    const uFiles = files.map((file) => prepareFile(file));
    setFilesToUpload([...filesToUpload, ...uFiles]);
  };
  const onCancelPressed = (fileHash: string) => {
    setFilesToUpload(filesToUpload.filter((uFile) => uFile.hash !== fileHash));
  };
  return (
    <Box>
      <DragAndDrop onDrop={onFileUpdated}>
        <Box textAlign="center" lineHeight="normal">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            mb="12px"
          >
            <CloudUpload20 style={{ width: "80px", height: "80px" }} />
          </Box>
          <Text fontSize="24px">
            {intl.formatMessage({ id: "data_sourcing_drag" })} &amp;{" "}
            {intl.formatMessage({ id: "data_sourcing_drop_files" })}
          </Text>
          <Text my="12px">
            {intl.formatMessage({ id: "data_sourcing_or" })}
          </Text>
          <Box
            display="inline-flex"
            height="40px"
            alignItems="center"
            px="12px"
            cursor="pointer"
            borderRadius="8px"
            fontWeight="500"
            _hover={{ bg: "credo.10" }}
            _active={{ bg: "credo.10" }}
            borderWidth="1px"
            borderColor="credo.600"
            color="credo.600"
            fontSize="16px"
            transition="all 120ms ease-in-out"
          >
            <DocumentAdd20 />
            <Text ml="8px">
              {intl.formatMessage({ id: "data_sourcing_browse" })}
            </Text>
          </Box>
        </Box>
      </DragAndDrop>

      {filesToUpload.length > 0 && (
        <Box mt="8px">
          {filesToUpload.map((uFile) => (
            <IndividualUploader
              key={uFile.hash}
              uFile={uFile}
              onCancelPressed={onCancelPressed}
            ></IndividualUploader>
          ))}
        </Box>
      )}
    </Box>
  );
};
const prepareFile = (file: File): IFile => {
  return {
    hash: computeFileHash(file),
    file: file,
    is_uploading: false,
    upload_started: false,
    errors: [],
    progress: 0,
  };
};
const computeFileHash = (file: File): string => {
  return `${
    file.name
  }-${file.type.toString()}-${file.size.toString()}-${file.lastModified.toString()}`.toLowerCase();
};
export default FileUploader;
