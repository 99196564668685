import useHasAccess from "./hooks/useHasAccess";
import { useNavigate } from "react-router-dom";

import useGetSessionPages from "./hooks/useGetSessionPages";
import React from "react";

type Props = {
  children: React.ReactNode;
  pageId: string;
};

const ModuleAccessManager = ({ children, pageId }: Props) => {
  const hasAccess = useHasAccess(pageId);
  const pages = useGetSessionPages();
  const navigate = useNavigate();
  if (!hasAccess) {
    if (pages.includes("dashboard")) {
      navigate("/dashboard");
      return <></>;
    }
    if (pages.includes("table_view")) {
      navigate("/loanbook");
      return <></>;
    }
    if (pages.includes("performance_view")) {
      navigate("/performance_view");
      return <></>;
    }
    if (pages.includes("data_room")) {
      navigate("/data-room");
      return <></>;
    }
    // navigate("/contact")
    // return <></>
  }

  return <>{hasAccess && children}</>;
};

export default ModuleAccessManager;
