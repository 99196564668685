import * as React from "react";
import { Global } from "@emotion/react";

export const Fonts = () => (
  <Global
    styles={`
      @import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;500;600;700&family=IBM+Plex+Sans+Condensed:wght@400;500;600;700&family=IBM+Plex+Sans:wght@400;500;600;700&display=block');

      @font-face {
        font-family: 'IBM Plex Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-display: block;
      }
      @font-face {
        font-family: 'IBM Plex Mono', monospace;
        font-style: normal;
        font-weight: 400;
        font-display: block;
      }
      @font-face {
        font-family: 'IBM Plex Sans Condensed', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-display: block;
      }
      `}
  />
);
