import { chakra, HTMLChakraProps } from '@chakra-ui/react'
import * as React from 'react'

export const Logo = (props: HTMLChakraProps<'svg'> & { iconColor?: string }) => {
    return (
        <chakra.svg fill="none" viewBox="0 0 1980.77 903.83">

				<polygon fill='#2a5462' points="404.87 799.03 214.16 885.77 214.16 191.46 404.87 104.72 404.87 799.03"/>
				<polygon fill='#486c78' points="23.57 799.03 214.28 885.77 214.28 191.46 23.57 104.72 23.57 799.03"/>
				<polygon fill='#0c3c4c' points="23.57 105.81 214.28 192.54 404.87 105.51 214.16 18.77 23.57 105.81"/>
				<g>
					<path fill='#0c3c4c' d="m755.09,379.57l-58.77,31.28c-9.27-18.96-20.75-28.44-34.44-28.44-6.53,0-12.12,2.16-16.75,6.48-4.64,4.32-6.95,9.85-6.95,16.59,0,11.8,13.69,23.49,41.08,35.07,37.71,16.22,63.09,31.18,76.15,44.87,13.06,13.7,19.59,32.13,19.59,55.3,0,29.7-10.96,54.56-32.86,74.57-21.28,18.96-46.98,28.44-77.1,28.44-51.61,0-88.16-25.17-109.64-75.52l60.67-28.12c8.42,14.75,14.85,24.12,19.27,28.12,8.64,8.01,18.96,12.01,30.97,12.01,24.01,0,36.02-10.95,36.02-32.86,0-12.64-9.27-24.43-27.81-35.39-7.16-3.58-14.33-7.06-21.49-10.43-7.16-3.37-14.43-6.84-21.8-10.43-20.65-10.11-35.18-20.22-43.6-30.33-10.74-12.85-16.12-29.39-16.12-49.61,0-26.75,9.16-48.87,27.49-66.36,18.75-17.48,41.5-26.23,68.25-26.23,39.39,0,68.67,20.33,87.84,60.98Z"/>
					<path fill='#0c3c4c' d="m1078.33,340.39v94.48c-16.22-19.8-30.76-33.38-43.6-40.76-12.64-7.58-27.49-11.38-44.55-11.38-26.75,0-48.98,9.38-66.67,28.12-17.69,18.75-26.54,42.24-26.54,70.46s8.53,52.56,25.59,71.1c17.27,18.54,39.29,27.81,66.04,27.81,17.06,0,32.12-3.68,45.18-11.06,12.64-7.16,27.49-21.06,44.55-41.71v93.85c-28.86,14.95-57.72,22.43-86.58,22.43-47.61,0-87.42-15.38-119.44-46.13-32.02-30.97-48.03-69.41-48.03-115.33s16.22-84.68,48.66-116.28c32.44-31.6,72.25-47.4,119.44-47.4,30.33,0,58.98,7.27,85.95,21.8Z"/>
					<path fill='#0c3c4c' d="m1374.09,327.12h71.41v307.76h-71.41v-32.23c-29.28,27.39-60.78,41.08-94.48,41.08-42.55,0-77.73-15.38-105.54-46.13-27.6-31.39-41.39-70.57-41.39-117.54s13.79-84.57,41.39-115.33c27.59-30.75,62.14-46.13,103.64-46.13,35.81,0,67.94,14.75,96.37,44.24v-35.71Zm-168.73,152.93c0,29.5,7.9,53.51,23.7,72.04,16.22,18.75,36.65,28.12,61.3,28.12,26.33,0,47.6-9.05,63.83-27.17,16.22-18.75,24.33-42.55,24.33-71.41s-8.11-52.66-24.33-71.41c-16.22-18.33-37.29-27.49-63.2-27.49-24.44,0-44.87,9.27-61.3,27.81-16.22,18.75-24.33,41.92-24.33,69.51Z"/>
					<path fill='#0c3c4c' d="m1632.24,577.69l-138.4-250.57h82.47l94.16,176.95,87.84-176.95h80.26l-245.2,475.23h-81.21l120.07-224.66Z"/>
					<path fill='#0c3c4c' d="m1958.01,101.2v533.69h-71.09V101.2h71.09Z"/>
				</g>
        </chakra.svg>
    )
}