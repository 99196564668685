import { extendTheme } from "@chakra-ui/react";

const colors = {
  credo: {
    10: "#E9F8FB",
    50: "#2BBADE",
    100: "#21B0D4",
    200: "#1EA1C2",
    300: "#1C93B0",
    400: "#19849F",
    500: "#16778F",
    600: "#13677C",
    700: "#11586A",
    800: "#0E4958",
    900: "#0B3B47",
  },
  table: {
    50: "#FBFDFE",
    100: "#FBFDFE",
    200: "#FBFDFE",
    300: "#FBFDFE",
    400: "#FBFDFE",
    500: "#FBFDFE",
    600: "#FBFDFE",
    700: "#FBFDFE",
    800: "#FBFDFE",
    900: "#FBFDFE",
  },
};

const fonts = {
  body: "IBM Plex Sans, sans-serif",
  heading: "IBM Plex Sans, sans-serif",
  condensed: "IBM Plex Sans Condensed, sans-serif",
  mono: "IBM Plex Mono, monospace",
};

const styles = {
  global: {
    body: {
      bg: "gray.100",
      fontSize: "16px",
      lineHeight: "24px",
      color: "#2B2B2B",
    },
  },
};

const theme = extendTheme({ colors, fonts, styles });

export default theme;
