import { useState, ChangeEvent } from 'react';
import { Box, FormLabel } from "@chakra-ui/react"

type Props = {
    onDrop: (files: File[]) => void
    children: React.ReactNode
}

const DragAndDrop = (props: Props) => {
    const [drag, setDrag] = useState(false)
    let dragCounter = 0;
    const handleDrag = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
    }

    const handleDragIn = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        dragCounter++;
        if (e.dataTransfer && e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            setDrag(true)
        }
    }
    const handleDragOut = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        dragCounter--
        if (dragCounter === 0) {
            setDrag(false)
        }
    }
    const handleDrop = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        setDrag(false)

        if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            props.onDrop(Array.from(e.dataTransfer.files))
            e.dataTransfer.clearData()
            dragCounter = 0
        }
    }
    const handleFileInputChange = (e: ChangeEvent) => {
        const target = e.target as HTMLInputElement;
        if (target.files?.length) {
            props.onDrop(Array.from(target.files))
        }

    }
    return (
        <Box
            onDragEnter={handleDragIn}
            onDragLeave={handleDragOut}
            onDragOver={handleDrag}
            onDrop={handleDrop}
        >
            <Box w="f" bg="#FCFDFD" border="3px dashed #CDDBDF" borderRadius="8px">
                <input id="ddFileInput" type="file" multiple={true} name="fileInput" hidden onChange={handleFileInputChange} />
                {drag && <></>}
                <FormLabel htmlFor="ddFileInput" cursor="pointer" w="full" p="24px" display="flex" flexDirection="column" alignItems="center" justifyContent="center" m="0" fontWeight="normal">
                    {props.children}
                </FormLabel>
            </Box>
        </Box>
    )
}

export default DragAndDrop
