import * as React from "react";
import {
  HStack,
  Icon,
  Link,
  LinkProps,
  useColorModeValue as mode,
  Text,
} from "@chakra-ui/react";
import { Link as RouteLink } from "react-router-dom";
import  useHasAccess from "../user/session/hooks/useHasAccess";

interface NavLinkProps extends LinkProps {
  isActive?: boolean;
  label: string;
  icon: React.ElementType;
  to: string;
  pageId: string;
}

export const NavLink = (props: NavLinkProps) => {
  const { icon, isActive, label, to, pageId, ...rest } = props;

  const hasAccess = useHasAccess(pageId);

  return hasAccess ? (
    <Link
      as={RouteLink}
      to={to}
      display="block"
      py="2"
      px="3"
      borderRadius="md"
      transition="all 0.3s"
      fontWeight="500"
      userSelect="none"
      aria-current={isActive ? "page" : undefined}
      color={mode("gray.700", "gray.400")}
      _hover={{
        bg: mode("gray.100", "gray.700"),
        color: mode("gray.900", "white"),
      }}
      _activeLink={{
        bg: mode("credo.500", "gray.700"),
        color: "#fff",
      }}
      {...rest}
    >
      <HStack spacing="4">
        <Icon as={icon} fontSize="20px" />
        <Text as="span">{label}</Text>
      </HStack>
    </Link>
  ) : null;
};
