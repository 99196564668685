import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  IDataDownloadProcess,
  IDataDownloadProcessInitParams,
  IDataDownloadProcessParams,
} from "./types";
import { API_BASE_URL } from "../config";

export const downloadApi = createApi({
  reducerPath: "downloadApi",
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  tagTypes: ["DownloadProcess"],
  endpoints: (builder) => ({
    initDownload: builder.mutation<
      IDataDownloadProcess,
      IDataDownloadProcessInitParams
    >({
      invalidatesTags: ["DownloadProcess"],
      query(post_params) {
        console.log("POSTING INIT DOWNLOAD");
        const { from, to, ...queryParams } = post_params.filters;
        return {
          url: `v1/originator/${post_params.originatorId}/init_download`,
          method: "POST",
          params: queryParams,
          body: {
            filename: post_params.fileName,
          },
          headers: {
            Authorization: `Bearer ${post_params.accessToken}`,
          },
        };
      },
    }),
    getDownloadProcess: builder.query<
      IDataDownloadProcess,
      IDataDownloadProcessParams
    >({
      providesTags: ["DownloadProcess"],
      query: (params: IDataDownloadProcessParams) => {
        return {
          url: `v1/originator/${params.originatorId}/download/${params.process_id}`,
          headers: {
            Authorization: `Bearer ${params.accessToken}`,
          },
        };
      },
    }),
  }),
});

export const { useInitDownloadMutation, useGetDownloadProcessQuery } =
  downloadApi;
