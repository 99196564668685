import React, { useState, useEffect, useContext, useRef } from "react";
import { Column } from "react-table";
import { TimePlot20 } from "@carbon/icons-react";
import { Box, IconButton, useDisclosure } from "@chakra-ui/react";
import { AccessTokenContext } from "../context/contextProvider";
import { useGetLoanBookQuery, loanBookApi } from "./service";
import { ILoanBookDataPoint, ILoanBookParams } from "./types";
import TimeSeriesModal from "./TimeSeriesModal";
import AncillaryLoanbookEntryDrawer from "./AncillaryLoanbookEntryDrawer";
import Table from "../shared/Table";
import ProgressBar from "../shared/ProgressBar";
import Loader from "../shared/Loader";
import { Money } from "../locale/formatters";
import { CreditPerformance, CreditRating, DaysOverdue } from "./badges";
import { FormattedNumber, FormattedMessage, useIntl } from "react-intl";
import InfiniteScroll from "react-infinite-scroll-component";
import { useAppDispatch } from "../hooks";

const cellStyles = {
  borrower_ref: {
    width: "150px",
    maxwidth: "150px",
    fontFamily: "mono",
  },
  credit_ref: {
    width: "120px",
    maxwidth: "120px",
    fontFamily: "mono",
  },
  credit_rating: {
    width: "120px",
    textAlign: "right",
  },
  months_elapsed: {
    width: "130px",
    textAlign: "right",
    fontFamily: "mono",
  },
  credit_performance: {
    width: "124px",
    textAlign: "right",
  },
  days_overdue: {
    width: "120px",
    textAlign: "right",
  },
  outstanding: {
    width: "200px",
    textAlign: "right",
    fontFamily: "mono",
  },
  repaid_ratio: {
    width: "160px",
    fontFamily: "mono",
  },
  time_series_action: {
    textAlign: "center",
    width: "60px",
  },
};
const headStyles = {
  borrower_ref: {
    width: "150px",
  },
  credit_ref: {
    width: "120px",
  },
  credit_rating: {
    width: "120px",
    textAlign: "right",
  },
  months_elapsed: {
    width: "130px",
    textAlign: "right",
  },
  credit_performance: {
    width: "124px",
    textAlign: "right",
  },
  days_overdue: {
    width: "120px",
    textAlign: "right",
  },
  outstanding: {
    width: "200px",
    textAlign: "right",
  },
  repaid_ratio: {
    width: "160px",
  },
  time_series_action: {
    textAlign: "center",
    width: "60px",
  },
};

type Props = {
  params: ILoanBookParams;
};

const LoanBook = ({ params }: Props) => {
  const intl = useIntl();

  const [selectedLoan, setSelectedLoan] = useState<ILoanBookDataPoint | null>(
    null
  );
  const {
    isOpen: isTimeOpen,
    onOpen: onTimeOpen,
    onClose: onTimeClose,
  } = useDisclosure();
  const {
    isOpen: isAncOpen,
    onOpen: onAncOpen,
    onClose: onAncClose,
  } = useDisclosure();
  const accessToken = useContext(AccessTokenContext).accessToken;
  const dispatch = useAppDispatch();

  const [tableData, setTableData] = useState<ILoanBookDataPoint[]>([]);

  const cursorRef = useRef("");
  const loadedMoreData = useRef(false);

  const { data, isFetching, error } = useGetLoanBookQuery({
    ...params,
    accessToken: accessToken,
  });

  useEffect(() => {
    if (data && data.data) {
      setTableData(data.data);
    }
  }, [data]);

  if (!loadedMoreData.current) {
    cursorRef.current = data?.data_set?.cursor ? data.data_set.cursor : "";
  }

  const loadMoreData = () => {
    if (!cursorRef.current) {
      return;
    }
    loadedMoreData.current = true;

    dispatch(
      loanBookApi.endpoints.getLoanBook.initiate({
        ...params,
        cursor: cursorRef.current,
        accessToken,
      })
    ).then((response) => {
      cursorRef.current = response.data?.data_set.cursor
        ? response.data?.data_set.cursor
        : "";
      const paginatedData = response.data?.data ? response.data?.data : [];
      setTableData(tableData.concat(paginatedData));
    });
  };

  const columns: Column<ILoanBookDataPoint>[] = React.useMemo(
    () => [
      {
        Header: intl.formatMessage({ id: "loanbook_loan_id" }),
        accessor: "credit_ref",
      },
      {
        Header: intl.formatMessage({ id: "loanbook_credit_rating" }),
        accessor: "credit_rating",
        Cell: ({ row, value }) =>
          value !== undefined ? <CreditRating rating={value} /> : <div>-</div>,
      },
      {
        Header: (
          <FormattedMessage id="loanbook_months_elapsed" values={{ br: "" }} />
        ),
        accessor: "months_elapsed",
        Cell: ({ row, value }) =>
          value !== undefined ? (
            <FormattedNumber
              value={value}
              maximumFractionDigits={0}
              minimumFractionDigits={0}
            />
          ) : (
            <div>-</div>
          ),
      },

      {
        Header: intl.formatMessage({ id: "loanbook_status" }),
        accessor: "credit_performance",
        Cell: ({ row, value }) =>
          value !== undefined ? (
            <CreditPerformance performance={value} />
          ) : (
            <div>-</div>
          ),
      },
      {
        Header: (
          <FormattedMessage
            id="loanbook_days_overdue"
            values={{ sum: "Σ", br: "" }}
          />
        ),
        accessor: "days_overdue",
        Cell: ({ row, value }) =>
          value !== undefined ? <DaysOverdue daysNo={value} /> : <div>-</div>,
      },
      {
        Header: (
          <FormattedMessage
            id="loanbook_outstanding_balance"
            values={{ sum: "Σ", br: "" }}
          />
        ),
        accessor: "outstanding",
        Cell: ({ row, value }) =>
          value !== undefined ? <Money value={value} /> : <div>-</div>,
      },
      {
        Header: intl.formatMessage({ id: "loanbook_repaid" }),
        accessor: "repaid_ratio",
        Cell: ({ row, value }) =>
          value !== undefined ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              <ProgressBar
                w="100%"
                h="14px"
                margin="0 16px 0 0"
                progressColor="credo.500"
                progress={`${value * 100}%`}
              />
              <Box w="75px">
                <FormattedNumber
                  value={value}
                  maximumFractionDigits={2}
                  // eslint-disable-next-line react/style-prop-object
                  style="percent"
                />
              </Box>
            </Box>
          ) : (
            <div>-</div>
          ),
      },
      {
        Header: intl.formatMessage({ id: "loanbook_time_series" }),
        accessor: "credit_id",
        id: "time_series_action",
        disableSortBy: true,
        Cell: ({ row }) => {
          let rowData = row.original;
          return (
            <IconButton
              aria-label="See timeseries"
              icon={<TimePlot20 />}
              bg="#fff"
              borderWidth="1px"
              w="32px"
              h="32px"
              _hover={{ background: "gray.50" }}
              minW="auto"
              onClick={() => {
                setSelectedLoan(rowData);
                onTimeOpen();
              }}
            />
          );
        },
      },
    ],
    [onTimeOpen, intl]
  );

  const handleRowClick = (rowData: ILoanBookDataPoint) => {
    setSelectedLoan(rowData);
    onAncOpen();
  };

  return (
    <>
      {isFetching && <Loader />}
      {error && (
        <Box>{intl.formatMessage({ id: "loanbook_error_message" })}</Box>
      )}
      {!isFetching && !error && (
        <>
          <Box w="full" p="0">
            <InfiniteScroll
              // style={{ maxHeight: "calc(100vh - 112px)" }} //calc(100vh - 210px) for mobile
              dataLength={tableData.length}
              next={loadMoreData}
              hasMore={true}
              loader={<></>}
            >
              <Table
                columns={columns}
                data={tableData || []}
                onRowClick={handleRowClick}
                actionColumnId={"time_series_action"}
                cellStyles={cellStyles}
                headStyles={headStyles}
              />
            </InfiniteScroll>
          </Box>
          {isTimeOpen && selectedLoan && (
            <TimeSeriesModal
              loan={selectedLoan}
              isOpen={isTimeOpen}
              onClose={() => {
                onTimeClose();
                setSelectedLoan(null);
              }}
              params={params}
            />
          )}
          {isAncOpen && selectedLoan && (
            <AncillaryLoanbookEntryDrawer
              loan={selectedLoan}
              isOpen={isAncOpen}
              onClose={() => {
                onAncClose();
                setSelectedLoan(null);
              }}
            />
          )}
        </>
      )}
    </>
  );
};

export default LoanBook;
