import { MiddlewareAPI, isRejectedWithValue, Middleware } from "@reduxjs/toolkit";

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorInterceptor: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
  if (isRejectedWithValue(action)) {
    console.warn(`Failed http call - status ${action.payload.status}!`, action);
    if ([401, 403].indexOf(action.payload.status) !== -1) {
      //authentication error redirect to /auth/login
      window.location.href = "/auth/login";
    }
  }

  return next(action);
};
