import React from "react";
import { useIntl } from "react-intl";
import { Text, Box } from "@chakra-ui/react";
import ProbabilityOfDefaultChart from "../../../risk/charts/ProbabilityOfDefault/ProbabilityOfDefaultChart";
import { IProbabilityOfDefaultValue } from "../../../risk/types";
import { getPlottableData } from "../../../risk/charts/ProbabilityOfDefault/chartData";
import { getBucketsNotInDataCube } from "../../../risk/utils";

type Props = {
  data: IProbabilityOfDefaultValue[] | [];
};

const ProbabilityOfDefault = ({ data }: Props) => {
  const intl = useIntl();

  const dataSource = getPlottableData(data);
  const bucketsNotInDataCube = getBucketsNotInDataCube(dataSource);

  return (
    <Box>
      <Text fontSize="14px" fontWeight="600" px="4" pt="4">
        {intl.formatMessage({ id: "risk_probability_of_default" })}
      </Text>
      <ProbabilityOfDefaultChart
        data={dataSource}
        bucketsNotInDataCube={bucketsNotInDataCube}
      />
    </Box>
  );
};

export default ProbabilityOfDefault;
