import React from "react";
import { Box, Spinner } from "@chakra-ui/react";

type Props = {
  minHeight?: string;
};

const Loader = ({ minHeight }: Props) => {
  return (
    <>
      <Box p="48px" display="flex" alignItems="center" justifyContent="center" minHeight={minHeight} m="auto">
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="credo.500"
          size="xl"
        />
      </Box>
    </>
  );
};

export default Loader;
