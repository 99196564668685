import { useIntl } from "react-intl";
import { Box } from "@chakra-ui/react";
import { useGetRiskGroupDistributionQuery } from "../../services";
import { getBucketsNotInDataCube } from "../../utils";
import RiskGroupDistributionChart from "./RiskGroupDistributionChart";
import { IRiskFilters } from "../../types";

import { getPlottableData } from "./chartData";
import Loader from "../../../shared/Loader";

type Props = {
  params: IRiskFilters;
};

const RiskGroupDistribution = ({ params }: Props) => {
  const intl = useIntl();

  const { data, isFetching } = useGetRiskGroupDistributionQuery(params);

  const dataSource = data?.data ? getPlottableData(data.data) : [];

  const bucketsNotInDataCube = getBucketsNotInDataCube(dataSource);

  return (
    <Box>
      {isFetching && <Loader />}
      {!isFetching && (
        <RiskGroupDistributionChart
          data={dataSource}
          title={intl.formatMessage({ id: "risk_risk_group_distribution" })}
          bucketsNotInDataCube={bucketsNotInDataCube}
        />
      )}
    </Box>
  );
};

export default RiskGroupDistribution;
