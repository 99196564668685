const dataSource = [
  {
    credo: 2.4,
    originator: 3,
    bucket: "M2020-01",
  },
  {
    credo: 3,
    originator: 2,
    bucket: "M2020-02",
  },
  {
    credo: 7,
    originator: 6,
    bucket: "M2020-03",
  },
  {
    credo: 4,
    originator: 5,
    bucket: "M2020-04",
  },
  {
    credo: 4,
    originator: 1.3,
    bucket: "M2020-05",
  },
  {
    credo: 1.9,
    originator: 1.2,
    bucket: "M2020-06",
  },
  {
    credo: 2.3,
    originator: 1.1,
    bucket: "M2020-07",
  },
  {
    credo: 2.4,
    originator: 3,
    bucket: "M2020-08",
  },
  {
    credo: 2.4,
    originator: 3,
    bucket: "M2020-09",
  },
  {
    credo: 2.4,
    originator: 3,
    bucket: "M2020-10",
  },
  {
    credo: 2.4,
    originator: 3,
    bucket: "M2020-11",
  },
  {
    credo: 2.4,
    originator: 3,
    bucket: "M2020-12",
  },
  {
    credo: 2.1,
    originator: 2.5,
    bucket: "M2021-01",
  },
  {
    credo: 3,
    originator: 2,
    bucket: "M2021-02",
  },
  {
    credo: 2,
    originator: 2,
    bucket: "M2021-03",
  },
  {
    credo: 4,
    originator: 5,
    bucket: "M2021-04",
  },
  {
    credo: 1,
    originator: 1.6,
    bucket: "M2021-05",
  },
  {
    credo: 1.9,
    originator: 2.2,
    bucket: "M2021-06",
  },
  {
    credo: 2.1,
    originator: 1.1,
    bucket: "M2021-07",
  },
  {
    credo: 2.4,
    originator: 3,
    bucket: "M2021-08",
  },
  {
    credo: 2.1,
    originator: 2.5,
    bucket: "M2021-09",
  },
];

export { dataSource };
