import React from "react";
import { Box } from "@chakra-ui/react";

type Props = {
  progressColor: string;
  w: string,
  h: string,
  margin: string,
  progress: string,
};
const ProgressBar = ({ progressColor, w, h, margin, progress }: Props) => {
  return (
    <Box h={h} w={w} margin={margin} background="#EDF2F7" border="3px solid #fff" display="flex" alignItems="center">
        <Box h="full" w={progress} bg={progressColor} />
        <Box w="5px"h={h} border="1px solid #fff" bg={progressColor} />
    </Box>
  );
};

export default ProgressBar;
