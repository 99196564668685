import React from "react";
import { useIntl } from "react-intl";
import { Box, Alert, Image, AlertIcon } from "@chakra-ui/react";

const IMAGE_SRC: Record<IChartType, string> = {
  barChart: "/chart-placeholder-bar.png",
  lineChart: "/chart-placeholder-line.png",
  areaChart: "/chart-placeholder-area.png",
};

type IChartType = "barChart" | "lineChart" | "areaChart";

type Props = {
  type: IChartType;
};

const ChartPlaceholder = ({ type }: Props) => {
  const intl = useIntl();

  return (
    <>
      <Box position="relative">
        <Image
          src={IMAGE_SRC[type]}
          alt="Chart Placeholder"
          mx="auto"
          maxHeight="400px"
          opacity="0.64"
        />
        <Box
          rounded="lg"
          bg="rgba(255,255,255,.64)"
          backdropFilter="blur(4px)"
          position="absolute"
          top="0"
          left="0"
          p="4"
          w="full"
          h="full"
          display="flex"
          alignItems="center"
          justifyContent="center"
          fontWeight="500"
        >
          <Alert
            status="info"
            rounded="lg"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            shadow="lg"
            bg="#BCE9F0"
            color="#17383E"
            lineHeight="normal"
            border="2px solid #fff"
            fontWeight="600"
            maxWidth="200px"
          >
            <AlertIcon color="#17383E" />
            {intl.formatMessage({ id: "risk_chart_placeholder_message" })}
          </Alert>
        </Box>
      </Box>
    </>
  );
};

export default ChartPlaceholder;
