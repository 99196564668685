import React from "react";
import CovenantsLatestView from "./CovenantsLatestView";
import { useGetCovenantsLatestQuery } from "../services";
import { ICovenantsFilters } from "../types";
import Loader from "../../shared/Loader";

type Props = {
  params: ICovenantsFilters;
};

const CovenantsLatest = ({ params }: Props) => {
  const { data, isFetching, error } = useGetCovenantsLatestQuery(params);
  const totals = data?.data ? data.data[0].totals : undefined;

  return (
    <>
      {!isFetching && data && !error && <CovenantsLatestView totals={totals} />}
      {isFetching && <Loader />}
    </>
  );
};

export default CovenantsLatest;
