import { useIntl } from "react-intl";
import { Box, Text } from "@chakra-ui/react";

const Header = () => {
  const intl = useIntl();

  return (
    <Box
      bg="#EDF2F7"
      color="#4A5568"
      borderBottom="1px solid #E2E8F0"
      lineHeight="normal"
      py="2"
      px="4"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box display="flex" alignItems="center" flex="1">
        <Text
          fontSize="12px"
          lineHeight="normal"
          fontWeight="bold"
          textTransform="uppercase"
          letterSpacing="1px"
          fontFamily="condensed"
        >
          {intl.formatMessage({ id: "data_room_header_name" })}
        </Text>
      </Box>
      <Box
        display={{ base: "none", md: "flex" }}
        alignItems="center"
        w="180px"
        pl="4"
        justifyContent="flex-end"
        textAlign="right"
      >
        <Text
          fontSize="12px"
          lineHeight="normal"
          fontWeight="bold"
          textTransform="uppercase"
          letterSpacing="1px"
          fontFamily="condensed"
        >
          {intl.formatMessage({ id: "data_room_header_date_added" })}
        </Text>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        w="100px"
        pl="4"
        justifyContent="flex-end"
        textAlign="right"
      >
        <Text
          fontSize="12px"
          lineHeight="normal"
          fontWeight="bold"
          textTransform="uppercase"
          letterSpacing="1px"
          fontFamily="condensed"
        >
          {intl.formatMessage({ id: "data_room_header_size" })}
        </Text>
      </Box>
    </Box>
  );
};

export default Header;
