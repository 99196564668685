import React, { useContext } from "react";
import { UserProfile } from "../../shared/UserProfile";
import { useGetSessionQuery } from "./service";
import { AccessTokenContext } from "../../context/contextProvider";
type Props = {};

const UserSession = (props: Props) => {
  const { data } = useGetSessionQuery({
    accessToken: useContext(AccessTokenContext).accessToken,
  });
  const email = "test@scaylcap.com";
  return (
    <>
      {data && (
        <UserProfile name={data.name} email={email} initials={data.initials} />
      )}
    </>
  );
};

export default UserSession;
