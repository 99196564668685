import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  Text,
  Link,
  Divider,
  Checkbox,
} from "@chakra-ui/react";
import { AccessTokenContext } from "../context/contextProvider";
import {
  useGetTermsAndConditionsQuery,
  useAcceptTermsAndConditionsMutation,
} from "./service";
import Loader from "../shared/Loader";

type Props = {
  dataRoomId: string;
  context: string;
  agreementKey: string;
  isModalOpen: boolean;
  closeModal: () => void;
};

const TermsAndConditions = ({
  dataRoomId,
  context,
  agreementKey,
  isModalOpen,
  closeModal,
}: Props) => {
  const navigate = useNavigate();
  const intl = useIntl();

  const accessToken = useContext(AccessTokenContext).accessToken;

  const [isTnRChecked, setIsTnRChecked] = useState<boolean>(false);

  const { data, isFetching, error } = useGetTermsAndConditionsQuery({
    context,
    agreement_key: agreementKey,
    accessToken,
  });

  const [acceptTermsAndConditions, { isLoading: isAcceptingTerms }] =
    useAcceptTermsAndConditionsMutation();

  const handleAcceptTermsAndConditions = () => {
    acceptTermsAndConditions({
      accessToken,
      context: context,
      agreement_key: agreementKey,
    })
      .unwrap()
      .then(() => {
        closeModal();
        navigate(`/data-room?data_room_id=${dataRoomId}`);
      })
      .catch(() => closeModal());
  };

  return (
    <Modal isOpen={isModalOpen} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent maxW="400px">
        <ModalHeader
          fontSize="16px"
          p="16px"
          display="flex"
          alignItems="center"
        >
          {intl.formatMessage({ id: "data_room_tnc_consent" })}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="4" borderTop="1px solid #E9EAEC">
          {isFetching && <Loader />}
          {!isFetching && data && (
            <>
              <Text>{intl.formatMessage({ id: "data_room_tnc_message" })}</Text>
              <Divider my="12px" />
              <Text>
                <Link
                  href={data.url}
                  isExternal
                  color="credo.500"
                  fontWeight="600"
                >
                  {intl.formatMessage({ id: "data_room_tnc_link_label" })}
                </Link>
              </Text>
              <Divider my="12px" />
              <Checkbox
                onChange={() => {
                  setIsTnRChecked((prevState) => !prevState);
                }}
                colorScheme="credo"
              >
                {intl.formatMessage({ id: "data_room_tnc_checkbox_label" })}
              </Checkbox>
              <Button
                disabled={!isTnRChecked}
                colorScheme="credo"
                w="100%"
                mt="12px"
                onClick={handleAcceptTermsAndConditions}
                isLoading={isAcceptingTerms}
                minWidth="40px"
                padding={{ base: "0 12px", md: "0 12px" }}
                fontSize="14px"
              >
                {intl.formatMessage({ id: "data_room_tnc_button_label" })}
              </Button>
            </>
          )}
          {!isFetching && error && (
            <Text> {intl.formatMessage({ id: "data_room_tnc_error" })}</Text>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default TermsAndConditions;
