import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  IDataRoomsList,
  ITermsAndConditionsParams,
  ITermsAndConditions,
  IDataRoomContentParams,
  IDataRoomContent,
  IFileDetails,
  IFileDetailsParams,
  IArchive,
  IArchiveParams,
  IArchiveStatus,
} from "./types";
import { IAuthParams } from "../auth/types";
import { API_BASE_URL } from "../config";

export const dataRoomApi = createApi({
  reducerPath: "dataRoomApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  tagTypes: ["AcceptTermsAndConditions", "CreateDownloadArchive"],
  endpoints: (builder) => ({
    getDataRooms: builder.query<IDataRoomsList, IAuthParams>({
      query: (params: IAuthParams) => {
        const { accessToken } = params;
        return {
          url: `v1/dataroom`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getTermsAndConditions: builder.query<
      ITermsAndConditions,
      ITermsAndConditionsParams
    >({
      query: (params: ITermsAndConditionsParams) => {
        const { accessToken, ...queryParams } = params;
        return {
          url: `v1/tnc`,
          params: queryParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    acceptTermsAndConditions: builder.mutation<void, ITermsAndConditionsParams>(
      {
        invalidatesTags: ["AcceptTermsAndConditions"],
        query(post_params) {
          return {
            url: `v1/tnc`,
            method: "POST",
            body: {
              agreement_key: post_params.agreement_key,
              context: post_params.context,
            },
            headers: {
              Authorization: `Bearer ${post_params.accessToken}`,
            },
          };
        },
      }
    ),
    getDataRoomContent: builder.query<IDataRoomContent, IDataRoomContentParams>(
      {
        query: (params: IDataRoomContentParams) => {
          const { accessToken, data_room_id, state } = params;

          return {
            url: `v1/dataroom/${data_room_id}${state ? `/${state}` : ""}`,
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          };
        },
      }
    ),
    getFileDetails: builder.query<IFileDetails, IFileDetailsParams>({
      query: (params: IFileDetailsParams) => {
        const { accessToken, data_room_id, key } = params;
        return {
          url: `v1/filearea/${data_room_id}/${key}`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    createDownloadArchive: builder.mutation<IArchive, IArchiveParams>({
      invalidatesTags: ["CreateDownloadArchive"],
      query(params) {
        const { data_room_id, state, accessToken } = params;
        return {
          url: `v1/archive/${data_room_id}/${state}`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getArchiveStatus: builder.query<IArchiveStatus, IArchiveParams>({
      providesTags: ["CreateDownloadArchive"],
      query: (params: IArchiveParams) => {
        const { data_room_id, state, accessToken } = params;
        return {
          url: `v1/archive/${data_room_id}/${state}`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      keepUnusedDataFor: 5,
    }),
  }),
});

export const {
  useGetDataRoomsQuery,
  useGetTermsAndConditionsQuery,
  useGetDataRoomContentQuery,
  useAcceptTermsAndConditionsMutation,
  useGetFileDetailsQuery,
  useCreateDownloadArchiveMutation,
  useGetArchiveStatusQuery,
} = dataRoomApi;
