import React, { createContext, useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useGetOriginatorsQuery } from "../originator/services";

type Props = {
  children: JSX.Element;
};

interface OriginatorContextType {
  originatorId: string;
  setOriginatorId: (value: string) => void;
  originatorName: string;
  setOriginatorName: (value: string) => void;
}

interface AccessTokenContextType {
  accessToken: string;
}

const OriginatorIdContext = createContext<OriginatorContextType>({
  originatorId: "",
  setOriginatorId: () => {},
  originatorName: "",
  setOriginatorName: () => {},
});

const AccessTokenContext = createContext<AccessTokenContextType>({
  accessToken: "",
});

const ContextProvider = ({ children }: Props) => {
  const { getAccessTokenSilently, loginWithRedirect, isAuthenticated } =
    useAuth0();
  const [accessToken, setAccessToken] = useState("");

  const { data } = useGetOriginatorsQuery(
    { accessToken },
    { skip: !accessToken }
  );

  const [originatorId, setOriginatorId] = useState(
    data ? data.originators[0].originator_id : ""
  );

  const [originatorName, setOriginatorName] = useState(
    data ? data.originators[0].name : ""
  );

  useEffect(() => {
    if (!isAuthenticated) {
      loginWithRedirect();
      return;
    }
    const getAccessToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        setAccessToken(token);
      } catch (e) {
        const redirectTarget = `${window.location.pathname}${window.location.search}`;
        loginWithRedirect({ appState: { target: redirectTarget } });
      }
    };
    if (isAuthenticated && !accessToken) {
      getAccessToken();
    }
  }, [getAccessTokenSilently, loginWithRedirect, isAuthenticated, accessToken]);

  return (
    <>
      {accessToken && (
        <AccessTokenContext.Provider value={{ accessToken }}>
          <OriginatorIdContext.Provider
            value={{
              originatorId,
              setOriginatorId,
              originatorName,
              setOriginatorName,
            }}
          >
            {children}
          </OriginatorIdContext.Provider>
        </AccessTokenContext.Provider>
      )}
    </>
  );
};

export { AccessTokenContext, OriginatorIdContext };
export default ContextProvider;
