import React from "react";
import { GridItem } from "@chakra-ui/react";

type Props = {
  label: string;
  borderBottom?: string;
  children: React.ReactNode;
  fontFamily?:string;
};

const BodyItem = ({ label, children, borderBottom, fontFamily }: Props) => {
  return (
    <>
      <GridItem
        w="100%"
        borderBottom={borderBottom}
        borderRight="1px solid #E2E8F0"
        p="8px"
        fontWeight="600"
      >
        {label}
      </GridItem>
      <GridItem w="100%" borderBottom={borderBottom} p="8px" bg="white" fontFamily={fontFamily}>
        {children}
      </GridItem>
    </>
  );
};

export default BodyItem;
