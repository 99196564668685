import React from "react";
import { GridItem, Text } from "@chakra-ui/react";

type Props = {
  label: React.ReactNode;
  children: React.ReactNode;
  borderBottom?: string;
  alignItems?: string;
  fontFamily?: string;
};

const HeaderItem = ({
  label,
  children,
  borderBottom,
  alignItems,
  fontFamily,
}: Props) => {
  return (
    <GridItem
      w="100%"
      borderBottom={borderBottom}
      mb="8px"
      pb="8px"
      display="flex"
      flexDirection="column"
      alignItems={alignItems}
    >
      <Text fontWeight="700" textTransform="uppercase" fontSize="12px">
        {label}
      </Text>
      <Text fontFamily={fontFamily}>{children}</Text>
    </GridItem>
  );
};

export default HeaderItem;
