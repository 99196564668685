import React from 'react'
import { defineMessages, FormattedMessage } from "react-intl";

const messages = defineMessages({
    item: {
        id: "duration_in_months",
        defaultMessage:
            "{value, plural, =0 {# months} one {# month} other {# months}}",
    },
});
type Props = {
    value?: number
}

const DurationInMonths = ({ value = 0 }: Props) => {
    return (
        <FormattedMessage values={{ value: value }} {...messages.item} />
    )
}

export default DurationInMonths