import React, { useState, useContext, ChangeEvent } from "react";
import {
  AccessTokenContext,
  OriginatorIdContext,
} from "../context/contextProvider";
import AuthLayout from "../shared/AuthLayout";
import { useNavigate } from "react-router-dom";
import {
  Box,
  HStack,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  Flex,
  Radio,
  RadioGroup,
  IconButton,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { startCase } from "lodash";
import Loader from "../shared/Loader";
import DashboardCard from "../dashboard/DashboardCard";
import {
  useGetFieldDescriptionsQuery,
  useCreateCreditProductMutation,
} from "./service";

const CreateCreditProduct = () => {
  const accessToken = useContext(AccessTokenContext).accessToken;
  const originator_id = useContext(OriginatorIdContext).originatorId;
  const navigate = useNavigate();

  const { data, isFetching, error } = useGetFieldDescriptionsQuery({
    accessToken: accessToken,
  });

  const [productName, setProductName] = useState("");
  const [country, setCountry] = useState("");
  const [productType, setProductType] = useState("");
  const [interestMethod, setInterestMethod] = useState("");
  const [repaymentModel, setRepaymentModel] = useState("");
  const [feeMethod, setFeeMethod] = useState("");
  const [feePaymentType, setFeePaymentType] = useState("");
  const [repaymentFrequencyMethod, setRepaymentFrequencyMethod] = useState("");
  const [repaymentFrequencyValue, setRepaymentFrequencyValue] = useState("");
  const [balloon, setBalloon] = useState(false);
  const [balloonMethod, setBalloonMethod] = useState("");
  const [setupFee, setSetupFee] = useState(false);
  const [setupFeeMethod, setSetupFeeMethod] = useState("");
  const [setupFeePaymentType, setSetupFeePaymentType] = useState("");
  const [otherFee, setOtherFee] = useState(false);
  const [otherFeeMethod, setOtherFeeMethod] = useState("");
  const [otherFeeFrequency, setOtherFeeFrequency] = useState("");
  const [terminationFee, setTerminationFee] = useState(false);
  const [terminationFeeMethod, setTerminationFeeMethod] = useState("");
  const [lateFee, setLateFee] = useState(false);
  const [lateFeeLevels, setLateFeeLevels] = useState([
    { level: 1, dpd: "", value: "" },
  ]);
  const [paymentHoliday, setPaymentHoliday] = useState(false);
  const [paymentHolidayPlan, setPaymentHolidayPlan] = useState("");
  const [bankHoliday, setBankHoliday] = useState(false);
  const [bankHolidayDueDateShift, setBankHolidayDueDateShift] = useState("");

  const addLateFeeLevel = () => {
    const nextLevel =
      Math.max(...lateFeeLevels.map((level) => level.level)) + 1;
    setLateFeeLevels([
      ...lateFeeLevels,
      { level: nextLevel, dpd: "", value: "" },
    ]);
  };

  const handleDPDChange = (level: number, dpd: string) => {
    setLateFeeLevels(
      lateFeeLevels.map((item) =>
        item.level === level ? { ...item, dpd: dpd } : item
      )
    );
  };

  const handleAmountChange = (level: number, value: string) => {
    setLateFeeLevels(
      lateFeeLevels.map((item) =>
        item.level === level ? { ...item, value: value } : item
      )
    );
  };

  const handleProductTypeChange = (event: ChangeEvent) => {
    setProductType((event.target as HTMLSelectElement).value);
  };

  const [createCreditProduct, { isLoading }] = useCreateCreditProductMutation();

  const handleSubmit = async () => {
    if (
      !productName ||
      !country ||
      !productType ||
      (productType === "interest" && (!interestMethod || !repaymentModel)) ||
      (productType === "fixed_fee" && (!feePaymentType || !feeMethod)) ||
      !repaymentFrequencyMethod ||
      (["fixed_weekly", "fixed_monthly", "after_x_days"].includes(
        repaymentFrequencyMethod
      ) &&
        !repaymentFrequencyValue) ||
      (balloon && !balloonMethod) ||
      (setupFee && (!setupFeePaymentType || !setupFeeMethod)) ||
      (otherFee && (!otherFeeFrequency || !otherFeeMethod)) ||
      (terminationFee && !terminationFeeMethod) ||
      (lateFee &&
        lateFeeLevels.some(
          (level) => level.dpd === "" || level.value === ""
        )) ||
      (paymentHoliday && !paymentHolidayPlan) ||
      (bankHoliday && !bankHolidayDueDateShift)
    ) {
      alert("Please fill in all the fields before submitting.");
      return;
    }

    const metadata = {
      country: country,
      repayment_frequency: {
        method: repaymentFrequencyMethod,
        value: repaymentFrequencyValue !== "" ? repaymentFrequencyValue : null,
      },
      interest:
        productType === "interest"
          ? {
              method: interestMethod,
              repayment_model: repaymentModel,
            }
          : null,
      fixed_fee:
        productType === "fixed_fee"
          ? { method: feeMethod, waterfall: feePaymentType }
          : null,
      balloon: balloon ? { method: balloonMethod } : null,
      setup_fee: setupFee
        ? { method: setupFeeMethod, waterfall: setupFeePaymentType }
        : null,
      other_fee: otherFee
        ? { method: otherFeeMethod, frequency: otherFeeFrequency }
        : null,
      termination_fee: terminationFee ? { method: terminationFeeMethod } : null,
      late_fee: lateFee
        ? lateFeeLevels.reduce((acc: any, level) => {
            acc[`fee${level.level}`] = {
              dpd: parseInt(level.dpd),
              amount: parseFloat(level.value),
            };
            return acc;
          }, {})
        : null,
      payment_holiday: paymentHoliday ? { plan: paymentHolidayPlan } : null,
      bank_holiday: bankHoliday
        ? { due_date_shift: bankHolidayDueDateShift }
        : null,
    };

    const creditProductData = {
      name: productName,
      metadata: metadata,
    };

    try {
      await createCreditProduct({
        originator_id: originator_id,
        accessToken: accessToken,
        data: creditProductData,
      }).unwrap();
      // Handle successful creation
      navigate("/credit-product");
    } catch (error) {
      // Handle error
      alert("There's an error, please contact the team");
    }
  };

  return (
    <AuthLayout>
      <>
        {isLoading && <Loader />}

        {isFetching && !data && <Loader />}

        {!isFetching && error && (
          <Box>There's an error. Team is looking into it.</Box>
        )}

        <DashboardCard
          title="Create New Product"
          cardSubtitle="Answer all the below questions"
          w="full"
        >
          <Box p={4} borderWidth="1px" borderRadius="lg">
            <VStack spacing={4} align="stretch">
              <FormControl id="productName">
                <FormLabel>Name your product</FormLabel>
                <Input
                  value={productName}
                  onChange={(e) => setProductName(e.target.value)}
                />
              </FormControl>

              <FormControl id="country">
                <FormLabel>Select the country?</FormLabel>
                <Select
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                >
                  <option value="">Select an option</option>
                  {data?.data.country &&
                    Object.entries(data.data.country).map(([key, value]) => (
                      <option key={key} value={key}>
                        {`${value}`}
                      </option>
                    ))}
                </Select>
              </FormControl>

              <FormControl id="productType">
                <FormLabel>
                  Is your product Interest based or Fixed Fee based?
                </FormLabel>
                <Select value={productType} onChange={handleProductTypeChange}>
                  <option value="">Select an option</option>
                  <option value="interest">Interest based</option>
                  <option value="fixed_fee">Fixed Fee based</option>
                </Select>
              </FormControl>
              {productType === "interest" && (
                <>
                  <FormControl id="interestMethod">
                    <FormLabel>What is the interest method?</FormLabel>
                    <Select
                      value={interestMethod}
                      onChange={(e) => setInterestMethod(e.target.value)}
                    >
                      <option value="">Select an option</option>
                      {data?.data.interest.method &&
                        Object.entries(data.data.interest.method).map(
                          ([key, value]) => (
                            <option key={key} value={key}>
                              {`${startCase(key)} (${value})`}
                            </option>
                          )
                        )}
                    </Select>
                  </FormControl>

                  <FormControl id="repaymentModel">
                    <FormLabel>What is the repayment model type?</FormLabel>
                    <Select
                      value={repaymentModel}
                      onChange={(e) => setRepaymentModel(e.target.value)}
                    >
                      <option value="">Select an option</option>
                      {data?.data.interest.repayment_model &&
                        Object.entries(data.data.interest.repayment_model).map(
                          ([key, value]) => (
                            <option key={key} value={key}>
                              {`${startCase(key)} (${value})`}
                            </option>
                          )
                        )}
                    </Select>
                  </FormControl>
                </>
              )}

              {productType === "fixed_fee" && (
                <>
                  <FormControl id="feeMethod">
                    <FormLabel>What is the fee method?</FormLabel>
                    <Select
                      value={feeMethod}
                      onChange={(e) => setFeeMethod(e.target.value)}
                    >
                      <option value="">Select an option</option>
                      {data?.data.fixed_fee.method &&
                        Object.entries(data.data.fixed_fee.method).map(
                          ([key, value]) => (
                            <option key={key} value={key}>
                              {`${startCase(key)} (${value})`}
                            </option>
                          )
                        )}
                    </Select>
                  </FormControl>

                  <FormControl id="feePaymentType">
                    <FormLabel>What is the fee payment type?</FormLabel>
                    <Select
                      value={feePaymentType}
                      onChange={(e) => setFeePaymentType(e.target.value)}
                    >
                      <option value="">Select an option</option>
                      {data?.data.fixed_fee.waterfall &&
                        Object.entries(data.data.fixed_fee.waterfall).map(
                          ([key, value]) => (
                            <option key={key} value={key}>
                              {`${startCase(key)} (${value})`}
                            </option>
                          )
                        )}
                    </Select>
                  </FormControl>
                </>
              )}

              <FormControl id="repaymentFrequencyMethod">
                <FormLabel>What is the repayment frequency?</FormLabel>
                <Select
                  value={repaymentFrequencyMethod}
                  onChange={(e) => setRepaymentFrequencyMethod(e.target.value)}
                >
                  <option value="">Select an option</option>
                  {data?.data.repayment_frequency.method &&
                    Object.entries(data.data.repayment_frequency.method).map(
                      ([key, value]) => (
                        <option key={key} value={key}>
                          {`${startCase(key)} (${value})`}
                        </option>
                      )
                    )}
                </Select>
              </FormControl>

              {["fixed_weekly", "fixed_monthly", "after_x_days"].includes(
                repaymentFrequencyMethod
              ) && (
                <FormControl id="repaymentFrequencyValue">
                  <FormLabel>What is the repayment frequency value?</FormLabel>
                  <Input
                    value={repaymentFrequencyValue}
                    onChange={(e) => setRepaymentFrequencyValue(e.target.value)}
                  />
                </FormControl>
              )}

              <FormControl id="balloon">
                <FormLabel>
                  Do you have balloon payment type for this product?
                </FormLabel>
                <RadioGroup
                  value={balloon.toString()}
                  onChange={(value) => setBalloon(value === "true")}
                >
                  <HStack spacing={8}>
                    <Radio value="true">Yes</Radio>
                    <Radio value="false">No</Radio>
                  </HStack>
                </RadioGroup>
              </FormControl>

              {balloon && (
                <>
                  <FormControl id="balloonMethod">
                    <FormLabel>What is balloon payment method?</FormLabel>
                    <Select
                      value={balloonMethod}
                      onChange={(e) => setBalloonMethod(e.target.value)}
                    >
                      <option value="">Select an option</option>
                      {data?.data.balloon.method &&
                        Object.entries(data.data.balloon.method).map(
                          ([key, value]) => (
                            <option key={key} value={key}>
                              {`${startCase(key)} (${value})`}
                            </option>
                          )
                        )}
                    </Select>
                  </FormControl>
                </>
              )}

              <FormControl id="setupFee">
                <FormLabel>Do you charge setup fee?</FormLabel>
                <RadioGroup
                  value={setupFee.toString()}
                  onChange={(value) => setSetupFee(value === "true")}
                >
                  <HStack spacing={8}>
                    <Radio value="true">Yes</Radio>
                    <Radio value="false">No</Radio>
                  </HStack>
                </RadioGroup>
              </FormControl>

              {setupFee && (
                <>
                  <FormControl id="setupFeeMethod">
                    <FormLabel>What is the setup fee method?</FormLabel>
                    <Select
                      value={setupFeeMethod}
                      onChange={(e) => setSetupFeeMethod(e.target.value)}
                    >
                      <option value="">Select an option</option>
                      {data?.data.setup_fee.method &&
                        Object.entries(data.data.setup_fee.method).map(
                          ([key, value]) => (
                            <option key={key} value={key}>
                              {`${startCase(key)} (${value})`}
                            </option>
                          )
                        )}
                    </Select>
                  </FormControl>

                  <FormControl id="setupFeePaymentType">
                    <FormLabel>What is the setup fee payment type?</FormLabel>
                    <Select
                      value={setupFeePaymentType}
                      onChange={(e) => setSetupFeePaymentType(e.target.value)}
                    >
                      <option value="">Select an option</option>
                      {data?.data.setup_fee.waterfall &&
                        Object.entries(data.data.setup_fee.waterfall).map(
                          ([key, value]) => (
                            <option key={key} value={key}>
                              {`${startCase(key)} (${value})`}
                            </option>
                          )
                        )}
                    </Select>
                  </FormControl>
                </>
              )}

              <FormControl id="lateFee">
                <FormLabel>Do you charge late fee?</FormLabel>
                <RadioGroup
                  value={lateFee.toString()}
                  onChange={(value) => setLateFee(value === "true")}
                >
                  <HStack spacing={8}>
                    <Radio value="true">Yes</Radio>
                    <Radio value="false">No</Radio>
                  </HStack>
                </RadioGroup>
              </FormControl>

              {lateFee && (
                <>
                  {lateFeeLevels.length < 3 &&
                    lateFeeLevels[lateFeeLevels.length - 1].dpd !== "" && (
                      <Flex alignItems="center">
                        <Button size="sm" onClick={addLateFeeLevel} ml={2}>
                          Add More DPD Levels
                        </Button>
                      </Flex>
                    )}
                  {lateFeeLevels.map((level, index) => (
                    <Box key={level.level}>
                      <FormControl id={`lateFeeDPDLevel${level.level}`} mb={2}>
                        <FormLabel>DPD for late fee {level.level}:</FormLabel>
                        {index > 0 && (
                          <IconButton
                            aria-label="Remove Level"
                            icon={<CloseIcon />}
                            onClick={() => {
                              const updatedLevels = [...lateFeeLevels];
                              updatedLevels.splice(index, 1);
                              setLateFeeLevels(updatedLevels);
                            }}
                          />
                        )}
                        <Input
                          type="number"
                          value={level.dpd}
                          onChange={(e) =>
                            handleDPDChange(level.level, e.target.value)
                          }
                        />
                      </FormControl>
                      <FormControl
                        id={`lateFeeAmountLevel${level.level}`}
                        mb={2}
                      >
                        <FormLabel>
                          Amount (absolute) for late fee {level.level}:
                        </FormLabel>
                        <Input
                          type="number"
                          step="0.1"
                          value={level.value}
                          onChange={(e) =>
                            handleAmountChange(level.level, e.target.value)
                          }
                        />
                      </FormControl>
                    </Box>
                  ))}
                </>
              )}

              <FormControl id="otherFee">
                <FormLabel>Do you charge any other fee?</FormLabel>
                <RadioGroup
                  value={otherFee.toString()}
                  onChange={(value) => setOtherFee(value === "true")}
                >
                  <HStack spacing={8}>
                    <Radio value="true">Yes</Radio>
                    <Radio value="false">No</Radio>
                  </HStack>
                </RadioGroup>
              </FormControl>

              {otherFee && (
                <>
                  <FormControl id="otherFeeMethod">
                    <FormLabel>What is the method for other fee?</FormLabel>
                    <Select
                      value={otherFeeMethod}
                      onChange={(e) => setOtherFeeMethod(e.target.value)}
                    >
                      <option value="">Select an option</option>
                      {data?.data.other_fee.method &&
                        Object.entries(data.data.other_fee.method).map(
                          ([key, value]) => (
                            <option key={key} value={key}>
                              {`${startCase(key)} (${value})`}
                            </option>
                          )
                        )}
                    </Select>
                  </FormControl>

                  <FormControl id="otherFeeFrequency">
                    <FormLabel>What is the frequency for other fee?</FormLabel>
                    <Select
                      value={otherFeeFrequency}
                      onChange={(e) => setOtherFeeFrequency(e.target.value)}
                    >
                      <option value="">Select an option</option>
                      {data?.data.other_fee.frequency &&
                        Object.entries(data.data.other_fee.frequency).map(
                          ([key, value]) => (
                            <option key={key} value={key}>
                              {`${startCase(key)} (${value})`}
                            </option>
                          )
                        )}
                    </Select>
                  </FormControl>
                </>
              )}

              <FormControl id="terminationFee">
                <FormLabel>Do you charge any termination fee?</FormLabel>
                <RadioGroup
                  value={terminationFee.toString()}
                  onChange={(value) => setTerminationFee(value === "true")}
                >
                  <HStack spacing={8}>
                    <Radio value="true">Yes</Radio>
                    <Radio value="false">No</Radio>
                  </HStack>
                </RadioGroup>
              </FormControl>

              {terminationFee && (
                <>
                  <FormControl id="terminationFeeMethod">
                    <FormLabel>
                      What is the method for termination fee?
                    </FormLabel>
                    <Select
                      value={terminationFeeMethod}
                      onChange={(e) => setTerminationFeeMethod(e.target.value)}
                    >
                      <option value="">Select an option</option>
                      {data?.data.termination_fee.method &&
                        Object.entries(data.data.termination_fee.method).map(
                          ([key, value]) => (
                            <option key={key} value={key}>
                              {`${startCase(key)} (${value})`}
                            </option>
                          )
                        )}
                    </Select>
                  </FormControl>
                </>
              )}

              <FormControl id="paymentHoliday">
                <FormLabel>Do you offer payment holidays?</FormLabel>
                <RadioGroup
                  value={paymentHoliday.toString()}
                  onChange={(value) => setPaymentHoliday(value === "true")}
                >
                  <HStack spacing={8}>
                    <Radio value="true">Yes</Radio>
                    <Radio value="false">No</Radio>
                  </HStack>
                </RadioGroup>
              </FormControl>

              {paymentHoliday && (
                <FormControl id="paymentHolidayPlan">
                  <FormLabel>How do you offer payment holidays?</FormLabel>
                  <Select
                    value={paymentHolidayPlan}
                    onChange={(e) => setPaymentHolidayPlan(e.target.value)}
                  >
                    <option value="">Select an option</option>
                    {data?.data.payment_holiday.plan &&
                      Object.entries(data.data.payment_holiday.plan).map(
                        ([key, value]) => (
                          <option key={key} value={key}>
                            {`${startCase(key)} (${value})`}
                          </option>
                        )
                      )}
                  </Select>
                </FormControl>
              )}

              <FormControl id="bankHoliday">
                <FormLabel>
                  Do you shift payment due dates beacuse of bank holiday or
                  weekend?
                </FormLabel>
                <RadioGroup
                  value={bankHoliday.toString()}
                  onChange={(value) => setBankHoliday(value === "true")}
                >
                  <HStack spacing={8}>
                    <Radio value="true">Yes</Radio>
                    <Radio value="false">No</Radio>
                  </HStack>
                </RadioGroup>
              </FormControl>

              {bankHoliday && (
                <FormControl id="bankHolidayDueDateShift">
                  <FormLabel>How do you shift due dates?</FormLabel>
                  <Select
                    value={bankHolidayDueDateShift}
                    onChange={(e) => setBankHolidayDueDateShift(e.target.value)}
                  >
                    <option value="">Select an option</option>
                    {data?.data.bank_holiday.due_date_shift &&
                      Object.entries(data.data.bank_holiday.due_date_shift).map(
                        ([key, value]) => (
                          <option key={key} value={key}>
                            {`${startCase(key)} (${value})`}
                          </option>
                        )
                      )}
                  </Select>
                </FormControl>
              )}

              <Button colorScheme="credo" onClick={handleSubmit}>
                Submit
              </Button>
            </VStack>
          </Box>
        </DashboardCard>
      </>
    </AuthLayout>
  );
};

export default CreateCreditProduct;
