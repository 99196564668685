import React, { useEffect, useRef, useContext } from "react";
import { useIntl } from "react-intl";
import * as am5xy from "@amcharts/amcharts5/xy";
import DeployedVolumeChart from "../charts/DeployedVolumeChart";
import { AccessTokenContext } from "../../context/contextProvider";
import {
  handlePanStarted,
  handlePanEnded,
  handleZooming,
  handleColumnClick,
} from "./eventHandlers";
import { initialFetchAndSetDataToChart } from "./chartData";
import Bucket from "../../bucket";
import { IDeploymentOverTimeParamsV2 } from "../types";

type IZoomLevel = {
  start: number | undefined;
  end: number | undefined;
};

type Props = {
  params: IDeploymentOverTimeParamsV2;
  onViewportChange: (from: string | undefined, to: string | undefined) => void;
};

const DeployedVolume = ({ params, onViewportChange }: Props) => {
  const intl = useIntl();
  const accessToken = useContext(AccessTokenContext).accessToken;

  const { resolution, ...deployedVolumeParams } = params;

  const chartRef = useRef<am5xy.XYChart | null>(null);
  const localParamsRef =
    useRef<IDeploymentOverTimeParamsV2>(deployedVolumeParams);

  const zoomLevel = useRef<IZoomLevel>({
    start: undefined,
    end: undefined,
  });

  const panZoomLevel = useRef<IZoomLevel>({
    start: undefined,
    end: undefined,
  });

  useEffect(() => {
    const chart = chartRef.current;
    if (!chart) return;
    localParamsRef.current = deployedVolumeParams;
    initialFetchAndSetDataToChart(localParamsRef.current, accessToken, chart);
  });

  const setRef = (chart: am5xy.XYChart) => {
    chartRef.current = chart;
  };

  const setZoomLevel = (start: number | undefined, end: number | undefined) => {
    zoomLevel.current = { start, end };
  };

  const setPanZoomLevel = (
    start: number | undefined,
    end: number | undefined
  ) => {
    panZoomLevel.current = { start, end };
  };

  const onPanEnded = () => {
    if (chartRef.current) {
      handlePanEnded(
        chartRef.current,
        localParamsRef.current,
        accessToken,
        panZoomLevel.current,
        setPanZoomLevel,
        onViewportChange
      );
    }
  };

  const onPanStarted = () => {
    if (chartRef.current) {
      handlePanStarted(chartRef.current, setPanZoomLevel);
    }
  };

  const onZoom = () => {
    if (chartRef.current) {
      handleZooming(
        chartRef.current,
        localParamsRef.current,
        accessToken,
        zoomLevel.current,
        setZoomLevel,
        onViewportChange
      );
    }
  };

  const onColumnClick = (bucket: Bucket) => {
    if (chartRef.current) {
      handleColumnClick(
        bucket,
        chartRef.current,
        localParamsRef.current,
        accessToken,
        onViewportChange
      );
    }
  };

  return (
    <>
      <DeployedVolumeChart
        title={intl.formatMessage({
          id: "deployment_originated_per_cohort",
        })}
        setRef={setRef}
        onPanStarted={onPanStarted}
        onPanEnded={onPanEnded}
        onColumnClick={onColumnClick}
        onZoom={onZoom}
      />
    </>
  );
};

export default DeployedVolume;
