export const translations = {
  "alert_monthly_originated_10_percent_below_base_plan": "monthly originated 10 percent below base plan",
  "asset_class_consumer": "Consumer",
  "asset_class_hybrid": "Hybrid",
  "asset_class_other": "Other",
  "asset_class_sme": "SME",
  "credit_performance_30dpd": "30 DPD",
  "credit_performance_60dpd": "60 DPD",
  "credit_performance_90dpd": "90 DPD",
  "credit_performance_default": "DEFAULT",
  "credit_performance_netted": "NETTED",
  "credit_performance_performing": "PERFORMING",
  "credit_performance_repaid": "REPAID",
  "credit_performance_unknown": "UNKNOWN",
  "credit_rating_1": "1",
  "credit_rating_2": "2",
  "credit_rating_3": "3",
  "credit_rating_4": "4",
  "credit_rating_5": "5",
  "employees_0_to_9": "0–9",
  "employees_1000_plus": "1000+",
  "employees_10_to_49": "10–49",
  "employees_250_to_499": "250–499",
  "employees_500_to_999": "500–999",
  "employees_50_to_249": "50–249",
  "employees_unknown": "Unknown",
  "industry_": "All",
  "industry_all": "All",
  "industry_unknown": "Unknown",
  "industry_10": "Energy",
  "industry_1010": "Energy",
  "industry_101010": "Energy Equipment & Services",
  "industry_10101010": "Oil & Gas Drilling",
  "industry_10101020": "Oil & Gas Equipment & Services",
  "industry_101020": "Oil, Gas & Consumable Fuels",
  "industry_10102010": "Integrated Oil & Gas",
  "industry_10102020": "Oil & Gas Exploration & Production",
  "industry_10102030": "Oil & Gas Refining & Marketing",
  "industry_10102040": "Oil & Gas Storage & Transportation",
  "industry_10102050": "Coal & Consumable Fuels",
  "industry_15": "Materials",
  "industry_1510": "Materials",
  "industry_151010": "Chemicals",
  "industry_15101010": "Commodity Chemicals",
  "industry_15101020": "Diversified Chemicals",
  "industry_15101030": "Fertilizers & Agricultural Chemicals",
  "industry_15101040": "Industrial Gases",
  "industry_15101050": "Specialty Chemicals",
  "industry_151020": "Construction Materials",
  "industry_15102010": "Construction Materials",
  "industry_151030": "Containers & Packaging",
  "industry_15103010": "Metal & Glass Containers",
  "industry_15103020": "Paper Packaging",
  "industry_151040": "Metals & Mining",
  "industry_15104010": "Aluminum",
  "industry_15104020": "Diversified Metals & Mining",
  "industry_15104025": "Copper",
  "industry_15104030": "Gold",
  "industry_15104040": "Precious Metals & Minerals",
  "industry_15104045": "Silver",
  "industry_15104050": "Steel",
  "industry_151050": "Paper & Forest Products",
  "industry_15105010": "Forest Products",
  "industry_15105020": "Paper Products",
  "industry_20": "Industrials",
  "industry_2010": "Capital Goods",
  "industry_201010": "Aerospace & Defense",
  "industry_20101010": "Aerospace & Defense",
  "industry_201020": "Building Products",
  "industry_20102010": "Building Products",
  "industry_201030": "Construction & Engineering",
  "industry_20103010": "Construction & Engineering",
  "industry_201040": "Electrical Equipment",
  "industry_20104010": "Electrical Components & Equipment",
  "industry_20104020": "Heavy Electrical Equipment",
  "industry_201050": "Industrial Conglomerates",
  "industry_20105010": "Industrial Conglomerates",
  "industry_201060": "Machinery",
  "industry_20106010": "Construction Machinery & Heavy Trucks",
  "industry_20106015": "Agricultural & Farm Machinery",
  "industry_20106020": "Industrial Machinery",
  "industry_201070": "Trading Companies & Distributors",
  "industry_20107010": "Trading Companies & Distributors",
  "industry_2020": "Commercial  & Professional Services",
  "industry_202010": "Commercial Services & Supplies",
  "industry_20201010": "Commercial Printing",
  "industry_20201050": "Environmental & Facilities Services",
  "industry_20201060": "Office Services & Supplies",
  "industry_20201070": "Diversified Support Services",
  "industry_20201080": "Security & Alarm Services",
  "industry_202020": "Professional Services",
  "industry_20202010": "Human Resource & Employment Services",
  "industry_20202020": "Research & Consulting Services",
  "industry_2030": "Transportation",
  "industry_203010": "Air Freight & Logistics",
  "industry_20301010": "Air Freight & Logistics",
  "industry_203020": "Airlines",
  "industry_20302010": "Airlines",
  "industry_203030": "Marine",
  "industry_20303010": "Marine",
  "industry_203040": "Road & Rail",
  "industry_20304010": "Railroads",
  "industry_20304020": "Trucking",
  "industry_203050": "Transportation Infrastructure",
  "industry_20305010": "Airport Services",
  "industry_20305020": "Highways & Railtracks",
  "industry_20305030": "Marine Ports & Services",
  "industry_25": "Consumer Discretionary",
  "industry_2510": "Automobiles & Components",
  "industry_251010": "Auto Components",
  "industry_25101010": "Auto Parts & Equipment",
  "industry_25101020": "Tires & Rubber",
  "industry_251020": "Automobiles",
  "industry_25102010": "Automobile Manufacturers",
  "industry_25102020": "Motorcycle Manufacturers",
  "industry_2520": "Consumer Durables & Apparel",
  "industry_252010": "Household Durables",
  "industry_25201010": "Consumer Electronics",
  "industry_25201020": "Home Furnishings",
  "industry_25201030": "Homebuilding",
  "industry_25201040": "Household Appliances",
  "industry_25201050": "Housewares & Specialties",
  "industry_252020": "Leisure Products",
  "industry_25202010": "Leisure Products",
  "industry_252030": "Textiles, Apparel & Luxury Goods",
  "industry_25203010": "Apparel, Accessories & Luxury Goods",
  "industry_25203020": "Footwear",
  "industry_25203030": "Textiles",
  "industry_2530": "Consumer Services",
  "industry_253010": "Hotels, Restaurants & Leisure",
  "industry_25301010": "Casinos & Gaming",
  "industry_25301020": "Hotels, Resorts & Cruise Lines",
  "industry_25301030": "Leisure Facilities",
  "industry_25301040": "Restaurants",
  "industry_253020": "Diversified Consumer Services",
  "industry_25302010": "Education Services",
  "industry_25302020": "Specialized Consumer Services",
  "industry_2550": "Retailing",
  "industry_255010": "Distributors",
  "industry_25501010": "Distributors",
  "industry_255020": "Internet & Direct Marketing Retail",
  "industry_25502020": "Internet & Direct Marketing Retail",
  "industry_255030": "Multiline Retail",
  "industry_25503010": "Department Stores",
  "industry_25503020": "General Merchandise Stores",
  "industry_255040": "Specialty Retail",
  "industry_25504010": "Apparel Retail",
  "industry_25504020": "Computer & Electronics Retail",
  "industry_25504030": "Home Improvement Retail",
  "industry_25504040": "Specialty Stores",
  "industry_25504050": "Automotive Retail",
  "industry_25504060": "Homefurnishing Retail",
  "industry_30": "Consumer Staples",
  "industry_3010": "Food & Staples Retailing",
  "industry_301010": "Food & Staples Retailing",
  "industry_30101010": "Drug Retail",
  "industry_30101020": "Food Distributors",
  "industry_30101030": "Food Retail",
  "industry_30101040": "Hypermarkets & Super Centers",
  "industry_3020": "Food, Beverage & Tobacco",
  "industry_302010": "Beverages",
  "industry_30201010": "Brewers",
  "industry_30201020": "Distillers & Vintners",
  "industry_30201030": "Soft Drinks",
  "industry_302020": "Food Products",
  "industry_30202010": "Agricultural Products",
  "industry_30202030": "Packaged Foods & Meats",
  "industry_302030": "Tobacco",
  "industry_30203010": "Tobacco",
  "industry_3030": "Household & Personal Products",
  "industry_303010": "Household Products",
  "industry_30301010": "Household Products",
  "industry_303020": "Personal Products",
  "industry_30302010": "Personal Products",
  "industry_35": "Health Care",
  "industry_3510": "Health Care Equipment & Services",
  "industry_351010": "Health Care Equipment & Supplies",
  "industry_35101010": "Health Care Equipment",
  "industry_35101020": "Health Care Supplies",
  "industry_351020": "Health Care Providers & Services",
  "industry_35102010": "Health Care Distributors",
  "industry_35102015": "Health Care  Services",
  "industry_35102020": "Health Care Facilities",
  "industry_35102030": "Managed Health Care",
  "industry_351030": "Health Care Technology",
  "industry_35103010": "Health Care Technology",
  "industry_3520": "Pharmaceuticals, Biotechnology & Life Sciences",
  "industry_352010": "Biotechnology",
  "industry_35201010": "Biotechnology",
  "industry_352020": "Pharmaceuticals",
  "industry_35202010": "Pharmaceuticals",
  "industry_352030": "Life Sciences Tools & Services",
  "industry_35203010": "Life Sciences Tools & Services",
  "industry_40": "Financials",
  "industry_4010": "Banks",
  "industry_401010": "Banks",
  "industry_40101010": "Diversified Banks",
  "industry_40101015": "Regional Banks",
  "industry_401020": "Thrifts & Mortgage Finance",
  "industry_40102010": "Thrifts & Mortgage Finance",
  "industry_4020": "Diversified Financials",
  "industry_402010": "Diversified Financial Services",
  "industry_40201020": "Other Diversified Financial Services",
  "industry_40201030": "Multi-Sector Holdings",
  "industry_40201040": "Specialized Finance",
  "industry_402020": "Consumer Finance",
  "industry_40202010": "Consumer Finance",
  "industry_402030": "Capital Markets",
  "industry_40203010": "Asset Management & Custody Banks",
  "industry_40203020": "Investment Banking & Brokerage",
  "industry_40203030": "Diversified Capital Markets",
  "industry_40203040": "Financial Exchanges & Data",
  "industry_402040": "Mortgage Real Estate Investment Trusts (REITs)",
  "industry_40204010": "Mortgage REITs",
  "industry_4030": "Insurance",
  "industry_403010": "Insurance",
  "industry_40301010": "Insurance Brokers",
  "industry_40301020": "Life & Health Insurance",
  "industry_40301030": "Multi-line Insurance",
  "industry_40301040": "Property & Casualty Insurance",
  "industry_40301050": "Reinsurance",
  "industry_45": "Information Technology",
  "industry_4510": "Software & Services",
  "industry_451020": "IT Services",
  "industry_45102010": "IT Consulting & Other Services",
  "industry_45102020": "Data Processing & Outsourced Services",
  "industry_45102030": "Internet Services & Infrastructure",
  "industry_451030": "Software",
  "industry_45103010": "Application Software",
  "industry_45103020": "Systems Software",
  "industry_4520": "Technology Hardware & Equipment",
  "industry_452010": "Communications Equipment",
  "industry_45201020": "Communications Equipment",
  "industry_452020": "Technology Hardware, Storage & Peripherals",
  "industry_45202030": "Technology Hardware, Storage & Peripherals",
  "industry_452030": "Electronic Equipment, Instruments & Components",
  "industry_45203010": "Electronic Equipment & Instruments",
  "industry_45203015": "Electronic Components",
  "industry_45203020": "Electronic Manufacturing Services",
  "industry_45203030": "Technology Distributors",
  "industry_4530": "Semiconductors & Semiconductor Equipment",
  "industry_453010": "Semiconductors & Semiconductor Equipment",
  "industry_45301010": "Semiconductor Equipment",
  "industry_45301020": "Semiconductors",
  "industry_50": "Communication Services",
  "industry_5010": "Telecommunication Services",
  "industry_501010": "Diversified Telecommunication Services",
  "industry_50101010": "Alternative Carriers",
  "industry_50101020": "Integrated Telecommunication Services",
  "industry_501020": "Wireless Telecommunication Services",
  "industry_50102010": "Wireless Telecommunication Services",
  "industry_5020": "Media & Entertainment",
  "industry_502010": "Media",
  "industry_50201010": "Advertising",
  "industry_50201020": "Broadcasting",
  "industry_50201030": "Cable & Satellite",
  "industry_50201040": "Publishing",
  "industry_502020": "Entertainment",
  "industry_50202010": "Movies & Entertainment",
  "industry_50202020": "Interactive Home Entertainment",
  "industry_502030": "Interactive Media & Services",
  "industry_50203010": "Interactive Media & Services",
  "industry_55": "Utilities",
  "industry_5510": "Utilities",
  "industry_551010": "Electric Utilities",
  "industry_55101010": "Electric Utilities",
  "industry_551020": "Gas Utilities",
  "industry_55102010": "Gas Utilities",
  "industry_551030": "Multi-Utilities",
  "industry_55103010": "Multi-Utilities",
  "industry_551040": "Water Utilities",
  "industry_55104010": "Water Utilities",
  "industry_551050": "Independent Power and Renewable Electricity Producers",
  "industry_55105010": "Independent Power Producers & Energy Traders",
  "industry_55105020": "Renewable Electricity",
  "industry_60": "Real Estate",
  "industry_6010": "Real Estate",
  "industry_601010": "Equity Real Estate Investment Trusts (REITs)",
  "industry_60101010": "Diversified REITs",
  "industry_60101020": "Industrial REITs",
  "industry_60101030": "Hotel & Resort REITs",
  "industry_60101040": "Office REITs",
  "industry_60101050": "Health Care REITs",
  "industry_60101060": "Residential REITs",
  "industry_60101070": "Retail REITs",
  "industry_60101080": "Specialized REITs",
  "industry_601020": "Real Estate Management & Development",
  "industry_60102010": "Diversified Real Estate Activities",
  "industry_60102020": "Real Estate Operating Companies",
  "industry_60102030": "Real Estate Development",
  "industry_60102040": "Real Estate Services",
  "product_type_annuity": "Annuity",
  "product_type_balloon": "Balloon",
  "product_type_basic_term_loan": "Term Loan",
  "product_type_revenue_finance": "Revenue Finance",
  "product_type_revolving": "Revolving",
  "turnover_0_50k": "0–50k",
  "turnover_100k_500k": "100-500k",
  "turnover_10m_50m": "10–50M",
  "turnover_1m_2m": "1-2M",
  "turnover_2m_3m": "2–3M",
  "turnover_3m_4m": "3–4M",
  "turnover_4m_5m": "4–5M",
  "turnover_2m_5m": "2-5M",
  "turnover_500k_1m": "0.5-1M",
  "turnover_50_100k": "50-100k",
  "turnover_50m_plus": "50M+",
  "turnover_5m_10m": "5–10M",
  "turnover_unknown": "Unknown"
};
