import { useLayoutEffect } from "react";
import { Box } from "@chakra-ui/react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { IAxisLabel } from "@amcharts/amcharts5/.internal/charts/xy/axes/AxisLabel";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import ChartsTheme from "../../theme/amcharts";
import Bucket from "../../bucket";
import { IPlottableCohortBalanceDataItem } from "../types";

type Props = {
  data: IPlottableCohortBalanceDataItem[];
};

const CohortBalanceChart = ({ data }: Props) => {
  useLayoutEffect(() => {
    let root = am5.Root.new("cohortBalanceChart");
    let legendRoot = am5.Root.new("cohortBalanceChartLegend");

    root.setThemes([am5themes_Animated.new(root), ChartsTheme.new(root)]);
    legendRoot.setThemes([am5themes_Animated.new(root), ChartsTheme.new(root)]);

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        focusable: true,
      })
    );
    chart.zoomOutButton.set("forceHidden", true);

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        numberFormat: "#'%'",
        maxPrecision: 0,
        min: 0,
        calculateTotals: true,
        renderer: am5xy.AxisRendererY.new(root, {
          strokeOpacity: 0.5,
          strokeWidth: 1,
        }),
      })
    );

    let yRenderer = yAxis.get("renderer");
    yRenderer.labels.template.setAll({
      textAlign: "right",
    });

    let xRenderer = am5xy.AxisRendererX.new(root, {
      strokeOpacity: 0.5,
      strokeWidth: 1,
    });
    xRenderer.grid.template.set("visible", false);

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "bucket",
        tooltip: am5.Tooltip.new(root, {}),
        renderer: xRenderer,
      })
    );

    xAxis.data.setAll(data);

    xAxis.get("renderer").labels.template.setAll({
      minPosition: 0.01,
      maxPosition: 0.99,
      location: 0.5,
      multiLocation: 0.5,
    });

    xAxis
      .get("renderer")
      .labels.template.adapters.add("text", function (text, target) {
        const targetAxisLabel = target as IAxisLabel | undefined;
        const dataContext = targetAxisLabel?.dataItem?.dataContext as {
          bucket: string;
        };
        if (dataContext) {
          const bucketValue = dataContext.bucket;
          return Bucket.fromDataSourceBucketString(bucketValue).formatBucket();
        }
        return text;
      });

    let legend = legendRoot.container.children.push(
      am5.Legend.new(legendRoot, {
        paddingTop: 6,
        paddingLeft: 20,
        useDefaultMarker: true,
      })
    );

    legend.labels.template.setAll({
      fontSize: 12,
      fontWeight: "300",
      textAlign: "right",
    });

    legend.valueLabels.template.setAll({
      forceHidden: true,
    });

    const generateColumnSeries = (
      name: string,
      valueType: string,
      color: string
    ) => {
      const series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: valueType,
          categoryXField: "bucket",
          legendLabelText: name,
          fill: am5.color(color),
          stacked: true,
        })
      );

      series.columns.template.setAll({
        tooltipText: "{name}: {valueY}",
        width: am5.percent(90),
        tooltipY: 0,
      });

      series.data.setAll(data);

      return series;
    };

    let cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        xAxis: xAxis,
      })
    );
    cursor.lineY.set("visible", false);

    generateColumnSeries("Principal settled", "principal_settled", "#287A92");
    generateColumnSeries("Interest settled", "interest_settled", "#85CDD9");
    generateColumnSeries(
      "Principal remaining",
      "principal_remaining",
      "#869EA6"
    );
    generateColumnSeries("Interest remaining", "interest_remaining", "#BCC9CD");

    legend.data.setAll(chart.series.values);

    chart.appear(1000, 100);

    return () => {
      root.dispose();
      legendRoot.dispose();
    };
  });

  return (
    <Box>
      <Box id={"cohortBalanceChartLegend"} h="56px" />
      <Box
        id="cohortBalanceChart"
        style={{ width: "100%", height: "250px" }}
      ></Box>
    </Box>
  );
};

export default CohortBalanceChart;
