import { useContext } from "react";
import { IDeploymentTotalsParams } from "./types";
import { useGetDeploymentTotalsQuery } from "./service";
import { AccessTokenContext } from "../context/contextProvider";

type Props = {
  params: IDeploymentTotalsParams;
};

const LoansNo = ({ params }: Props) => {
  const { data } = useGetDeploymentTotalsQuery({
    ...params,
    accessToken: useContext(AccessTokenContext).accessToken,
  });
  const totals = data?.data;
  return <>{totals && <>Looking at {totals.credits} loans</>}</>;
};

export default LoansNo;
