import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  ILoanBookDataSet,
  ILoanBookParams,
  ICreditEventsDataSet,
  ILoanBookCreditEventsParams,
} from "./types";
import { API_BASE_URL } from "../config";

export const loanBookApi = createApi({
  reducerPath: "loanBookApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: (builder) => ({
    getLoanBook: builder.query<ILoanBookDataSet, ILoanBookParams>({
      query: (params: ILoanBookParams) => {
        const { originatorId, accessToken, from, to, ...queryParams } = params;
        return {
          url: `v1/originator/${originatorId}/loanbook`,
          params: queryParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      transformResponse: (response: ILoanBookDataSet) => {
        response.data.forEach((dataPoint) => {
          dataPoint.observation_date = response.data_set.updated_at
            ? response.data_set.updated_at
            : "";
        });
        return response;
      },
    }),
    getLoanBookCreditEvents: builder.query<
      ICreditEventsDataSet,
      ILoanBookCreditEventsParams
    >({
      query: (params: ILoanBookCreditEventsParams) => {
        const { originatorId, creditId } = params;
        return {
          url: `v1/originator/${originatorId}/loanbook/${creditId}/event`,
          headers: {
            Authorization: `Bearer ${params.accessToken}`,
          },
        };
      },
    }),
  }),
});

export const { useGetLoanBookQuery, useGetLoanBookCreditEventsQuery } =
  loanBookApi;
