import React from "react";
import { FormattedDateParts } from "react-intl";

type Props = {
  value: string;
  format?: string;
};
const DateFormatter = ({ value }: Props) => {
  return (
    <FormattedDateParts
      value={value}
      year="numeric"
      month="2-digit"
      day="2-digit"
    >
      {(parts) => {
        return <>{`${parts[4].value}-${parts[0].value}-${parts[2].value}`}</>;
      }}
    </FormattedDateParts>
  );
};

export default DateFormatter;
