import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Box, Text } from "@chakra-ui/react";
import { Folder20 } from "@carbon/icons-react";
import TermsAndConditions from "./TermsAndConditions";
import { IDataRoom } from "./types";

interface Props {
  dataRooms: IDataRoom[];
}

const DataRooms = ({ dataRooms }: Props) => {
  const [, setSearchParams] = useSearchParams();

  const [showTnCModal, setShowTnCModal] = useState<boolean>(false);
  const [selectedDataRoom, setSelectedDataRoom] = useState<IDataRoom | null>(
    null
  );

  const handleDataRoomClick = (dataRoom: IDataRoom) => {
    setSelectedDataRoom(dataRoom);
    if (!dataRoom.accepted_tnc) {
      setShowTnCModal(true);
    } else {
      setSearchParams({ data_room_id: dataRoom.data_room_id });
    }
  };

  const handleCloseModal = () => {
    setShowTnCModal(false);
  };

  return (
    <>
      {dataRooms.map((dataRoom, idx) => {
        return (
          <Box key={idx} onClick={(e) => handleDataRoomClick(dataRoom)}>
            <Box
              bg="#fff"
              p="4"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              cursor="pointer"
              transition="all 120ms ease-in-out"
              fontSize="14px"
              lineHeight="normal"
              borderTop="1px solid #E2E8F0"
              // _notLast={{
              //   borderBottom: "1px solid #E2E8F0",
              // }}
              _hover={{
                bg: "#F2F8FB",
              }}
            >
              <Box display="flex" alignItems="center" flex="1" fontWeight="600">
                <Folder20 style={{ width: "20px", minWidth: "20px" }} />
                <Text ml="8px">{dataRoom.name}</Text>
              </Box>
              <Box
                display={{ base: "none", md: "flex" }}
                alignItems="center"
                w="180px"
                pl="4"
                justifyContent="flex-end"
                textAlign="right"
              >
                <Text>—</Text>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                w="100px"
                pl="4"
                justifyContent="flex-end"
                textAlign="right"
              >
                <Text>—</Text>
              </Box>
            </Box>
          </Box>
        );
      })}
      {selectedDataRoom && showTnCModal && (
        <TermsAndConditions
          dataRoomId={selectedDataRoom.data_room_id}
          context={selectedDataRoom.data_room_id}
          agreementKey={selectedDataRoom.agreement_key}
          isModalOpen={showTnCModal}
          closeModal={handleCloseModal}
        />
      )}
    </>
  );
};

export default DataRooms;
