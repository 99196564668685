import { Box } from "@chakra-ui/react";
import { useIntl } from "react-intl";
import { IPerformanceTriggersOverTimeParams } from "../../risk/types";
import { useGetPerformanceTriggersOverTimeQuery } from "../../risk/services";
import PerformingOutstandingBalanceChart from "./PerformingOutstandingBalanceChart";
import Loader from "../../shared/Loader";

type Props = {
  params: IPerformanceTriggersOverTimeParams;
};

interface DataSet {
  date: number;
  value: number;
}

const DeploymentPerforming = ({ params }: Props) => {
  const intl = useIntl();
  const { data, isFetching } = useGetPerformanceTriggersOverTimeQuery(params);
  const dataSource = data?.data ? data?.data : [];

  const performingOutstanding: DataSet[] = [];

  for (const data of dataSource) {
    const view_date = new Date(data.totals.view_date).getTime();
    performingOutstanding.push({
      date: view_date,
      value: data.totals.performing_outstanding_principal,
    });
  }
  return (
    <Box w="full" borderColor="gray.200">
      {isFetching && <Loader />}
      {!isFetching && (
        <PerformingOutstandingBalanceChart
          title={intl.formatMessage({
            id: "deployment_performing_balance",
          })}
          dataSet={performingOutstanding}
        />
      )}
    </Box>
  );
};

export default DeploymentPerforming;
