import React from "react";
import Badge from "../../shared/Badge";

type Props = {
  daysNo: number;
};

const DaysOverdue = ({ daysNo }: Props) => {
  function getBadgeColor(daysNo: number): string {
    let color;
    if (daysNo <= 10) {
      color = "blue";
    } else if (daysNo > 10 && daysNo <= 40) {
      color = "orange";
    } else {
      color = "red";
    }
    return color;
  }

  return <Badge color={getBadgeColor(daysNo)} label={`${daysNo}`} />;
};

export default DaysOverdue;
