import React from "react";
import Badge from "../../shared/Badge";
import { ICreditPerformance } from "../types";

type Props = {
  performance: ICreditPerformance;
};

const CreditPerformance = ({ performance }: Props) => {
  const CREDIT_PERFORMANCE: Record<
    ICreditPerformance,
    Record<string, string>
  > = {
    performing: { label: "performing", color: "blue" },
    overdue: { label: "overdue", color: "orange" },
    "30dpd": { label: "30 DPD", color: "red" },
    "60dpd": { label: "60 DPD", color: "red" },
  };

  let color = "gray";
  let label = performance;

  if (CREDIT_PERFORMANCE[performance]) {
    color = CREDIT_PERFORMANCE[performance]["color"];
    label = CREDIT_PERFORMANCE[performance]["label"];
  }

  return <Badge color={color} label={label} />;
};

export default CreditPerformance;
