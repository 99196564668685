import React, { useContext } from "react";
import { AccessTokenContext } from "../context/contextProvider";
import { useGetDataRoomContentQuery } from "./service";
import File from "./File";
import Directory from "./Directory";
import Loader from "../shared/Loader";
import { IDirectoryContent, IFileContent } from "./types";

type Props = {
  dataRoomId: string;
  state?: string;
};

const Content = ({ dataRoomId, state }: Props) => {
  const accessToken = useContext(AccessTokenContext).accessToken;

  const { data, isFetching } = useGetDataRoomContentQuery({
    accessToken,
    data_room_id: dataRoomId,
    state,
  });

  const content = (data && data.contents) || [];
  const files = content.filter((item) => item.type === "file");
  const directories = content.filter((item) => item.type === "prefix");
  const sortedContent = [...directories, ...files];

  return (
    <>
      {isFetching && <Loader />}
      {!isFetching &&
        sortedContent.map((item, idx) => {
          if ("key" in item) {
            return <File key={idx} file={item as IFileContent} />;
          } else {
            return (
              <Directory key={idx} directory={item as IDirectoryContent} />
            );
          }
        })}
    </>
  );
};

export default Content;
