import { useContext, useRef } from "react";
import { FaCopy } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Flex,
  Text,
  Button,
  Stack,
  Divider,
  Badge,
} from "@chakra-ui/react";
import { useGetCreditProductsQuery } from "../service";
import Loader from "../../shared/Loader";
import AuthLayout from "../../shared/AuthLayout";
import {
  AccessTokenContext,
  OriginatorIdContext,
} from "../../context/contextProvider";
import DashboardCard from "../../dashboard/DashboardCard";
import { startCase } from "lodash";

const CreditProduct = () => {
  const navigate = useNavigate();
  const copyRef = useRef(null);

  const accessToken = useContext(AccessTokenContext).accessToken;
  const originatorId = useContext(OriginatorIdContext).originatorId;

  const { data, isFetching, error } = useGetCreditProductsQuery({
    originator_id: originatorId,
    accessToken: accessToken,
  });
  const products = data?.data;

  return (
    <AuthLayout>
      <>
        {isFetching && !data && <Loader />}

        {!isFetching && error && (
          <Box>There's an error. Team is looking into it.</Box>
        )}

        {!isFetching && !error && data && (
          <DashboardCard
            title="Credit Products"
            w="full"
            actions={
              <Button
                colorScheme="credo"
                onClick={() => navigate("/credit-product/create")}
              >
                New Credit Product
              </Button>
            }
          >
            <Stack>
              {products &&
                products.map((product, index) => (
                  <Box
                    key={index}
                    borderWidth="1px"
                    borderRadius="lg"
                    overflow="hidden"
                    boxShadow="md"
                  >
                    <Stack p={4} spacing={4}>
                      <Flex justifyContent="flex-start" alignItems="center">
                        <Text mr={4} fontWeight="bold" fontSize="lg">
                          {startCase(product.name)}
                        </Text>
                        <Badge colorScheme="green">{product.status}</Badge>
                      </Flex>

                      <Divider />

                      <Flex justifyContent="flex-start">
                        <Text mr={4}>Product Id:</Text>
                        <Text ref={copyRef}>{product.product_id}</Text>
                        <FaCopy
                          style={{ marginLeft: "16px", cursor: "pointer" }}
                          onClick={() => {
                            navigator.clipboard.writeText(product.product_id);
                          }}
                        />
                      </Flex>

                      {product.metadata.country && (
                        <Flex justifyContent="flex-start">
                          <Text mr={4}>Country:</Text>
                          <Text>{startCase(product.metadata.country)}</Text>
                        </Flex>
                      )}

                      {product.metadata.repayment_frequency && (
                        <Flex justifyContent="flex-start">
                          <Text mr={4}>Repayment Frequency:</Text>
                          <Text mr={2}>
                            {startCase(
                              product.metadata.repayment_frequency.method
                            )}
                          </Text>
                          <Text>
                            {product.metadata.repayment_frequency.value}
                          </Text>
                        </Flex>
                      )}

                      {product.metadata.interest && (
                        <Flex justifyContent="flex-start">
                          <Text mr={4}>Interest Method:</Text>
                          <Text>
                            {startCase(product.metadata.interest.method)}
                          </Text>
                        </Flex>
                      )}

                      {product.metadata.interest && (
                        <Flex justifyContent="flex-start">
                          <Text mr={4}>Repayment Model:</Text>
                          <Text>
                            {startCase(
                              product.metadata.interest.repayment_model
                            )}
                          </Text>
                        </Flex>
                      )}

                      {product.metadata.fixed_fee && (
                        <Flex justifyContent="flex-start">
                          <Text mr={4}>Fixed Fee:</Text>
                          <Text mr={2}>
                            {startCase(product.metadata.fixed_fee.method)}
                          </Text>
                          <Text>
                            {startCase(product.metadata.fixed_fee.waterfall)}
                          </Text>
                        </Flex>
                      )}

                      {product.metadata.balloon && (
                        <Flex justifyContent="flex-start">
                          <Text mr={4}>Balloon Type:</Text>
                          <Text mr={2}>
                            {startCase(product.metadata.balloon.method)}
                          </Text>
                        </Flex>
                      )}

                      {product.metadata.setup_fee && (
                        <Flex justifyContent="flex-start">
                          <Text mr={4}>Setup Fee:</Text>
                          <Text mr={2}>
                            {startCase(product.metadata.setup_fee.method)}
                          </Text>
                          <Text>
                            {startCase(product.metadata.setup_fee.waterfall)}
                          </Text>
                        </Flex>
                      )}

                      {product.metadata.other_fee && (
                        <Flex justifyContent="flex-start">
                          <Text mr={4}>Other Fee:</Text>
                          <Text mr={2}>
                            {startCase(product.metadata.other_fee.method)}
                          </Text>
                          <Text>
                            {startCase(product.metadata.other_fee.frequency)}
                          </Text>
                        </Flex>
                      )}

                      {product.metadata.termination_fee && (
                        <Flex justifyContent="flex-start">
                          <Text mr={4}>Terminaation Fee:</Text>
                          <Text mr={2}>
                            {startCase(product.metadata.termination_fee.method)}
                          </Text>
                        </Flex>
                      )}

                      {product.metadata.late_fee && (
                        <Flex justifyContent="flex-start">
                          <Text mr={4}>Late Fee:</Text>
                          <Text>
                            Fee1: {product.metadata.late_fee.fee1.value} (DPD:{" "}
                            {product.metadata.late_fee.fee1.dpd}), Fee2:{" "}
                            {product.metadata.late_fee.fee2
                              ? `${product.metadata.late_fee.fee2.value} (DPD: ${product.metadata.late_fee.fee2.dpd})`
                              : "N/A"}
                            , Fee3:{" "}
                            {product.metadata.late_fee.fee3
                              ? `${product.metadata.late_fee.fee3.value} (DPD: ${product.metadata.late_fee.fee3.dpd})`
                              : "N/A"}
                          </Text>
                        </Flex>
                      )}

                      {product.metadata.payment_holiday && (
                        <Flex justifyContent="flex-start">
                          <Text mr={4}>Payment Holiday Plan:</Text>
                          <Text>
                            {startCase(product.metadata.payment_holiday.plan)}
                          </Text>
                        </Flex>
                      )}

                      {product.metadata.bank_holiday && (
                        <Flex justifyContent="flex-start">
                          <Text mr={4}>Bank Holiday Due Date Shift:</Text>
                          <Text>
                            {startCase(
                              product.metadata.bank_holiday.due_date_shift
                            )}
                          </Text>
                        </Flex>
                      )}

                      <Flex justifyContent="flex-start">
                        <Text mr={4}>Updated At:</Text>
                        <Text>
                          {new Date(product.updated_at).toLocaleString()}
                        </Text>
                      </Flex>

                      <Flex justifyContent="flex-start">
                        <Text mr={4}>Created At:</Text>
                        <Text>
                          {new Date(product.created_at).toLocaleString()}
                        </Text>
                      </Flex>
                    </Stack>
                  </Box>
                ))}
            </Stack>
          </DashboardCard>
        )}
      </>
    </AuthLayout>
  );
};

export default CreditProduct;
