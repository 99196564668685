import { useContext } from "react";
import { Box, Text, Link } from "@chakra-ui/react";
import AuthLayout from "../../shared/AuthLayout";
import DashboardCard from "../../dashboard/DashboardCard";
import { useGetSessionQuery } from "../../user/session/service";
import { AccessTokenContext } from "../../context/contextProvider";
import Loader from "../../shared/Loader";

const Contact = () => {
  const { data, isFetching } = useGetSessionQuery({
    accessToken: useContext(AccessTokenContext).accessToken,
  });

  return (
    <AuthLayout>
      <>
        {isFetching && <Loader />}
        {!isFetching && (
          <DashboardCard title="Contact Scayl" w="full">
            <Box p="4">
              <Text>{`Hi ${data?.name || ""},`}</Text>
              <Text mt="4">
                Please don't hesitate to reach out for questions and/or
                feedback. Drop us a note at{" "}
                <Link
                  href="mailto:support@scayl.finance"
                  isExternal
                  color="#003342"
                  fontWeight="600"
                >
                  support@scayl.finance
                </Link>{" "}
                and we'll get back to you shortly.
              </Text>
              <Text mt="4">/ The Scayl Team</Text>
            </Box>
          </DashboardCard>
        )}
      </>
    </AuthLayout>
  );
};

export default Contact;
