import { useSearchParams } from "react-router-dom";
import moment from "moment";
import { Box, Text } from "@chakra-ui/react";
import { Folder20 } from "@carbon/icons-react";
import { IDirectoryContent } from "./types";
interface Props {
  directory: IDirectoryContent;
}

const Directory = ({ directory }: Props) => {
  const [, setSearchParams] = useSearchParams();

  const { name, modified, data_room_id, state } = directory;

  const handleOnClick = () => {
    setSearchParams({ data_room_id, state });
  };

  return (
    <Box
      onClick={handleOnClick}
      bg="#fff"
      p="4"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      cursor="pointer"
      transition="all 120ms ease-in-out"
      fontSize="14px"
      lineHeight="normal"
      borderTop="1px solid #E2E8F0"
      // _notLast={{
      //   borderBottom: "1px solid #E2E8F0",
      // }}
      _hover={{
        bg: "#F2F8FB",
      }}
    >
      <Box display="flex" alignItems="center" flex="1" fontWeight="600">
        <Folder20 style={{ width: "20px", minWidth: "20px" }} />
        <Text ml="8px">{name}</Text>
      </Box>
      <Box
        display={{ base: "none", md: "flex" }}
        alignItems="center"
        w="180px"
        pl="4"
        justifyContent="flex-end"
        textAlign="right"
      >
        <Text>{moment(modified).format("YYYY-MM-DD, HH:MM")}</Text>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        w="100px"
        pl="4"
        justifyContent="flex-end"
        textAlign="right"
      >
        <Text>—</Text>
      </Box>
    </Box>
  );
};

export default Directory;
