import { Box } from "@chakra-ui/react";
import { useIntl } from "react-intl";
import { useGetLatePaymentsDistributionQuery } from "../../services";
import LoansWithLatePaymentsChart from "./LoansWithLatePaymentsChart";
import { getPlottableData } from "./chartData";
import { IRiskFilters } from "../../types";
import Loader from "../../../shared/Loader";

type Props = {
  params: IRiskFilters;
};

const LoansWithLatePayments = ({ params }: Props) => {
  const intl = useIntl();

  const { data, isFetching } = useGetLatePaymentsDistributionQuery(params);

  const dataSource = data?.data ? getPlottableData(data.data) : [];

  return (
    <Box>
      {isFetching && <Loader />}
      {!isFetching && (
        <LoansWithLatePaymentsChart
          title={intl.formatMessage({ id: "risk_loans_with_late_payments" })}
          data={dataSource}
        />
      )}
    </Box>
  );
};

export default LoansWithLatePayments;
