import React from "react";
import DataStat from "../../shared/DataStat";
import { SimpleGrid } from "@chakra-ui/react";
import { Percent, Money } from "../../locale/formatters";
import { useIntl } from "react-intl";
import { IRiskTotalsValue } from "../types";

type Props = {
  totals?: IRiskTotalsValue;
};

const RiskTotalsView = ({ totals }: Props) => {
  const intl = useIntl();
  return (
    <>
      {totals && (
        <SimpleGrid
          columns={{ base: 2, lg: 4 }}
          width="100%"
          borderColor="gray.200"
          borderLeftWidth={{ base: "0", xl: "0" }}
          textAlign={{ base: "center", lg: "center", xl: "center" }}
        >
          <DataStat
            label={intl.formatMessage({ id: "risk_avg_pd" })}
            value={<Percent value={totals.pd_average} />}
            p={{ base: "8px 16px", lg: 4 }}
            borderColor="gray.200"
            borderBottom="1px"
          />
          <DataStat
            label={intl.formatMessage({ id: "risk_avg_lgd" })}
            value={<Percent value={totals.lgd_average} />}
            p={{ base: "8px 16px", lg: 4 }}
            borderColor="gray.200"
            borderBottom="1px"
            borderLeft={{ base: "1px", lg: "1px", xl: "1px" }}
          />
          <DataStat
            label={intl.formatMessage({ id: "risk_avg_ecl" })}
            value={<Percent value={totals.ecl_average} />}
            p={{ base: "8px 16px", lg: 4 }}
            borderColor="gray.200"
            borderBottom="1px"
            borderLeft={{ base: "1px", lg: "1px", xl: "1px" }}
          />

          <DataStat
            label={intl.formatMessage({ id: "risk_written_off" })}
            value={<Money value={totals.written_off} />}
            p={{ base: "8px 16px", lg: 4 }}
            borderColor="gray.200"
            borderLeft={{ base: "1px", lg: "1px", xl: "1px" }}
            borderBottom={{ base: "1px", lg: "1px", xl: "1px" }}
          />
        </SimpleGrid>
      )}
    </>
  );
};

export default RiskTotalsView;
