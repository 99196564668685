import { configureStore } from "@reduxjs/toolkit";
import { deploymentApi } from "./deployment/service";
import { loanBookApi } from "./loanbook/service";
import { creditProductApi } from "./credit-product/service";
import { originatorApiDocsApi } from "./apidocs/service";
import { dashboardApi } from "./dashboard/service";
import { alertsApi } from "./alerts/service";
import { originatorApi } from "./originator/services";
import { userSessionApi } from "./user/session/service";
import { downloadApi } from "./download/service";
import { uploadApi } from "./data-sourcing/file-upload/service";
import { setupListeners } from "@reduxjs/toolkit/query";
import { dataRoomApi } from "./data-room/service";
import { riskApi, covenantsApi } from "./risk/services";
import { rtkQueryErrorInterceptor } from "./errors/rtkQueryErrorInterceptor";
export const store = configureStore({
  reducer: {
    [deploymentApi.reducerPath]: deploymentApi.reducer,
    [loanBookApi.reducerPath]: loanBookApi.reducer,
    [creditProductApi.reducerPath]: creditProductApi.reducer,
    [originatorApiDocsApi.reducerPath]: originatorApiDocsApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [alertsApi.reducerPath]: alertsApi.reducer,
    [originatorApi.reducerPath]: originatorApi.reducer,
    [userSessionApi.reducerPath]: userSessionApi.reducer,
    [downloadApi.reducerPath]: downloadApi.reducer,
    [uploadApi.reducerPath]: uploadApi.reducer,
    [dataRoomApi.reducerPath]: dataRoomApi.reducer,
    [riskApi.reducerPath]: riskApi.reducer,
    [covenantsApi.reducerPath]: covenantsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      deploymentApi.middleware,
      loanBookApi.middleware,
      creditProductApi.middleware,
      originatorApiDocsApi.middleware,
      dashboardApi.middleware,
      alertsApi.middleware,
      originatorApi.middleware,
      userSessionApi.middleware,
      downloadApi.middleware,
      uploadApi.middleware,
      dataRoomApi.middleware,
      riskApi.middleware,
      covenantsApi.middleware,
      rtkQueryErrorInterceptor
    ),
});
setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
