import { useState, useContext, useRef } from "react";
import {
  useSearchParams,
  URLSearchParamsInit,
  useNavigate,
} from "react-router-dom";
import useQueryParams from "../../shared/hooks/useQuery";
import AuthLayout from "../../shared/AuthLayout";
import DashboardCard from "../../dashboard/DashboardCard";
import DistributionOfDeployments from "../../deployment/DistributionOfDeployments";
import DeploymentTotals from "../../deployment/DeploymentTotals";
import LoansNo from "../../deployment/LoansNo";
import { IDeploymentOverTimeParams } from "../../deployment/types";
import { Box, SimpleGrid, IconButton } from "@chakra-ui/react";
import { ArrowLeft20 } from "@carbon/icons-react";
import {
  AccessTokenContext,
  OriginatorIdContext,
} from "../../context/contextProvider";
import FilterTag from "../../shared/FilterTag";
import { defaultTimeAndResolutionParams } from "../../deployment/defaults";
import DeployedVolume from "../../deployment/deployedVolume/";
import useHasAccess from "../../user/session/hooks/useHasAccess";
import PortfolioNavigation from "../PortfolioNavigation";
import CohortBalance from "../../deployment/cohortBalance";
import moment from "moment";
import DeploymentPerforming from "../../deployment/DeploymentPerforming";

type Props = {};

interface IGlobalParams
  extends IDeploymentOverTimeParams,
    Record<string, string | number | undefined> {}

type ISegment = {
  type: string;
  value: string | undefined;
};

interface IFilter {
  type: string;
  value: string | number | undefined;
}

interface IViewportParams {
  viewport_from: string | undefined;
  viewport_to: string | undefined;
}
const OriginationAndPerformance = (props: Props) => {
  const navigate = useNavigate();

  const queryParams: IGlobalParams = useQueryParams() as IGlobalParams;
  const [, setSearchParams] = useSearchParams();

  const defaultParams: IGlobalParams = {
    ...defaultTimeAndResolutionParams,
    asset_class: "all",
    originatorId: useContext(OriginatorIdContext).originatorId,
  };

  const [params, setParams] = useState({ ...defaultParams, ...queryParams });

  const viewportParams = useRef<IViewportParams>({
    viewport_from: undefined,
    viewport_to: undefined,
  });
  const prevParams = useRef<ISegment | null>(null);

  const shouldSkipDataFetching = (
    prevParams: ISegment | null,
    by: "industry" | "employees" | "turnover"
  ) => {
    let shouldSkip = false;
    if (prevParams !== null) {
      shouldSkip = prevParams.type === by;
    }
    return shouldSkip;
  };

  const {
    originatorId,
    from,
    to,
    asset_class,
    resolution,
    ...filtersToDisplay
  } = params;

  const accessToken = useContext(AccessTokenContext).accessToken;

  const performingParams = {
    accessToken: accessToken,
    originatorId: originatorId,
    asset_class: "all",
    from: moment().subtract(90, "days").format("YYYY-MM-DD") + "T00:00:00",
    to: moment().format("YYYY-MM-DD") + "T00:00:00",
    viewport_from:
      moment().subtract(5, "years").format("YYYY-MM-DD") + "T00:00:00",
    viewport_to: moment().format("YYYY-MM-DD") + "T00:00:00",
  };

  const onSegmentChange = (segment: ISegment) => {
    if (segment.value === undefined) {
      delete queryParams[segment.type];
      const updatedParams = { ...params };
      delete updatedParams[segment.type];
      setParams(updatedParams);
    } else {
      queryParams[segment.type] = segment.value;
      setParams((prevState) => ({ ...prevState, ...queryParams }));
    }
    prevParams.current = { type: segment.type, value: segment.value };
    setSearchParams(queryParams as URLSearchParamsInit);
  };

  const onViewportChange = (
    from: string | undefined,
    to: string | undefined
  ) => {
    viewportParams.current = { viewport_from: from, viewport_to: to };
  };

  const handleRemoveFilter = (filter: IFilter) => {
    delete queryParams[filter["type"]];
    const updatedParams = { ...params };
    delete updatedParams[filter["type"]];
    setParams(updatedParams);
    setSearchParams(queryParams as URLSearchParamsInit);
  };

  const hasDashboardAccess = useHasAccess("dashboard");

  return (
    <AuthLayout>
      <>
        <DashboardCard
          overflow="hidden"
          linkBack={
            hasDashboardAccess ? (
              <IconButton
                colorScheme="credo"
                _hover={{ bg: "credo.10" }}
                _active={{ bg: "credo.10" }}
                variant="outline"
                mr="12px"
                aria-label="Back"
                icon={<ArrowLeft20 />}
                w="40px"
                h="40px"
                minWidth="auto"
                onClick={() => navigate("/dashboard")}
              />
            ) : undefined
          }
          title="Origination and performance"
          cardSubtitle={<LoansNo params={params} />}
          w="full"
          filters={
            <>
              <Box
                width={{ base: "100%", md: "auto" }}
                mt={{ base: "8px", md: "0" }}
                overflow="auto"
                whiteSpace="nowrap"
              >
                {Object.keys(filtersToDisplay).map((param, idx) => (
                  <FilterTag
                    key={idx}
                    filter={{
                      type: param,
                      value: params[param],
                      abbreviate: param === "employees",
                      hideLabel: param === "industry",
                    }}
                    onRemoveFilter={handleRemoveFilter}
                  />
                ))}
              </Box>
            </>
          }
          actions={
            <PortfolioNavigation
              params={params}
              queryParams={queryParams as URLSearchParamsInit}
            />
          }
        >
          <>
            <SimpleGrid
              columns={{ base: 1, md: 3 }}
              bg={{ base: "#fff", md: "#FBFDFE" }}
              borderBottomWidth="1px"
              borderColor="gray.200"
            >
              <Box
                p="0"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <DistributionOfDeployments
                  params={params}
                  by="industry"
                  onSegmentChange={onSegmentChange}
                  shouldSkipFetch={shouldSkipDataFetching(
                    prevParams.current,
                    "industry"
                  )}
                />
              </Box>

              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                p="0"
                borderColor="gray.200"
                borderTopWidth={{ base: "1px", md: "0px" }}
                borderBottomWidth={{ base: "1px", md: "0px" }}
                borderLeftWidth={{ base: "0", md: "1px" }}
                borderRightWidth={{ base: "0", md: "1px" }}
              >
                <DistributionOfDeployments
                  params={params}
                  by="employees"
                  onSegmentChange={onSegmentChange}
                  shouldSkipFetch={shouldSkipDataFetching(
                    prevParams.current,
                    "employees"
                  )}
                />
              </Box>

              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                p="0"
              >
                <DistributionOfDeployments
                  params={params}
                  by="turnover"
                  onSegmentChange={onSegmentChange}
                  shouldSkipFetch={shouldSkipDataFetching(
                    prevParams.current,
                    "turnover"
                  )}
                />
              </Box>
            </SimpleGrid>

            <Box
              w="full"
              display="flex"
              flexWrap="wrap"
              borderColor="gray.200"
              borderBottomWidth="1px"
            >
              <Box
                w={{ base: "100%", lg: "50%" }}
                pt="4"
                borderColor="gray.200"
                borderRightWidth={{ base: "0", lg: "1px" }}
              >
                <DeployedVolume
                  params={{ ...params, ...viewportParams.current }}
                  onViewportChange={onViewportChange}
                />
              </Box>
              <Box
                w={{ base: "100%", lg: "50%" }}
                p="4"
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderColor="gray.200"
                borderTopWidth={{ base: "1px", lg: "0" }}
                position="relative"
              >
                <DeploymentPerforming params={performingParams} />
              </Box>
            </Box>
            <CohortBalance params={params} />
            <DeploymentTotals params={params} />
          </>
        </DashboardCard>
      </>
    </AuthLayout>
  );
};

export default OriginationAndPerformance;
