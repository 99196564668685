import React from "react";
import { useIntl } from "react-intl";
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import { ReportData20 } from "@carbon/icons-react";
import { ILoanBookDataPoint } from "../types";
import { FormattedMessage } from "react-intl";
import Header from "./Header";
import HeaderItem from "./HeaderItem";
import Body from "./Body";
import BodyItem from "./BodyItem";
import { CreditPerformance } from "../badges";
import { FormattedNumber } from "react-intl";
import { Money, DateFormatter, Percent } from "../../locale/formatters";
interface Props {
  loan: ILoanBookDataPoint;
  isOpen: boolean;
  onClose: any;
}

const AncillaryLoanbookEntryDrawer = ({ loan, isOpen, onClose }: Props) => {
  const intl = useIntl();

  return (
    <>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="sm">
        <DrawerOverlay />
        <DrawerContent pt="56px">
          <DrawerCloseButton zIndex="2" />
          <DrawerHeader
            fontSize="16px"
            p="16px"
            display="flex"
            alignItems="center"
            position="fixed"
            w="full"
            top="0"
            bg="white"
          >
            <ReportData20 />
            &nbsp; {intl.formatMessage({ id: "loanbook_ancillary" })}
          </DrawerHeader>
          <DrawerBody p="0">
            <Header>
              <HeaderItem borderBottom="1px solid #444" label="Loan id">
                {loan.credit_ref}
              </HeaderItem>
              <HeaderItem
                borderBottom="1px solid #444"
                label={intl.formatMessage({ id: "loanbook_borrower" })}
                alignItems="flex-end"
                fontFamily="mono"
              >
                {loan.borrower_ref}
              </HeaderItem>
              <HeaderItem
                borderBottom="1px solid #444"
                label={
                  <FormattedMessage
                    id="loanbook_observation_date"
                    values={{ br: "" }}
                  />
                }
                fontFamily="mono"
              >
                <DateFormatter value={loan.observation_date} />
              </HeaderItem>
              <HeaderItem
                borderBottom="1px solid #444"
                label={
                  <FormattedMessage
                    id="loanbook_months_elapsed"
                    values={{ br: "" }}
                  />
                }
                alignItems="flex-end"
                fontFamily="mono"
              >
                <FormattedNumber
                  value={loan.months_elapsed}
                  maximumFractionDigits={2}
                  minimumFractionDigits={2}
                />
              </HeaderItem>
              <HeaderItem
                label={intl.formatMessage({ id: "loanbook_status" })}
                borderBottom="1px solid #444"
              >
                {loan.credit_performance ? (
                  <CreditPerformance performance={loan.credit_performance} />
                ) : (
                  <div>-</div>
                )}
              </HeaderItem>
              <HeaderItem
                borderBottom="1px solid #444"
                label={intl.formatMessage({ id: "loanbook_amount_overdue" })}
                alignItems="flex-end"
                fontFamily="mono"
              >
                <Money value={loan.overdue} />
              </HeaderItem>
              <HeaderItem
                borderBottom="1px solid #444"
                label={
                  <FormattedMessage
                    id="loanbook_days_overdue"
                    values={{ sum: "Σ", br: "" }}
                  />
                }
                fontFamily="mono"
              >
                {loan.days_overdue}
              </HeaderItem>
              <HeaderItem
                borderBottom="1px solid #444"
                label={
                  <FormattedMessage
                    id="loanbook_outstanding_balance"
                    values={{ sum: "Σ", br: "" }}
                  />
                }
                alignItems="flex-end"
                fontFamily="mono"
              >
                <Money value={loan.outstanding} />
              </HeaderItem>
              <HeaderItem
                label={
                  <FormattedMessage
                    id="loanbook_principal_paid"
                    values={{ sum: "Σ", br: "" }}
                  />
                }
                fontFamily="mono"
              >
                <Money value={loan.principal_paid} />
              </HeaderItem>
              <HeaderItem
                label={
                  <FormattedMessage
                    id="loanbook_interest_paid"
                    values={{ sum: "Σ", br: "" }}
                  />
                }
                alignItems="flex-end"
                fontFamily="mono"
              >
                <Money value={loan.interest_paid} />
              </HeaderItem>
            </Header>

            <Box p="16px">
              <Body>
                <BodyItem
                  borderBottom="1px solid #E2E8F0"
                  label={intl.formatMessage({ id: "loanbook_product_type" })}
                >
                  <FormattedMessage id={`${loan.product_type}`} />
                </BodyItem>
                <BodyItem
                  borderBottom="1px solid #E2E8F0"
                  label={intl.formatMessage({ id: "loanbook_industry" })}
                >
                  <FormattedMessage id={`industry_${loan.industry}`} />
                </BodyItem>
                <BodyItem
                  borderBottom="1px solid #E2E8F0"
                  label={intl.formatMessage({ id: "loanbook_country" })}
                >
                  {loan.country}
                </BodyItem>
                <BodyItem
                  borderBottom="1px solid #E2E8F0"
                  label={intl.formatMessage({ id: "loanbook_region" })}
                >
                  {loan.region}
                </BodyItem>
                <BodyItem
                  borderBottom="1px solid #E2E8F0"
                  label={intl.formatMessage({ id: "loanbook_interest_rate" })}
                  fontFamily="mono"
                >
                  <Percent value={loan.interest_rate} />
                </BodyItem>
                <BodyItem
                  borderBottom="1px solid #E2E8F0"
                  label={intl.formatMessage({ id: "loanbook_principal" })}
                  fontFamily="mono"
                >
                  <Money value={loan.principal} />
                </BodyItem>
                <BodyItem
                  borderBottom="1px solid #E2E8F0"
                  label={intl.formatMessage({ id: "loanbook_maturity_date" })}
                  fontFamily="mono"
                >
                  <DateFormatter value={loan.maturity_date} />
                </BodyItem>
                <BodyItem
                  borderBottom="1px solid #E2E8F0"
                  label={intl.formatMessage({ id: "loanbook_credit_rating" })}
                  fontFamily="mono"
                >
                  {loan.credit_rating}
                </BodyItem>
                <BodyItem
                  borderBottom="1px solid #E2E8F0"
                  label={intl.formatMessage({ id: "loanbook_PD" })}
                  fontFamily="mono"
                >
                  {loan.pd && <Percent value={loan.pd} />}
                </BodyItem>
                <BodyItem
                  borderBottom="1px solid #E2E8F0"
                  label={intl.formatMessage({ id: "loanbook_LGD" })}
                  fontFamily="mono"
                >
                  {loan.lgd && <Money value={loan.lgd} currency="EUR" />}
                </BodyItem>
                <BodyItem
                  label={intl.formatMessage({ id: "loanbook_ECL" })}
                  fontFamily="mono"
                >
                  {loan.ecl && <Money value={loan.ecl} currency="EUR" />}
                </BodyItem>
                <BodyItem
                  label={intl.formatMessage({ id: "loanbook_actual_xirr" })}
                  fontFamily="mono"
                >
                  {loan.actual_xirr && <Percent value={loan.actual_xirr} />}
                </BodyItem>
              </Body>
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default AncillaryLoanbookEntryDrawer;
