export type ITrend = "up" | "down" | "eq";

export const getTrend = (actual: number, previous: number): ITrend => {
  if (actual === previous) return "eq";
  if (actual > previous) return "up";
  return "down";
};

export const trendFromDelta = (delta: number | undefined): ITrend => {
  if (delta === undefined) return "eq";
  if (delta === 0) return "eq";
  if (delta > 0) return "up";
  return "down";
};
