import * as am5xy from "@amcharts/amcharts5/xy";
import Bucket from "../../bucket";
import {
  getCategoryAxisData,
  getRelativeZoomPositionOfCategoryAxis,
} from "./categoryAxis";
import {
  getFirstAndLastVisibleDataPointsIndex,
  addDataToStartOfChart,
  addDataToEndOfChart,
  fetchAndSetDataToChart,
  getVisibleTimeInterval,
} from "./chartData";
import { getViewportFrom, getViewportTo } from "./viewport";
import { IDeploymentOverTimeParamsV2 } from "../types";

const handlePanStarted = (
  chart: am5xy.XYChart,
  setZoomLevel: (start: number | undefined, end: number | undefined) => void
) => {
  const { start, end } = getRelativeZoomPositionOfCategoryAxis(chart);
  setZoomLevel(start, end);
};

const handlePanEnded = (
  chart: am5xy.XYChart,
  params: IDeploymentOverTimeParamsV2,
  accessToken: string,
  previousZoomLevel: { start: number | undefined; end: number | undefined },
  setPrevZoomLevel: (
    start: number | undefined,
    end: number | undefined
  ) => void,
  onViewportChange: (from: string | undefined, to: string | undefined) => void
) => {
  const { start, end } = getRelativeZoomPositionOfCategoryAxis(chart);
  const { start: prevStart, end: prevEnd } = previousZoomLevel;

  if (
    start === undefined ||
    end === undefined ||
    prevStart === undefined ||
    prevEnd === undefined
  )
    return;

  if (end === prevEnd && start === prevStart) {
    return;
  }

  const dataSource = getCategoryAxisData(chart);
  if (dataSource.length === 0) return;

  const firstDataPoint = dataSource[0];
  const lastDataPoint = dataSource[dataSource.length - 1];

  const firstBucket = Bucket.fromDataSourceBucketString(firstDataPoint.bucket);
  const lastBucket = Bucket.fromDataSourceBucketString(lastDataPoint.bucket);
  const resolution = firstBucket.resolution;

  const { viewport_from, viewport_to } = getVisibleTimeInterval(chart);
  onViewportChange(viewport_from || undefined, viewport_to || undefined);

  if (start < 0.15) {
    addDataToStartOfChart(
      chart,
      { ...params, resolution },
      accessToken,
      firstBucket,
      dataSource[0].viewPortWidth
    );
  } else if (end > 0.85) {
    addDataToEndOfChart(
      chart,
      { ...params, resolution },
      accessToken,
      lastBucket,
      dataSource[0].viewPortWidth
    );
  }

  setPrevZoomLevel(start, end);
};

const handleZooming = (
  chart: am5xy.XYChart,
  params: IDeploymentOverTimeParamsV2,
  accessToken: string,
  previousZoomLevel: { start: number | undefined; end: number | undefined },
  setPrevZoomLevel: (
    start: number | undefined,
    end: number | undefined
  ) => void,
  onViewportChange: (from: string | undefined, to: string | undefined) => void
) => {
  const { start, end } = getRelativeZoomPositionOfCategoryAxis(chart);
  const { startIndex, endIndex } = getFirstAndLastVisibleDataPointsIndex(chart);

  if (
    start === undefined ||
    end === undefined ||
    startIndex === undefined ||
    endIndex === undefined
  )
    return;

  const { start: prevStart, end: prevEnd } = previousZoomLevel;
  if (prevStart === undefined || prevEnd === undefined) {
    setPrevZoomLevel(start, end);
    return;
  }

  const dataSource = getCategoryAxisData(chart);
  if (dataSource.length === 0) return;

  const { viewport_from, viewport_to } = getVisibleTimeInterval(chart);
  onViewportChange(viewport_from || undefined, viewport_to || undefined);

  const firstDataPoint = dataSource[0];
  const lastDataPoint = dataSource[dataSource.length - 1];
  const firstBucket = Bucket.fromDataSourceBucketString(firstDataPoint.bucket);
  const lastBucket = Bucket.fromDataSourceBucketString(lastDataPoint.bucket);

  const IS_ZOOM_OUT = end - start > prevEnd - prevStart;
  const IS_ZOOM_IN = end - start < prevEnd - prevStart;
  const MIN_VISIBLE_DATA_POINTS = 4;

  const isZoomOutOnBothSides = start < 0.15 && end > 0.85;
  const isZoomOutOnLeftSide = start < 0.15;
  const isZoomOutOnRightSide = end > 0.85;

  const visibleDataPoints = endIndex - startIndex;

  let viewportFrom;
  let viewportTo;

  if (IS_ZOOM_OUT) {
    if (isZoomOutOnBothSides) {
      viewportFrom = getViewportFrom(firstBucket, dataSource.length);
      viewportTo = getViewportTo(lastBucket, dataSource.length);
    } else if (isZoomOutOnLeftSide) {
      viewportFrom = getViewportFrom(firstBucket, dataSource.length);
      viewportTo = lastBucket.getDateStrFromBucket();
    } else if (isZoomOutOnRightSide) {
      viewportFrom = firstBucket.getDateStrFromBucket();
      viewportTo = getViewportTo(lastBucket, dataSource.length);
    }
  } else if (IS_ZOOM_IN && visibleDataPoints <= MIN_VISIBLE_DATA_POINTS) {
    const { viewport_from, viewport_to } = getVisibleTimeInterval(chart);
    viewportFrom = viewport_from;
    viewportTo = viewport_to;
  }

  if (viewportFrom && viewportTo) {
    fetchAndSetDataToChart(
      {
        ...params,
        viewport_from: viewportFrom,
        viewport_to: viewportTo,
      },
      accessToken,
      chart,
      () => setPrevZoomLevel(undefined, undefined),
      IS_ZOOM_OUT
    );
  } else {
    setPrevZoomLevel(start, end);
  }
};

const handleColumnClick = (
  bucket: Bucket,
  chart: am5xy.XYChart,
  params: IDeploymentOverTimeParamsV2,
  accessToken: string,
  onViewportChange: (from: string | undefined, to: string | undefined) => void
) => {
  if (bucket.resolution === "days") return;
  const { from, to } = bucket.getBoundaries();
  onViewportChange(from, to);
  fetchAndSetDataToChart(
    { ...params, viewport_from: from, viewport_to: to },
    accessToken,
    chart
  );
};

export { handlePanStarted, handlePanEnded, handleZooming, handleColumnClick };
