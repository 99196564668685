import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  IProbabilityOfDefaultDataSet,
  IFirstMissedPaymentDataSet,
  IRiskGroupDistributionDataSet,
  IPrepaymentsPerCohortDataSet,
  IRiskFilters,
  ICovenantsFilters,
  IRiskTotalsDataSet,
  ILatePaymentsDistributionDataSet,
  ICovenantsDataSet,
  IPerformanceTriggersOverTimeParams,
} from "./types";
import { API_BASE_URL } from "../config";

export const riskApi = createApi({
  reducerPath: "riskApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: (builder) => ({
    getAverageProbabilityOfDefault: builder.query<
      IProbabilityOfDefaultDataSet,
      IRiskFilters
    >({
      query: (params: IRiskFilters) => {
        const { accessToken, originatorId, from, to, ...queryParams } = params;
        return {
          url: `v1/originator/${originatorId}/risk/avg_pd`,
          params: queryParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getRiskGroupDistribution: builder.query<
      IRiskGroupDistributionDataSet,
      IRiskFilters
    >({
      query: (params: IRiskFilters) => {
        const { accessToken, originatorId, from, to, ...queryParams } = params;
        return {
          url: `v1/originator/${originatorId}/risk/rating_dist`,
          params: queryParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getPrepaymentsPerCohort: builder.query<
      IPrepaymentsPerCohortDataSet,
      IRiskFilters
    >({
      query: (params: IRiskFilters) => {
        const { accessToken, originatorId, from, to, ...queryParams } = params;
        return {
          url: `v1/originator/${originatorId}/risk/prep_per_age`,
          params: queryParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getLatePaymentsDistribution: builder.query<
      ILatePaymentsDistributionDataSet,
      IRiskFilters
    >({
      query: (params: IRiskFilters) => {
        const { accessToken, originatorId, from, to, ...queryParams } = params;
        return {
          url: `v1/originator/${originatorId}/risk/dpd_over_time`,
          params: queryParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getRiskTotals: builder.query<IRiskTotalsDataSet, IRiskFilters>({
      query: (params: IRiskFilters) => {
        const { accessToken, originatorId, from, to, ...queryParams } = params;
        return {
          url: `v1/originator/${originatorId}/risk/totals`,
          params: queryParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getSumFirstMissedPayment: builder.query<
      IFirstMissedPaymentDataSet,
      IRiskFilters
    >({
      query: (params: IRiskFilters) => {
        const { accessToken, originatorId, from, to, ...queryParams } = params;
        return {
          url: `v1/originator/${originatorId}/risk/first_missed_payment`,
          params: queryParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
  }),
});

export const {
  useGetAverageProbabilityOfDefaultQuery,
  useGetRiskGroupDistributionQuery,
  useGetPrepaymentsPerCohortQuery,
  useGetLatePaymentsDistributionQuery,
  useGetRiskTotalsQuery,
  useGetSumFirstMissedPaymentQuery,
} = riskApi;

export const covenantsApi = createApi({
  reducerPath: "covenantsApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: (builder) => ({
    getCovenantsLatest: builder.query<ICovenantsDataSet, ICovenantsFilters>({
      query: (params: ICovenantsFilters) => {
        const { accessToken, originatorId, from, to, ...queryParams } = params;
        return {
          url: `v1/originator/${originatorId}/covenants/latest`,
          params: queryParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getPerformanceTriggersOverTime: builder.query<ICovenantsDataSet, IPerformanceTriggersOverTimeParams>({
      query: (params: IPerformanceTriggersOverTimeParams) => {
        const { accessToken, originatorId, from, to, ...queryParams } = params;
        return {
          url: `v1/originator/${originatorId}/covenants/performance_triggers_over_time`,
          params: queryParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
  }),
});

export const {
  useGetCovenantsLatestQuery,
  useGetPerformanceTriggersOverTimeQuery,
} = covenantsApi;
