import { useContext } from "react";
import { AccessTokenContext } from "../../context/contextProvider";
import { useGetDataRoomsQuery } from "../service";
import useQueryParams from "../../shared/hooks/useQuery";
import AuthLayout from "../../shared/AuthLayout";
import DashboardCard from "../../dashboard/DashboardCard";
import DataRooms from "../DataRooms";
import Breadcrumb from "../Breadcrumb";
import Header from "../Header";
import Content from "../Content";
import DownloadButton from "../DownloadButton";
import Loader from "../../shared/Loader";
import { IDataRoomParams } from "../types";

const DataRoom = () => {
  const accessToken = useContext(AccessTokenContext).accessToken;

  const queryParams: IDataRoomParams = useQueryParams() as IDataRoomParams;
  const { data_room_id = "", state = "" } = queryParams;

  const { isFetching, data } = useGetDataRoomsQuery({
    accessToken,
  });

  const dataRooms = (data && data.data_rooms) || [];

  return (
    <AuthLayout>
      <>
        {isFetching && <Loader />}
        {!isFetching && (
          <DashboardCard
            overflow="hidden"
            w="full"
            Breadcrumb={<Breadcrumb dataRoomId={data_room_id} state={state} />}
            actions={
              data_room_id ? (
                <DownloadButton dataRoomId={data_room_id} state={state} />
              ) : undefined
            }
          >
            <>
              <Header />
              {!data_room_id && !state && <DataRooms dataRooms={dataRooms} />}
              {data_room_id && (
                <Content dataRoomId={data_room_id} state={state} />
              )}
            </>
          </DashboardCard>
        )}
      </>
    </AuthLayout>
  );
};

export default DataRoom;
