import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { useIntl } from "react-intl";
import { TimePlot20 } from "@carbon/icons-react";
import { ILoanBookDataPoint, ILoanBookParams } from "../types";
import CreditEventsTable from "./CreditEventsTable";
import LoanTable from "./LoanTable";

interface Props {
  loan: ILoanBookDataPoint;
  isOpen: boolean;
  onClose: any;
  params: ILoanBookParams;
}

const TimeSeriesModal = ({ params, loan, isOpen, onClose }: Props) => {
  const intl = useIntl();
  return (
    <>
      <Modal onClose={onClose} isOpen={isOpen} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            fontSize="16px"
            p="16px"
            display="flex"
            alignItems="center"
          >
            <TimePlot20 />
            &nbsp; {intl.formatMessage({ id: "loanbook_events" })}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody p="0" fontSize="14px" overflow="auto" w="full">
            <Box>
              <LoanTable loan={loan} />
              <CreditEventsTable params={params} creditId={loan.credit_id} />
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default TimeSeriesModal;
