export type IDataSource = {
  bucket: string;
  in: boolean;
}[];

export type IBucketInterval = {
  bucket: string;
  endBucket: string;
};

// dataSource is sorted by bucket
const getBucketsNotInDataCube = (
  dataSource: IDataSource
): IBucketInterval[] => {
  let bucketsNotInDataCube: IBucketInterval[] = [];
  let bucketInterval = {} as IBucketInterval;

  let i = 0;
  let j = 0;
  while (i < dataSource.length) {
    let dataItem = dataSource[i];

    if (!dataItem.in) {
      bucketInterval = {
        bucket: dataItem.bucket,
        endBucket: dataItem.bucket,
      };

      for (j = i + 1; j < dataSource.length; j++) {
        let dataItem = dataSource[j];
        if (!dataItem.in) {
          bucketInterval["endBucket"] = dataItem.bucket;
        } else {
          break;
        }
      }

      i = j + 1;
      bucketsNotInDataCube.push(bucketInterval);
    } else {
      i += 1;
    }
  }

  return bucketsNotInDataCube;
};

export { getBucketsNotInDataCube };
