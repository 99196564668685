import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { IApiDocsData } from "./types";
import { API_BASE_URL } from "../config";
import { IAuthParams } from "../auth/types";

export const originatorApiDocsApi = createApi({
  reducerPath: "originatorApiDocsApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: (builder) => ({
    getApiDocs: builder.query<IApiDocsData, IAuthParams>({
      query: (params: IAuthParams) => {
        return {
          url: `v1/originator_api_docs_url`,
          headers: {
            Authorization: `Bearer ${params.accessToken}`,
          },
        };
      },
    }),
  }),
});

export const { useGetApiDocsQuery } = originatorApiDocsApi;
