import moment from "moment";
import { IResolution } from "../../../../bucket/types";
import { IBaseBucket } from "../../../types";

const getBucketStr = (date: moment.Moment, resolution: IResolution): string => {
  if (resolution === "years") {
    return `Y${date.format("YYYY")}`;
  } else if (resolution === "quarters") {
    return `Q${date.format("YYYY-Q")}`;
  } else if (resolution === "months") {
    return `M${date.format("YYYY-MM")}`;
  } else if (resolution === "weeks") {
    return `W${date.format("YYYY-WW")}`;
  } else {
    return `D${date.format("YYYY-MM-DD")}`;
  }
};

const generateBucketsArray = (
  startDateStr: string,
  endDateStr: string,
  resolution: IResolution
): IBaseBucket[] => {
  let data = [];
  let currentDate = moment(startDateStr, "YYYY-MM-DD");
  let endDate = moment(endDateStr, "YYYY-MM-DD");
  while (currentDate.isSameOrBefore(endDate)) {
    data.push({
      bucket: getBucketStr(currentDate, resolution),
    });
    currentDate.add(1, resolution);
  }
  return data;
};

const removeDuplicates = (array: IBaseBucket[]) => {
  return array.filter(
    (value, index, self) =>
      index === self.findIndex((item) => item.bucket === value.bucket)
  );
};

export { generateBucketsArray, removeDuplicates };
