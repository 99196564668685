import React from "react";
import { Text, Table, Tbody, Tr, Td } from "@chakra-ui/react";
import { ILoanBookDataPoint } from "../types";
import { CreditPerformance, DaysOverdue } from "../badges";
import { Money } from "../../locale/formatters";
import DateFormatter from "../../locale/formatters/DateFormatter";
import { FormattedMessage } from "react-intl";

type Props = {
  loan: ILoanBookDataPoint;
};

const LoanTable = ({ loan }: Props) => {
  return (
    <>
      <Table
        variant="unstyled"
        size="sm"
        minWidth="1000px"
        position="relative"
        lineHeight="normal"
      >
        <Tbody>
          <Tr bg="#2B2B2B" color="#fff">
            <Td py="2" px="4" height="40px" minWidth="198px">
              <Text
                fontSize="12px"
                textTransform="uppercase"
                fontFamily="condensed"
                fontWeight="700"
                lineHeight="16px"
              >
                <FormattedMessage
                  id="loanbook_observation_date"
                  values={{ br: <br /> }}
                />
              </Text>
              <Text fontFamily="mono">
                <DateFormatter value={loan.observation_date} />
              </Text>
            </Td>

            <Td py="2" px="4" height="40px" minWidth="146px" textAlign="right">
              <Text
                fontSize="12px"
                textTransform="uppercase"
                fontFamily="condensed"
                fontWeight="700"
                lineHeight="16px"
              >
                <FormattedMessage
                  id="loanbook_months_elapsed"
                  values={{ br: <br /> }}
                />
              </Text>
              <Text fontFamily="mono">{loan.months_elapsed}</Text>
            </Td>

            <Td py="2" px="4" height="40px" minWidth="150px" textAlign="right">
              <Text
                fontSize="12px"
                textTransform="uppercase"
                fontFamily="condensed"
                fontWeight="700"
                lineHeight="16px"
              >
                <FormattedMessage
                  id="loanbook_payments_elapsed"
                  values={{ br: <br /> }}
                />
              </Text>
              <Text fontFamily="mono">{loan.payments_elapsed}</Text>
            </Td>

            <Td py="2" px="4" height="40px" minWidth="150px" textAlign="right">
              <Text
                fontSize="12px"
                textTransform="uppercase"
                fontFamily="condensed"
                fontWeight="700"
                lineHeight="16px"
              >
                <FormattedMessage id="loanbook_status" />
                <br />
                &nbsp;
              </Text>
              {loan.credit_performance ? (
                <CreditPerformance performance={loan.credit_performance} />
              ) : (
                <div>-</div>
              )}
            </Td>

            <Td py="2" px="4" height="40px" minWidth="150px" textAlign="right">
              <Text
                fontSize="12px"
                textTransform="uppercase"
                fontFamily="condensed"
                fontWeight="700"
                lineHeight="16px"
              >
                <FormattedMessage
                  id="loanbook_payments_overdue"
                  values={{ br: <br /> }}
                />
              </Text>
              <Text fontFamily="mono">
                {loan.payments_overdue}
              </Text>
            </Td>

            <Td py="2" px="4" height="40px" minWidth="150px" textAlign="right">
              <Text
                fontSize="12px"
                textTransform="uppercase"
                fontFamily="condensed"
                fontWeight="700"
                lineHeight="16px"
              >
                <FormattedMessage
                  id="loanbook_days_overdue"
                  values={{ sum: "Σ", br: <br /> }}
                />
              </Text>
              {loan.days_overdue !== undefined ? (
                <DaysOverdue daysNo={loan.days_overdue} />
              ) : (
                <div>-</div>
              )}
            </Td>

            <Td py="2" px="4" height="40px" minWidth="150px" textAlign="right">
              <Text
                fontSize="12px"
                textTransform="uppercase"
                fontFamily="condensed"
                fontWeight="700"
                lineHeight="16px"
              >
                <FormattedMessage
                  id="loanbook_principal_paid"
                  values={{ sum: "Σ", br: <br /> }}
                />
              </Text>
              <Text fontFamily="mono">
                <Money value={loan.principal_paid} />
              </Text>
            </Td>

            <Td py="2" px="4" height="40px" minWidth="200px" textAlign="right">
              <Text
                fontSize="12px"
                textTransform="uppercase"
                fontFamily="condensed"
                fontWeight="700"
                lineHeight="16px"
              >
                <FormattedMessage
                  id="loanbook_outstanding_balance"
                  values={{ sum: "Σ", br: <br /> }}
                />
              </Text>
              <Text fontFamily="mono">
                <Money value={loan.outstanding} />
              </Text>
            </Td>

            <Td py="2" px="4" height="40px" minWidth="150px" textAlign="right">
              <Text
                fontSize="12px"
                textTransform="uppercase"
                fontFamily="condensed"
                fontWeight="700"
                lineHeight="16px"
              >
                <FormattedMessage
                  id="loanbook_interest_paid"
                  values={{ sum: "Σ", br: <br /> }}
                />
              </Text>
              <Text fontFamily="mono">
                <Money value={loan.interest_paid} />
              </Text>
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </>
  );
};

export default LoanTable;
