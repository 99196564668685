import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../../config";
import { IUploadProcess, IUploadProcessInitParams, IFinalizedUploadProcess, IFinalizedUploadProcessParams } from "./types"
export const uploadApi = createApi({
    reducerPath: "uploadApi",
    refetchOnMountOrArgChange: false,
    baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
    tagTypes: ["UploadProcess", "FinalizedUploadProcess"],
    endpoints: (builder) => ({
        initUpload: builder.mutation<IUploadProcess, IUploadProcessInitParams>({
            invalidatesTags: ["UploadProcess"],
            query(post_params) {
                return {
                    url: `v1/init_upload`,
                    method: "POST",
                    body: {
                        filename: post_params.filename,
                        content_type: post_params.content_type,
                        file_size: post_params.file_size
                    },
                    headers: {
                        Authorization: `Bearer ${post_params.accessToken}`,
                    },
                };
            },
        }),
        finalizeUpload: builder.mutation<IFinalizedUploadProcess, IFinalizedUploadProcessParams>({
            invalidatesTags: ["FinalizedUploadProcess"],
            query(post_params) {
                return {
                    url: `v1/finalize_upload/${post_params.jobId}`,
                    method: "POST",
                    body: {
                        etags: post_params.etags
                    },
                    headers: {
                        Authorization: `Bearer ${post_params.accessToken}`,
                    },
                };
            },
        })
    }),

});

export const { useInitUploadMutation, useFinalizeUploadMutation } = uploadApi;
