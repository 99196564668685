import { translations as server_translations } from "./en/server_translation";
import { translations as local_translations } from "./en/local_translation";

const translations = {
  en: { ...server_translations, ...local_translations },
};

const getTranslations = (locale: string) => {
  switch (locale) {
    case "en":
      return translations.en;
    default:
      return translations.en;
  }
};

export default getTranslations;
