import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
type Props = {};

const AuthCallback = (props: Props) => {
  const navigate = useNavigate();
  const { handleRedirectCallback } = useAuth0();
  useEffect(() => {
    const getCallbackState = async () => {
      try {
        const callbackParams = await handleRedirectCallback();
        const redirectPath = callbackParams.appState.target;
        navigate(redirectPath);
      } catch (e) {
        navigate("/originators");
      }
    };
    getCallbackState();
  }, [handleRedirectCallback, navigate]);

  return <></>;
};

export default AuthCallback;
