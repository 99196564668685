import { useRef } from "react";

import {
  useCreateDownloadArchiveMutation,
  useGetArchiveStatusQuery,
} from "./service";

const useGetDownloadLink = (
  data_room_id: string,
  state: string,
  accessToken: string
) => {
  const statusRef = useRef("idle");
  let downloadUrl = "";

  const [
    createArchive,
    {
      isLoading: isGenerating,
      isSuccess: generationSuccess,
      isError: isGenerationError,
    },
  ] = useCreateDownloadArchiveMutation();

  if (isGenerating) {
    statusRef.current = "generating";
  }

  if (isGenerationError) {
    statusRef.current = "error";
  }

  if (
    generationSuccess &&
    !isGenerating &&
    ["polling", "finished", "error"].indexOf(statusRef.current) === -1
  ) {
    statusRef.current = "polling";
  }

  const { data, isFetching, error } = useGetArchiveStatusQuery(
    { data_room_id, state, accessToken },
    {
      skip: statusRef.current !== "polling",
      pollingInterval: 3000,
      refetchOnMountOrArgChange: true,
    }
  );

  if (error) {
    statusRef.current = "finished";
  }

  if (
    data?.status === "ready" &&
    !isFetching &&
    statusRef.current !== "generating"
  ) {
    statusRef.current = "finished";
    if (data?.url) {
      downloadUrl = data.url;
    }
  }

  return {
    createArchive,
    status: statusRef.current,
    downloadUrl,
    isGenerating,
  };
};

export default useGetDownloadLink;
