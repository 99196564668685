export const dataSource = [
  { bucket: "M2018-02", repaid: 8, prepaid: 3 },
  { bucket: "M2018-03", repaid: 9, prepaid: 2 },
  { bucket: "M2018-04", repaid: 10, prepaid: 1 },
  { bucket: "M2018-05", repaid: 10, prepaid: 0 },
  { bucket: "M2018-06", repaid: 9, prepaid: 1 },
  { bucket: "M2018-07", repaid: 8, prepaid: 2 },
  { bucket: "M2018-08", repaid: 7, prepaid: 3 },
  { bucket: "M2018-09", repaid: 8, prepaid: 4 },
  { bucket: "M2018-10", repaid: 9, prepaid: 3 },
  { bucket: "M2018-11", repaid: 9, prepaid: 2 },
  { bucket: "M2018-12", repaid: 10, prepaid: 2 },
  { bucket: "M2019-01", repaid: 11, prepaid: 1 },
  { bucket: "M2019-02", repaid: 12, prepaid: 0 },
  { bucket: "M2019-03", repaid: 14, prepaid: 1 },
  { bucket: "M2019-04", repaid: 13, prepaid: 2 },
  { bucket: "M2019-05", repaid: 11, prepaid: 3 },
  { bucket: "M2019-06", repaid: 10, prepaid: 2 },
  { bucket: "M2019-07", repaid: 9, prepaid: 1 },
  { bucket: "M2019-08", repaid: 8, prepaid: 1 },
  { bucket: "M2019-09", repaid: 8, prepaid: 0 },
  { bucket: "M2019-10", repaid: 7, prepaid: 1 },
  { bucket: "M2019-11", repaid: 8, prepaid: 2 },
  { bucket: "M2019-12", repaid: 9, prepaid: 3 },
  { bucket: "M2020-01", repaid: 10, prepaid: 1 },
  { bucket: "M2020-02", repaid: 11, prepaid: 2 },
  { bucket: "M2020-03", repaid: 12, prepaid: 3 },
  { bucket: "M2020-04", repaid: 12, prepaid: 2 },
  { bucket: "M2020-05", repaid: 11, prepaid: 1 },
  { bucket: "M2020-06", repaid: 10, prepaid: 1 },
  { bucket: "M2020-07", repaid: 11, prepaid: 2 },
  { bucket: "M2020-08", repaid: 12, prepaid: 1 },
  { bucket: "M2020-09", repaid: 11, prepaid: 2 },
  { bucket: "M2020-10", repaid: 10, prepaid: 3 },
  { bucket: "M2020-11", repaid: 9, prepaid: 2 },
  { bucket: "M2020-12", repaid: 8, prepaid: 1 },
];
