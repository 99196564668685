import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  IDeploymentOverTimeDataSet,
  IDeploymentOverTimeParams,
  IDeploymentEmployeesDataSet,
  IDeploymentEmployeesParams,
  IDeploymentIndustriesParams,
  IDeploymentIndustriesDataSet,
  IDeploymentTotalsParams,
  IDeploymentTotalsDataSet,
  IDeploymentTurnoverDataSet,
  IDeploymentTurnoverParams,
  IDeploymentOverTimeDataSetV2,
  IDeploymentOverTimeParamsV2,
  ICohortBalanceDataSet,
  ICohortBalanceParams,
} from "./types";
import { API_BASE_URL } from "../config";

export const deploymentApi = createApi({
  reducerPath: "deploymentApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: (builder) => ({
    getDeploymentOverTime: builder.query<
      IDeploymentOverTimeDataSet,
      IDeploymentOverTimeParams
    >({
      query: (params: IDeploymentOverTimeParams) => {
        const { originatorId, accessToken, ...queryParams } = params;
        return {
          url: `v1/originator/${originatorId}/deployment/depvt`,
          params: queryParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getDeploymentOverTimeV2: builder.query<
      IDeploymentOverTimeDataSetV2,
      IDeploymentOverTimeParamsV2
    >({
      query: (params: IDeploymentOverTimeParamsV2) => {
        const { originatorId, accessToken, from, to, ...queryParams } = params;
        return {
          url: `v2/originator/${originatorId}/deployment/depvt`,
          params: queryParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getDeploymentEmployees: builder.query<
      IDeploymentEmployeesDataSet,
      IDeploymentEmployeesParams
    >({
      query: (params: IDeploymentEmployeesParams) => {
        const {
          originatorId,
          accessToken,
          employees,
          from,
          to,
          ...queryParams
        } = params;
        return {
          url: `v1/originator/${originatorId}/deployment/employees`,
          params: queryParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getDeploymentIndustries: builder.query<
      IDeploymentIndustriesDataSet,
      IDeploymentIndustriesParams
    >({
      query: (params: IDeploymentIndustriesParams) => {
        const {
          originatorId,
          accessToken,
          industry,
          from,
          to,
          ...queryParams
        } = params;
        return {
          url: `v1/originator/${originatorId}/deployment/industries`,
          params: queryParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getDeploymentTurnover: builder.query<
      IDeploymentTurnoverDataSet,
      IDeploymentTurnoverParams
    >({
      query: (params: IDeploymentTurnoverParams) => {
        const {
          originatorId,
          accessToken,
          turnover,
          from,
          to,
          ...queryParams
        } = params;
        return {
          url: `v1/originator/${originatorId}/deployment/turnover`,
          params: queryParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getDeploymentTotals: builder.query<
      IDeploymentTotalsDataSet,
      IDeploymentTotalsParams
    >({
      query: (params: IDeploymentTotalsParams) => {
        const { originatorId, accessToken, from, to, ...queryParams } = params;
        return {
          url: `v2/originator/${originatorId}/deployment/totals`,
          params: queryParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),

    getRepaymentByAgeCohort: builder.query<
      ICohortBalanceDataSet,
      ICohortBalanceParams
    >({
      query: (params: ICohortBalanceParams) => {
        const { originatorId, accessToken, from, to, ...queryParams } = params;
        return {
          url: `v1/originator/${originatorId}/repayment/by_age`,
          params: queryParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
  }),
});

export const {
  useGetDeploymentOverTimeQuery,
  useGetDeploymentEmployeesQuery,
  useGetDeploymentIndustriesQuery,
  useGetDeploymentTurnoverQuery,
  useGetDeploymentTotalsQuery,
  useGetRepaymentByAgeCohortQuery,
} = deploymentApi;
