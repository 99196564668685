import React from "react";
import Badge from "../../shared/Badge";
import { ICreditRating } from "../types";

type Props = {
  rating: ICreditRating;
};
const CreditRating = ({ rating }: Props) => {
  const CREDIT_RATINGS: Record<ICreditRating, string> = {
    "1": "blue",
    "2": "blue",
    "3": "orange",
    "4": "red",
  };

  return <Badge color={CREDIT_RATINGS[rating]} label={`CR${rating}`} />;
};

export default CreditRating;
