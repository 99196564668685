import React from "react";
import DataStat from "../../shared/DataStat";
import { SimpleGrid } from "@chakra-ui/react";
import { IDeploymentTotalsValue } from "../types";
import {
  Money,
  Percent,
  Rating,
  DurationInMonths,
} from "../../locale/formatters";
import { useIntl } from "react-intl";
type Props = {
  totals?: IDeploymentTotalsValue;
};

const DeploymentTotalsView = ({ totals }: Props) => {
  const intl = useIntl();
  return (
    <>
      {totals && (
        <SimpleGrid
          columns={{ base: 2, lg: 4 }}
          textAlign={{ base: "left", lg: "center" }}
        >
          <DataStat
            label={intl.formatMessage({ id: "deployment_totals_outstanding" })}
            value={<Money value={totals.outstanding} />}
            p={{ base: "8px 16px", lg: 4 }}
            borderColor="gray.200"
            borderLeft="0"
            borderBottom="1px"
          />

          <DataStat
            label={intl.formatMessage({ id: "deployment_totals_interest" })}
            value={<Percent value={totals.interest} />}
            p={{ base: "8px 16px", lg: 4 }}
            borderColor="gray.200"
            borderLeft="1px"
            borderBottom="1px"
          />

          <DataStat
            label={intl.formatMessage({ id: "deployment_totals_prepayment" })}
            value={<Percent value={totals.prepayment} />}
            p={{ base: "8px 16px", lg: 4 }}
            borderColor="gray.200"
            borderLeft="1px"
            borderBottom="1px"
          />

          <DataStat
            label={intl.formatMessage({
              id: "deployment_totals_avg_credit_ranking",
            })}
            value={<Rating value={totals.credit_rating_avg} />}
            p={{ base: "8px 16px", lg: 4 }}
            borderColor="gray.200"
            borderLeft="1px"
            borderBottom="1px"
          />

          <DataStat
            label={intl.formatMessage({
              id: "deployment_totals_avg_interest_rate",
            })}
            value={<Percent value={totals.interest_rate_avg}></Percent>}
            p={{ base: "8px 16px", lg: 4 }}
            borderColor="gray.200"
            borderLeft={{ base: "1px", lg: "0" }}
            borderBottom="1px"
          />
          <DataStat
            label={intl.formatMessage({ id: "deployment_totals_avg_ticket" })}
            value={<Money value={totals.principal_avg} />}
            p={{ base: "8px 16px", lg: 4 }}
            borderColor="gray.200"
            borderLeft="1px"
            borderBottom="1px"
          />

          <DataStat
            label={intl.formatMessage({ id: "deployment_totals_avg_term" })}
            value={<DurationInMonths value={totals.term_length_avg} />}
            p={{ base: "8px 16px", lg: 4 }}
            borderColor="gray.200"
            borderLeft="1px"
            borderBottom="1px"
          />
          <DataStat
            label={intl.formatMessage({ id: "deployment_totals_wal" })}
            value={<DurationInMonths value={totals.term_length_avg * 0.6} />}
            p={{ base: "8px 16px", lg: 4 }}
            borderColor="gray.200"
            borderLeft="1px"
            borderBottom="1px"
          />
        </SimpleGrid>
      )}
    </>
  );
};

export default DeploymentTotalsView;
