import React from "react";
import { useLocation } from "react-router-dom";

function useQueryParams() {
  const { search } = useLocation();

  return React.useMemo(() => {
    return paramsArrayToDict(getIteratorContent(new URLSearchParams(search).entries()));
  }, [search]);
}

function getIteratorContent(iterator: IterableIterator<[string, string]>) {
  let hasMore = true;
  const values = [];
  while (hasMore) {
    const item = iterator.next();
    if (item.done === true) {
      hasMore = false;
    } else {
      values.push(item.value);
    }
  }
  return values;
}

function paramsArrayToDict(paramsArray: Array<[string, string]>): Record<string, string | number> {
  let paramsDict: Record<string, string | number> = {};
  paramsArray.forEach((item) => {
    paramsDict[item[0]] = item[1];
  });
  return paramsDict;
}
export default useQueryParams;
