import { useLayoutEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { IAxisLabel } from "@amcharts/amcharts5/.internal/charts/xy/axes/AxisLabel";
import ChartTheme from "../../../theme/amcharts";
import Bucket from "../../../bucket";
import { IPrepaymentsPerCohortPlottableData } from "../../types";

type Props = {
  data: IPrepaymentsPerCohortPlottableData[];
  buckets: { bucket: string }[];
};

// TODO: should highlight dataItems that are not included in data cube?

const PrepaymentsPerCohortChart = ({ data, buckets }: Props) => {
  useLayoutEffect(() => {
    let root = am5.Root.new("prepaymentsPerCohortChart");
    root.autoResize = false;
    root.setThemes([am5themes_Animated.new(root), ChartTheme.new(root)]);

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        layout: root.verticalLayout,
      })
    );

    let xRenderer = am5xy.AxisRendererX.new(root, {
      strokeOpacity: 0.5,
      strokeWidth: 1,
      minGridDistance: 12,
    });
    xRenderer.grid.template.set("visible", false);

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "month",
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    xAxis.data.setAll([
      { month: "01" },
      { month: "02" },
      { month: "03" },
      { month: "04" },
      { month: "05" },
      { month: "06" },
      { month: "07" },
      { month: "08" },
      { month: "09" },
      { month: "10" },
      { month: "11" },
      { month: "12" },
    ]);

    let yRenderer = am5xy.AxisRendererY.new(root, {
      strokeOpacity: 0.5,
      strokeWidth: 1,
      minGridDistance: 30,
      inversed: true,
    });
    yRenderer.grid.template.set("visible", false);

    let yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "bucket",
        tooltip: am5.Tooltip.new(root, {}),
        renderer: yRenderer,
      })
    );

    yAxis.data.setAll(buckets);

    yAxis.get("renderer").labels.template.setAll({
      minPosition: 0.01,
      maxPosition: 0.99,
      location: 0.5,
      multiLocation: 0.5,
    });

    yAxis
      .get("renderer")
      .labels.template.adapters.add("text", function (text, target) {
        const targetAxisLabel = target as IAxisLabel | undefined;
        const dataContext = targetAxisLabel?.dataItem?.dataContext as {
          bucket: string;
        };

        if (dataContext) {
          const bucketValue = dataContext.bucket;
          text = Bucket.fromDataSourceBucketString(bucketValue).formatBucket();
        }
        return text;
      });

    let series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        calculateAggregates: true,
        clustered: false,
        xAxis: xAxis,
        yAxis: yAxis,
        categoryXField: "month",
        categoryYField: "bucket",
        valueField: "value",
      })
    );

    series.columns.template.setAll({
      tooltipText: "{bucket}, {month}: {value}",
      strokeWidth: 0,
      strokeOpacity: 0,
      width: am5.percent(100),
      height: am5.percent(100),
    });

    series.bullets.push(function () {
      return am5.Bullet.new(root, {
        sprite: am5.Circle.new(root, {
          fill: am5.color(0x000000),
          fillOpacity: 0,
        }),
      });
    });

    series.bullets.push(function () {
      return am5.Bullet.new(root, {
        sprite: am5.Label.new(root, {
          fill: am5.color(0x000000),
          populateText: true,
          centerX: am5.p50,
          centerY: am5.p50,
          fontSize: 12,
          text: "{value}%",
        }),
      });
    });

    series.set("heatRules", [
      {
        target: series.columns.template,
        min: am5.color("#ffffff"),
        max: am5.color("#287A92"),
        dataField: "value",
        key: "fill",
      },
    ]);

    series.data.setAll(data);
    series.appear();

    return () => {
      root.dispose();
    };
  });

  return (
    <div
      id="prepaymentsPerCohortChart"
      style={{ width: "100%", height: "400px" }}
    />
  );
};

export default PrepaymentsPerCohortChart;
