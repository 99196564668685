import { IResolution } from "./types";

const DATE_FORMAT = "YYYY-MM-DD";

//TODO: when the api will not fail at times other than 00,rollback the dirt  DATE_AND_TIME_FORMAT) + "T00:00:00"
const DATE_AND_TIME_FORMAT = "YYYY-MM-DD";

const DEFAULT_RESOLUTION: IResolution = "months";

export { DATE_FORMAT, DATE_AND_TIME_FORMAT, DEFAULT_RESOLUTION };
