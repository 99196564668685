import { useIntl } from "react-intl";
import { Text, Box } from "@chakra-ui/react";
import PrepaymentsPerCohortChart from "./PrepaymentsPerCohortChart";
import { useGetPrepaymentsPerCohortQuery } from "../../services";
import { getPlottableData } from "./chartData";
import { IRiskFilters } from "../../types";
import Loader from "../../../shared/Loader";

type Props = {
  params: IRiskFilters;
};

const PrepaymentsPerCohort = ({ params }: Props) => {
  const intl = useIntl();

  const { data, isFetching } = useGetPrepaymentsPerCohortQuery(params);

  const dataSource = data?.data ? getPlottableData(data.data) : [];
  const buckets = data?.data
    ? data?.data.map((item) => ({ bucket: item.bucket }))
    : [];

  return (
    <Box>
      {isFetching && <Loader />}
      {!isFetching && (
        <>
          <Text fontSize="14px" fontWeight="600" px="4" pt="4">
            {intl.formatMessage({ id: "risk_prepayments_per_cohort" })}
          </Text>
          <PrepaymentsPerCohortChart data={dataSource} buckets={buckets} />
        </>
      )}
    </Box>
  );
};

export default PrepaymentsPerCohort;
