import Bucket from "../../../bucket";
import { IBaseBucket } from "../../types";
import { removeDuplicates } from "./utils";

// the bucket arrays are sorted
const mergeBuckets = (buckets: IBaseBucket[], newBuckets: IBaseBucket[]) => {
  let mergedBuckets: IBaseBucket[] = [];

  if (buckets.length === 0 && newBuckets.length > 0) return newBuckets;

  if (newBuckets.length === 0 && buckets.length > 0) return buckets;

  if (buckets.length === 0 && newBuckets.length === 0) return [];

  const firstBucket = Bucket.fromDataSourceBucketString(buckets[0].bucket);
  const newFirstBucket = Bucket.fromDataSourceBucketString(
    newBuckets[0].bucket
  );

  if (firstBucket.resolution !== newFirstBucket.resolution) {
    mergedBuckets = [...newBuckets];
  } else if (firstBucket.isSameOrAfter(newFirstBucket)) {
    mergedBuckets = [...newBuckets, ...buckets];
  } else {
    mergedBuckets = [...buckets, ...newBuckets];
  }
  return removeDuplicates(mergedBuckets);
};

export { mergeBuckets };
