import {
  Text,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  IconButton,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import { HiOutlineMenu } from 'react-icons/hi'
import { Sidebar } from './Sidebar'
import { useMobileMenuState } from './useMobileMenuState'

export const MobileTopBar = () => {
  const { isOpen, onClose, onOpen } = useMobileMenuState()
  return (
    <Flex
      align="center"
      justify="space-between"
      py="2"
      px="4"
      bg={mode('credo.500', 'gray.800')}
      display={{ base: 'flex', lg: 'none' }}
      color="#fff"
      position="fixed"
      top="0"
      w="full"
      zIndex="3"
    >
      <Text fontWeight="bold">Dashboard</Text>
      <IconButton
        onClick={onOpen}
        variant="unstyled"
        display="flex"
        cursor="pointer"
        aria-label="Menu"
        icon={<HiOutlineMenu fontSize="1.5rem" />}
      />
      <Drawer
        size="xs"
        placement="right"
        isOpen={isOpen}
        blockScrollOnMount={true}
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent bg={mode('white', 'gray.800')} shadow="none" position="relative" maxW="290px">
          <Sidebar width="full" height="full" bg="inherit" border="0" />
          {/* <DrawerCloseButton
              bg="blue.500"
              _hover={{ bg: 'blue.600' }}
              _active={{ bg: 'blue.700' }}
              rounded="0"
              position="absolute"
              color="white"
              right="-8"
              top="0"
            /> */}
        </DrawerContent>
      </Drawer>
    </Flex>
  )
}