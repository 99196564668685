import * as am5xy from "@amcharts/amcharts5/xy";
import Bucket from "../../bucket";
import {
  IDeploymentOverTimeParamsV2,
  IPlottableDeploymentOverTimeValue,
} from "../types";
import {
  getCategoryAxis,
  getCategoryAxisData,
  getCategoryAxisDataPoint,
  getCategoryAxisPrivateSettings,
  resetCategoryAxisZoomLevel,
  maintainCategoryAxisZoomLevel,
} from "./categoryAxis";
import { getViewportFrom, getViewportTo } from "./viewport";
import { fetchData, getPlottableData } from "./service";
import { mergeBuckets } from "./mergeBuckets/mergeBuckets";

const setDataToChart = (
  chart: am5xy.XYChart,
  data: IPlottableDeploymentOverTimeValue[]
) => {
  chart.series.each((item) => {
    item.data.setAll(data);
  });

  const categoryAxis = getCategoryAxis(chart);
  categoryAxis.data.setAll(data);
};

const getFirstAndLastVisibleDataPointsIndex = (chart: am5xy.XYChart) => {
  const privateSettings = getCategoryAxisPrivateSettings(chart);
  const { startIndex, endIndex } = privateSettings;
  return {
    startIndex,
    endIndex,
  };
};

const getFirstAndLastVisibleDataPoints = (chart: am5xy.XYChart) => {
  const { startIndex, endIndex } = getFirstAndLastVisibleDataPointsIndex(chart);
  const dataSource = getCategoryAxisData(chart);
  const updatedEndIndex =
    dataSource.length === endIndex ? endIndex - 1 : endIndex; // endIndex is not 0 based for some reason

  return {
    firstVisibleDataPoint: getCategoryAxisDataPoint(
      chart,
      startIndex as number
    ),
    lastVisibleDataPoint: getCategoryAxisDataPoint(
      chart,
      updatedEndIndex as number
    ),
  };
};

const getVisibleTimeInterval = (chart: am5xy.XYChart) => {
  const { firstVisibleDataPoint, lastVisibleDataPoint } =
    getFirstAndLastVisibleDataPoints(chart);

  return {
    viewport_from: firstVisibleDataPoint
      ? Bucket.fromDataSourceBucketString(
          firstVisibleDataPoint.bucket
        ).getDateStrFromBucket()
      : null,
    viewport_to: lastVisibleDataPoint
      ? Bucket.fromDataSourceBucketString(
          lastVisibleDataPoint.bucket
        ).getDateStrFromBucket()
      : null,
  };
};

const addDataToStartOfChart = (
  chart: am5xy.XYChart,
  params: IDeploymentOverTimeParamsV2,
  accessToken: string,
  firstBucket: Bucket,
  viewPortWidth: number
) => {
  const viewportTo = firstBucket.getDateStrFromBucket();
  const viewportFrom = getViewportFrom(firstBucket, viewPortWidth);
  fetchAndSetDataToChart(
    {
      ...params,
      viewport_from: viewportFrom,
      viewport_to: viewportTo,
    },
    accessToken,
    chart
  );
};

const addDataToEndOfChart = (
  chart: am5xy.XYChart,
  params: IDeploymentOverTimeParamsV2,
  accessToken: string,
  lastBucket: Bucket,
  viewPortWidth: number
) => {
  const viewportFrom = lastBucket.addTime(1, lastBucket.resolution);
  const viewportTo = getViewportTo(lastBucket, viewPortWidth + 1);
  fetchAndSetDataToChart(
    {
      ...params,
      viewport_from: viewportFrom,
      viewport_to: viewportTo,
    },
    accessToken,
    chart
  );
};

const preloadDataToBothEndsOfChart = (
  chart: am5xy.XYChart,
  params: IDeploymentOverTimeParamsV2,
  accessToken: string,
  dataSource: IPlottableDeploymentOverTimeValue[]
) => {
  if (dataSource.length === 0) return;
  const firstBucket = Bucket.fromDataSourceBucketString(dataSource[0].bucket);
  const lastBucket = Bucket.fromDataSourceBucketString(
    dataSource[dataSource.length - 1].bucket
  );
  const resolution = firstBucket.resolution;
  const viewPortWidth = dataSource[0].viewPortWidth;
  addDataToStartOfChart(
    chart,
    { ...params, resolution },
    accessToken,
    firstBucket,
    viewPortWidth
  );
  setTimeout(function () {
    addDataToEndOfChart(
      chart,
      { ...params, resolution },
      accessToken,
      lastBucket,
      viewPortWidth
    );
  }, 1000);
};

const fetchAndSetDataToChart = (
  params: IDeploymentOverTimeParamsV2,
  accessToken: string,
  chart: am5xy.XYChart,
  onSetDataFinish?: () => void,
  isZoomOut: Boolean = false
) => {
  fetchData(params, accessToken).then((response) => {
    const dataSource = getCategoryAxisData(chart);
    const newResolution = response?.data?.viewport.resolution;
    const resolution = dataSource[0]
      ? Bucket.fromDataSourceBucketString(dataSource[0].bucket).resolution
      : undefined;

    const data = response?.data?.data || [];
    const plottableData = getPlottableData(data);
    if (resolution !== newResolution || isZoomOut) {
      setDataToChart(chart, plottableData);
      resetCategoryAxisZoomLevel(chart);
      // preloadDataToBothEndsOfChart(chart, params, accessToken, plottableData);
    } else {
      const mergedData = mergeBuckets(dataSource, plottableData);
      if (mergedData.length === 0) return;
      setDataAndCategoryAxisZoom(
        chart,
        mergedData as IPlottableDeploymentOverTimeValue[]
      );
    }

    if (onSetDataFinish) onSetDataFinish();
  });
};

const initialFetchAndSetDataToChart = (
  params: IDeploymentOverTimeParamsV2,
  accessToken: string,
  chart: am5xy.XYChart,
  onSetDataFinish?: () => void
) => {
  fetchData(params, accessToken).then((response) => {
    const data = response?.data?.data || [];
    const plottableData = getPlottableData(data);
    setDataToChart(chart, plottableData);
    // updateCategoryAxisZoomLevel(chart, 0.25, 0.75);
    preloadDataToBothEndsOfChart(chart, params, accessToken, plottableData);

    if (onSetDataFinish) {
      onSetDataFinish();
    }
  });
};

const setDataAndCategoryAxisZoom = (
  chart: am5xy.XYChart,
  newData: IPlottableDeploymentOverTimeValue[]
) => {
  const { firstVisibleDataPoint, lastVisibleDataPoint } =
    getFirstAndLastVisibleDataPoints(chart);
  if (firstVisibleDataPoint === undefined || lastVisibleDataPoint === undefined)
    return;

  setDataToChart(chart, newData);
  maintainCategoryAxisZoomLevel(
    chart,
    firstVisibleDataPoint.bucket,
    lastVisibleDataPoint.bucket
  );
};

export {
  setDataToChart,
  getFirstAndLastVisibleDataPointsIndex,
  getFirstAndLastVisibleDataPoints,
  getVisibleTimeInterval,
  preloadDataToBothEndsOfChart,
  initialFetchAndSetDataToChart,
  fetchAndSetDataToChart,
  addDataToStartOfChart,
  addDataToEndOfChart,
};
