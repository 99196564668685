import { ICohortBalanceValue, IPlottableCohortBalanceDataItem } from "../types";

const getPlottableData = (data: ICohortBalanceValue[]) => {
  const plottableData: IPlottableCohortBalanceDataItem[] = [];
  data.forEach((dataPoint) => {
    let newDataPoint = {
      bucket: dataPoint["bucket"],
      in: dataPoint["in"],
      principal_remaining: dataPoint.series.principal_remaining[0].value * 100,
      principal_settled: dataPoint.series.principal_settled[0].value * 100,
      interest_remaining: dataPoint.series.interest_remaining[0].value * 100,
      interest_settled: dataPoint.series.interest_settled[0].value * 100,
    };
    plottableData.push(newDataPoint);
  });

  return plottableData;
};

export { getPlottableData };
