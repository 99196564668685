import { useTable, Column } from "react-table";
import {
  Table as ChakraTable,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
} from "@chakra-ui/react";

export type Props<Data extends object> = {
  data: Data[];
  columns: Column<Data>[];
  onRowClick?: (rowData: Data) => void;
  actionColumnId?: string;
  cellStyles?: Record<string, Record<string, string>>;
  headStyles?: Record<string, Record<string, string>>;
  isSmall?: boolean;
};

function Table<Data extends object>({
  data,
  columns,
  onRowClick,
  actionColumnId,
  cellStyles = {},
  headStyles = {},
}: Props<Data>) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });
  return (
    <ChakraTable
      {...getTableProps()}
      variant="unstyled"
      size="sm"
      minWidth="1000px"
      position="relative"
    >
      <Thead bg="#EDF2F7" color="#4A5568" position="sticky" top="0" zIndex="2">
        {headerGroups.map((headerGroup) => (
          <Tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => {
              const headStyle = headStyles[column.id]
                ? headStyles[column.id]
                : {};
              return (
                <Th
                  position="sticky"
                  top="0"
                  zIndex="2"
                  py="2"
                  px="4"
                  borderRight="1px solid #E2E8F0"
                  borderBottom="1px solid #E2E8F0"
                  _last={{ borderRight: "none" }}
                  fontSize="12px"
                  lineHeight="normal"
                  fontWeight="bold"
                  textTransform="uppercase"
                  letterSpacing="1px"
                  bg="#EDF2F7"
                  // cursor="pointer"
                  fontFamily="condensed"
                  verticalAlign="top"
                  {...headStyle}
                  {...column.getHeaderProps()}
                >
                  {column.render("Header")}
                </Th>
              );
            })}
          </Tr>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <Tr
              cursor="pointer"
              transition="all 120ms ease-in-out"
              _hover={{ bg: "#F2F8FB !important" }}
              bg="#fff"
              _even={{
                bg: "#F8FAFC",
              }}
              {...row.getRowProps()}
              onClick={(e) => {
                if (onRowClick) {
                  onRowClick(row.original);
                }
              }}
            >
              {row.cells.map((cell) => {
                const cellStyle = cellStyles[cell.column.id]
                  ? cellStyles[cell.column.id]
                  : {};
                return (
                  <Td
                    py="2"
                    px="4"
                    borderRight="1px solid #E2E8F0"
                    borderBottom="1px solid #E2E8F0"
                    height="40px"
                    {...cellStyle}
                    _last={{ borderRight: "none" }}
                    {...cell.getCellProps()}
                    onClick={(e) => {
                      if (cell.column.id === actionColumnId) {
                        e.stopPropagation();
                      }
                    }}
                  >
                    <Text noOfLines={1}>{cell.render("Cell")}</Text>
                  </Td>
                );
              })}
            </Tr>
          );
        })}
      </Tbody>
    </ChakraTable>
  );
}

export default Table;
