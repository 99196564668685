import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { IDashboardDataSet, IDashboardParams } from "./types";
import { API_BASE_URL } from "../config";

// Define a service using a base URL and expected endpoints
export const dashboardApi = createApi({
  reducerPath: "dashboardApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: (builder) => ({
    getDashboard: builder.query<IDashboardDataSet, IDashboardParams>({
      query: (params: IDashboardParams) => {
        return {
          url: `v1/originator/${params.originator_id}/dashboard`,
          headers: {
            Authorization: `Bearer ${params.accessToken}`,
          },
        };
      },
    }),
  }),
});

export const { useGetDashboardQuery } = dashboardApi;
