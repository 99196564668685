import React, { useContext, useEffect } from "react";
// import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
// import { useGetOriginatorsQuery } from "../originator/services";
import ModuleAccessManager from "../user/session/ModuleAccessManager";
import {
  AccessTokenContext,
  OriginatorIdContext,
} from "../context/contextProvider";

type Props = {
  pageId: string;
  children: JSX.Element;
};

const ProtectedComponent = ({ pageId, children }: Props) => {
  const { loginWithRedirect } = useAuth0();
  const { accessToken } = useContext(AccessTokenContext);
  const { originatorId } = useContext(OriginatorIdContext);

  useEffect(() => {
    if (!accessToken) {
      const redirectTarget = `${window.location.pathname}${window.location.search}`;
      loginWithRedirect({ appState: { target: redirectTarget } });
    }
  }, [accessToken, loginWithRedirect]);

  if (!accessToken || !originatorId) {
    return null; // Or render a loading spinner
  }

  return (
    <>
      <ModuleAccessManager pageId={pageId}>{children}</ModuleAccessManager>
    </>
  );
};

export default ProtectedComponent;
