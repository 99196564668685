import React from "react";
import { Grid } from "@chakra-ui/react";

type Props = { children: React.ReactNode };

const Body = ({ children }: Props) => {
  return (
    <Grid
      templateColumns="repeat(2, 1fr)"
      gap={0}
      bg="#FBFDFE"
      color="#2B2B2B"
      fontSize="14px"
      lineHeight="20px"
      borderRadius="8"
      overflow="hidden"
      border="1px solid #E2E8F0"
      shadow="lg"
    >
      {children}
    </Grid>
  );
};

export default Body;
