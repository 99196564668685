import React from "react";
import { useIntl } from "react-intl";
import { formatBytes } from "./file-size";
import { IconButton, Box, Progress, Text } from "@chakra-ui/react";
import { TrashCan20 } from "@carbon/icons-react";
import { IFile } from "./types";
import useUploadFile from "./useUploadFile";
import ErrorIndicator from "./Indicators/ErrorIndicator";
import UploadingIndicator from "./Indicators/UploadingIndicator";
import SuccessIndicator from "./Indicators/SuccessIndicator";
type Props = {
  uFile: IFile;
  onCancelPressed: (fileHash: string) => void;
};

const FileUploader = ({ uFile, onCancelPressed }: Props) => {
  const intl = useIntl();

  const { progress, cancelUpload, error } = useUploadFile(uFile);
  const cancelBtnHandler = () => {
    cancelUpload();
    onCancelPressed(uFile.hash);
  };

  return (
    <>
      <Box display="flex" textAlign="left" lineHeight="normal" pt="16px">
        {progress < 100 && !error && <UploadingIndicator />}
        {progress === 100 && !error && <SuccessIndicator />}
        {error && <ErrorIndicator />}
        <Box
          flexWrap="wrap"
          flex="1"
          display="flex"
          flexDirection="column"
          w="full"
          maxW="100%"
          borderBottom="1px solid #E9EAEC"
          pb="16px"
        >
          <Box w="full" display="flex" justifyContent="space-between" pb="8px">
            <Box pr="8px">
              <Text fontWeight="600" wordBreak="break-all">
                {uFile.file.name}
              </Text>
              <Text color="#6F6F6F" mt="4px">
                {formatBytes(uFile.file.size)} ({progress.toFixed(0)}%)
              </Text>
            </Box>
            <IconButton
              w="32px"
              h="32px"
              minWidth="32px"
              maxW="32px"
              onClick={cancelBtnHandler}
              aria-label="Cancel upload"
              icon={<TrashCan20 />}
            />
          </Box>
          {!error && (
            <Box w="full" mt="auto" alignSelf="flex-end">
              <Progress
                w="full"
                h="8px"
                colorScheme="credo"
                hasStripe
                value={progress}
                isAnimated
              />
            </Box>
          )}
          {error && (
            <Box w="full" mt="auto" alignSelf="flex-end">
              <Text
                fontWeight="600"
                fontSize="12px"
                color="#DA1E28"
                height="8px"
              >
                {intl.formatMessage({ id: "data_sourcing_uploader_error" })}
              </Text>
            </Box>
          )}
        </Box>
      </Box>

      {/* ERROR BOX */}
      {/* <Box
                display="flex"
                textAlign="left"
                lineHeight="normal"
                pt="16px"
            >

                <Box
                    flexWrap="wrap"
                    flex="1"
                    display="flex"
                    flexDirection="column"
                    w="full"
                    maxW="100%"
                    borderBottom="1px solid #E9EAEC"
                    pb="16px"
                >
                    <Box w="full" display="flex" justifyContent="space-between" pb="8px">
                        <Box pr="8px">
                            <Text fontWeight="600" wordBreak="break-all">{uFile.file.name}</Text>
                            <Text color="#6F6F6F" mt="4px">{formatBytes(uFile.file.size)} ({progress}%)</Text>
                        </Box>
                        <IconButton w="32px" h="32px" minWidth="32px" maxW="32px" onClick={() => { console.log("cancel upload") }} aria-label='Cancel upload' icon={<TrashCan20 />} />
                    </Box>
                    <Box w="full" mt="auto" alignSelf="flex-end">
                        <Text fontWeight="600" fontSize="12px" color="#DA1E28" height="8px">There was an issue with your upload. Please try again.</Text>
                    </Box>
                </Box>
            </Box>

            {/* UPLOAD FINISHED BOX */}
      {/* <Box
                display="flex"
                textAlign="left"
                lineHeight="normal"
                pt="16px"
            >
                <Box
                    bg="#F2FBFD"
                    mr="16px"
                    borderRadius="8px"
                    border="1px solid #6CA3B2"
                    w="64px"
                    minW="64px"
                    h="64px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    color="credo.500">
                    <CheckmarkOutline32 />
                </Box>
                <Box
                    flexWrap="wrap"
                    flex="1"
                    display="flex"
                    flexDirection="column"
                    w="full"
                    maxW="100%"
                    borderBottom="1px solid #E9EAEC"
                    pb="16px"
                >
                    <Box w="full" display="flex" justifyContent="space-between" pb="8px">
                        <Box pr="8px">
                            <Text fontWeight="600" wordBreak="break-all">{uFile.file.name}</Text>
                            <Text color="#6F6F6F" mt="4px">{formatBytes(uFile.file.size)} ({progress}%)</Text>
                        </Box>
                        <IconButton w="32px" h="32px" minWidth="32px" maxW="32px" onClick={() => { console.log("cancel upload") }} aria-label='Cancel upload' icon={<TrashCan20 />} />
                    </Box>
                    <Box w="full" mt="auto" alignSelf="flex-end">
                        <Progress w="full" h="8px" colorScheme="credo" hasStripe value={100} isAnimated />
                    </Box>
                </Box>
            </Box>  */}
    </>
  );
};

export default FileUploader;
