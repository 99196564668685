import React from 'react'
import {
    CheckmarkOutline32
} from "@carbon/icons-react"
import { Box } from '@chakra-ui/react'
type Props = {}

const SuccessIndicator = (props: Props) => {
    return (
        <Box
            bg="#F2FBFD"
            mr="16px"
            borderRadius="8px"
            border="1px solid #6CA3B2"
            w="64px"
            minW="64px"
            h="64px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            color="credo.500">
            <CheckmarkOutline32 />
        </Box>
    )
}

export default SuccessIndicator