import { useState, useContext, useRef, useEffect } from "react";
import { useInitUploadMutation, useFinalizeUploadMutation } from "./service";
import { IFile, IUploadEtag } from "./types";
import { AccessTokenContext } from "../../context/contextProvider";
import axios, { CancelTokenSource } from "axios";
axios.CancelToken.source();
const useUploadFile = (uFile: IFile) => {
  const [initEnded, setInitEnded] = useState(false);
  const [aggregatedProgress, setAggregatedProgress] = useState(0);
  const [error, setError] = useState("");
  const uploadOffset = useRef(0);
  const uploadCanceledRef = useRef(false);
  const accessToken = useContext(AccessTokenContext).accessToken;

  const uploadedUrlsRef = useRef<string[]>([]);
  const progressByUrlRef = useRef<{ [key: string]: number }>({});
  const uploadETagsRef = useRef<IUploadEtag[]>([]);
  const hasFinalizedUpload = useRef(false);
  const axiosCancelSourcesRef = useRef<CancelTokenSource[]>([]);
  const [initUpload, { data: uploadProcess, isSuccess, isError: isErrorOnInit }] = useInitUploadMutation();
  const [finalizeUpload, { isError: isErrorOnFinalize }] = useFinalizeUploadMutation();

  const getNextChunk = (file: File, chunkSize: number) => {
    if (file.size <= chunkSize) {
      return file;
    }
    const chunk = file.slice(uploadOffset.current, uploadOffset.current + chunkSize);
    uploadOffset.current += chunkSize;
    return chunk;
  };

  const aggregateProgress = (chunksNo: number): void => {
    let sum = 0;
    uploadedUrlsRef.current.forEach((url) => {
      if (progressByUrlRef.current[url]) {
        sum += progressByUrlRef.current[url];
      }
    });
    setAggregatedProgress(sum / chunksNo);
  };

  const isUploadFinished = (): boolean => {
    return aggregatedProgress === 100;
  };

  if (isUploadFinished() && !hasFinalizedUpload.current) {
    hasFinalizedUpload.current = true;
    finalizeUpload({ etags: uploadETagsRef.current, jobId: uploadProcess?.job_id, accessToken: accessToken });
  }
  if (!error && (isErrorOnFinalize || isErrorOnInit)) {
    setError("Error while uploading file");
  }

  async function pushToS3(
    presignedUrl: string,
    fileChunk: any,
    chunkSize: number,
    contentType: string,
    partNumber: number,
    chunksNo = 1
  ) {
    if (uploadedUrlsRef.current.indexOf(presignedUrl) !== -1) {
      return;
    }
    uploadedUrlsRef.current.push(presignedUrl);
    const cancelSource = axios.CancelToken.source();
    axiosCancelSourcesRef.current.push(cancelSource);
    axios
      .put(presignedUrl, fileChunk, {
        cancelToken: cancelSource.token,
        headers: {
          "content-type": contentType,
          "content-length": chunkSize,
        },
        onUploadProgress: (e) => {
          //  Show progress
          let percentCompleted = Math.round((e.loaded * 100) / e.total);
          if (percentCompleted < 100) {
            progressByUrlRef.current[presignedUrl] = percentCompleted;
            aggregateProgress(chunksNo);
          }
        },
      })
      .then(
        (successResp) => {
          uploadETagsRef.current.push({
            etag: successResp.headers["etag"].replaceAll('"', ""),
            part_number: partNumber,
          });
          progressByUrlRef.current[presignedUrl] = 100;
          aggregateProgress(chunksNo);
        },
        (errorResp) => {
          setError("Error while uploading file");
        }
      );
  }

  useEffect(() => {
    initUpload({
      filename: uFile.file.name,
      content_type: uFile.file.type,
      file_size: uFile.file.size,
      accessToken: accessToken,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isSuccess && !initEnded && uploadProcess) {
    setInitEnded(true);
    const partsNo = uploadProcess?.parts.length ? uploadProcess.parts.length : 0;
    for (let i = 0; i < partsNo; i++) {
      if (uploadCanceledRef.current) {
        break;
      }
      const part_specification = uploadProcess.parts[i];
      const chunk = getNextChunk(uFile.file, part_specification.size);
      pushToS3(
        part_specification.url,
        chunk,
        part_specification.size,
        uFile.file.type,
        part_specification.number,
        partsNo
      );
    }
  }

  const cancelUpload = (): void => {
    axiosCancelSourcesRef.current.forEach((source) => {
      source.cancel();
    });
  };

  return { progress: aggregatedProgress, cancelUpload, error };
};

export default useUploadFile;
