import React from "react";
import { Grid } from "@chakra-ui/react";

type Props = { children: React.ReactNode };

const Header = ({ children }: Props) => {
  return (
    <Grid
      templateColumns="repeat(2, 1fr)"
      gap={0}
      p="16px"
      bg="#2B2B2B"
      color="#fff"
      fontSize="14px"
      lineHeight="20px"
    >
      {children}{" "}
    </Grid>
  );
};

export default Header;
