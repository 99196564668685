import {
  ILatePaymentsDistributionValue,
  ILatePaymentsDistributionSeries,
  ILatePaymentsDistributionPlottableData,
} from "../../types";

export const getPlottableData = (data: ILatePaymentsDistributionValue[]): ILatePaymentsDistributionPlottableData[] => {
  let dataSource: ILatePaymentsDistributionPlottableData[] = [];
  if (!data) return [];
  data.forEach((dataItem) => {
    let dataSourceItem = {
      bucket: dataItem.bucket,
      in: dataItem.in,
    } as ILatePaymentsDistributionPlottableData;
    dataItem.series.status.forEach((series: ILatePaymentsDistributionSeries) => {
      dataSourceItem = {
        ...dataSourceItem,
        [series.id]: series.value * 100,
      };
      return dataSourceItem;
    });
    dataSource.push(dataSourceItem);
  });

  return dataSource;
};
