import React from "react";
import DataStat from "../../shared/DataStat";
import { SimpleGrid } from "@chakra-ui/react";
import { Percent } from "../../locale/formatters";
import { FormattedNumber, useIntl } from "react-intl";
import { ICovenantsLatestValue } from "../types";

type Props = {
  totals?: ICovenantsLatestValue;
};

const CovenantsLatestView = ({ totals }: Props) => {
  const intl = useIntl();
  return (
    <>
      {totals && (
        <SimpleGrid
          columns={{ base: 2, lg: 5 }}
          width="100%"
          borderColor="gray.200"
          borderLeftWidth={{ base: "0", xl: "0" }}
          textAlign={{ base: "center", lg: "center", xl: "center" }}
        >
          <DataStat
            label={intl.formatMessage({ id: "covenants_phase" })}
            value={<FormattedNumber value={totals.phase} />}
            p={{ base: "8px 16px", lg: 4 }}
            borderColor="gray.200"
            borderBottom="1px"
            borderLeft={{ base: "1px", lg: "1px", xl: "1px" }}
          />
          <DataStat
            label={intl.formatMessage({ id: "covenants_advance_rate" })}
            value={<Percent value={totals.advance_rate} />}
            p={{ base: "8px 16px", lg: 4 }}
            borderColor="gray.200"
            borderBottom="1px"
            borderLeft={{ base: "1px", lg: "1px", xl: "1px" }}
          />
          <DataStat
            label={intl.formatMessage({ id: "covenants_interest_rate" })}
            value={<Percent value={totals.interest_rate} />}
            p={{ base: "8px 16px", lg: 4 }}
            borderColor="gray.200"
            borderBottom="1px"
            borderLeft={{ base: "1px", lg: "1px", xl: "1px" }}
          />
          <DataStat
            label={intl.formatMessage({ id: "covenants_portfolio_apr" })}
            value={<Percent value={totals.portfolio_apr} />}
            p={{ base: "8px 16px", lg: 4 }}
            borderColor="gray.200"
            borderBottom="1px"
            borderLeft={{ base: "1px", lg: "1px", xl: "1px" }}
          />
          <DataStat
            label={intl.formatMessage({
              id: "covenants_recovery_rate",
            })}
            value={<Percent value={totals.recovery_rate} />}
            p={{ base: "8px 16px", lg: 4 }}
            borderColor="gray.200"
            borderLeft={{ base: "1px", lg: "1px", xl: "1px" }}
            borderBottom={{ base: "1px", lg: "1px", xl: "1px" }}
          />
          <DataStat
            label={intl.formatMessage({ id: "covenants_delinquency_rate" })}
            value={<Percent value={totals.delinquency_rate} />}
            p={{ base: "8px 16px", lg: 4 }}
            borderColor="gray.200"
            borderBottom="1px"
            borderLeft={{ base: "1px", lg: "1px", xl: "1px" }}
          />
          <DataStat
            label={intl.formatMessage({ id: "covenants_max_delinquency_rate" })}
            value={<Percent value={totals.max_delinquency_rate} />}
            p={{ base: "8px 16px", lg: 4 }}
            borderColor="gray.200"
            borderBottom="1px"
            borderLeft={{ base: "1px", lg: "1px", xl: "1px" }}
          />
          <DataStat
            label={intl.formatMessage({ id: "covenants_default_rate" })}
            value={<Percent value={totals.default_rate} />}
            p={{ base: "8px 16px", lg: 4 }}
            borderColor="gray.200"
            borderBottom="1px"
            borderLeft={{ base: "1px", lg: "1px", xl: "1px" }}
          />
          <DataStat
            label={intl.formatMessage({ id: "covenants_max_default_rate" })}
            value={<Percent value={totals.max_default_rate} />}
            p={{ base: "8px 16px", lg: 4 }}
            borderColor="gray.200"
            borderBottom="1px"
            borderLeft={{ base: "1px", lg: "1px", xl: "1px" }}
          />
          <DataStat
            label={intl.formatMessage({ id: "covenants_portfolio_margin" })}
            value={<Percent value={totals.portfolio_margin} />}
            p={{ base: "8px 16px", lg: 4 }}
            borderColor="gray.200"
            borderBottom="1px"
            borderLeft={{ base: "1px", lg: "1px", xl: "1px" }}
          />
        </SimpleGrid>
      )}
    </>
  );
};

export default CovenantsLatestView;
