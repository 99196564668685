import { Box, Text } from "@chakra-ui/react";
import { useIntl } from "react-intl";
import PrepaymentRatesChart from "./PrepaymentRatesChart";
import { dataSource } from "../../../stubs/prepaymentRates";
import useHasFeatureFlag from "../../../user/session/hooks/useHasFeatureFlag";
import ChartPlaceholder from "../ChartPlaceholder";

const PrepaymentRates = () => {
  const intl = useIntl();
  const isDemoMode = useHasFeatureFlag("demo_mode");

  return (
    <Box>
      {isDemoMode ? (
        <PrepaymentRatesChart
          title={intl.formatMessage({ id: "risk_prepayment_rates" })}
          data={dataSource}
        />
      ) : (
        <>
          <Text fontSize="14px" fontWeight="600" px="4" pt="4" flexShrink={0}>
            {intl.formatMessage({ id: "risk_prepayment_rates" })}
          </Text>
          <ChartPlaceholder type="areaChart" />
        </>
      )}
    </Box>
  );
};

export default PrepaymentRates;
