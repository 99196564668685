import { useContext } from "react";
import { useGetSessionQuery } from "../service";
import { AccessTokenContext } from "../../../context/contextProvider";

const useGetSessionPages = () => {
  const { data } = useGetSessionQuery({
    accessToken: useContext(AccessTokenContext).accessToken,
  });
  // const pages =
  //   data && data.modules ? data.modules.filter((module) => module.enabled === true).map((module) => module.id) : [];

  const pages =
    data && data.modules ? data.modules.map((module) => module.id) : [];
  //should return pages

  pages.push("contact");
  pages.push("callback");
  pages.push("originators");

  // console.log(pages)
  return pages;
};

export default useGetSessionPages;
