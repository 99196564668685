import React from 'react'
import { Box } from "@chakra-ui/react"
import {
    Document32,
} from "@carbon/icons-react"

type Props = {}

function UploadingIndicator(props: Props) {
    return (
        <Box
            bg="#fff"
            mr="16px"
            borderRadius="8px"
            border="1px solid #E9EAEC"
            w="64px"
            minW="64px"
            h="64px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            color="inherit">
            <Document32 />
        </Box>
    )
}

export default UploadingIndicator