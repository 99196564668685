import { useContext } from "react";
import { useIntl } from "react-intl";
import { Box } from "@chakra-ui/react";
import {
  useGetDeploymentEmployeesQuery,
  useGetDeploymentIndustriesQuery,
  useGetDeploymentTurnoverQuery,
} from "../deployment/service";
import {
  IDeploymentEmployeesParams,
  IDeploymentIndustriesParams,
  IDeploymentTurnoverParams,
  IDeploymentIndustriesValue,
  IDeploymentEmployeesValue,
  IDeploymentTurnoverValue,
} from "../deployment/types";
import { AccessTokenContext } from "../context/contextProvider";
import DistributionOfDeploymentChart from "./charts/DistributionOfDeploymentChart";
import Loader from "../shared/Loader";

type ISegment = {
  type: string;
  value: string | undefined;
};

type Props = {
  by: "industry" | "employees" | "turnover";
  params:
    | IDeploymentEmployeesParams
    | IDeploymentIndustriesParams
    | IDeploymentTurnoverParams;
  onSegmentChange: (segment: ISegment) => void;
  shouldSkipFetch: boolean;
};

function DistributionOfDeployments({
  by,
  params,
  onSegmentChange,
  shouldSkipFetch,
}: Props) {
  const intl = useIntl();

  let useQueryHook = useGetDeploymentIndustriesQuery;
  let dataset:
    | IDeploymentIndustriesValue[]
    | IDeploymentEmployeesValue[]
    | IDeploymentTurnoverValue[] = [];
  switch (by) {
    case "industry":
      useQueryHook = useGetDeploymentIndustriesQuery;
      break;
    case "employees":
      useQueryHook = useGetDeploymentEmployeesQuery;
      break;
    case "turnover":
      useQueryHook = useGetDeploymentTurnoverQuery;
      break;
  }
  const { data, isFetching, error } = useQueryHook(
    {
      ...params,
      accessToken: useContext(AccessTokenContext).accessToken,
    },
    { skip: shouldSkipFetch }
  );

  if (data) {
    dataset = data.data.map((item) => {
      return {
        ...item,
        by: by,
        translated_bucket: intl.formatMessage({ id: `${by}_${item.bucket}` }),
      };
    });
  }

  const label = intl.formatMessage({ id: `deployment_${by}_label` });

  const handleOnSliceClick = (sliceValue: string | undefined) => {
    onSegmentChange({ type: by, value: sliceValue });
  };

  return (
    <>
      {isFetching && <Loader minHeight="300px" />}
      {error && <Box>Something went wrong</Box>}
      {!isFetching && !error && dataset.length > 0 && (
        <DistributionOfDeploymentChart
          data={dataset}
          by={by}
          label={label}
          onSliceClick={handleOnSliceClick}
          industry={params.industry}
          employees={params.employees}
          turnover={params.turnover}
        />
      )}
    </>
  );
}

export default DistributionOfDeployments;
