import { useIntl } from "react-intl";
import { Text, Box } from "@chakra-ui/react";
import ProbabilityOfDefaultChart from "./ProbabilityOfDefaultChart";
import { useGetAverageProbabilityOfDefaultQuery } from "../../services";
import { IRiskFilters } from "../../types";
import { getBucketsNotInDataCube } from "../../utils";
import { getPlottableData } from "./chartData";
import Loader from "../../../shared/Loader";

type Props = {
  params: IRiskFilters;
};

const ProbabilityOfDefault = ({ params }: Props) => {
  const intl = useIntl();

  const { data, isFetching } = useGetAverageProbabilityOfDefaultQuery(params);

  const dataSource = data?.data ? getPlottableData(data.data) : [];
  const bucketsNotInDataCube = getBucketsNotInDataCube(dataSource);

  return (
    <Box>
      {isFetching && <Loader />}
      {!isFetching && (
        <>
          <Text fontSize="14px" fontWeight="600" px="4" pt="4">
            {intl.formatMessage({ id: "risk_probability_of_default" })}
          </Text>
          <ProbabilityOfDefaultChart
            data={dataSource}
            bucketsNotInDataCube={bucketsNotInDataCube}
          />
        </>
      )}
    </Box>
  );
};

export default ProbabilityOfDefault;
