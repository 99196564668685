import React from "react";
import { Box, Divider, useColorModeValue, Text } from "@chakra-ui/react";

interface Props {
  title?: string;
  cardSubtitle?: string | JSX.Element;
  Breadcrumb?: string | JSX.Element;
  children: JSX.Element;
  w?: any;
  pr?: any;
  pl?: any;
  overflow?: any;
  filters?: JSX.Element;
  linkBack?: JSX.Element;
  actions?: JSX.Element;
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
}

const DashboardCard = ({
  title,
  children,
  w,
  pl,
  pr,
  cardSubtitle,
  Breadcrumb,
  filters,
  linkBack,
  actions,
  overflow,
  onClick,
}: Props) => {
  return (
    <Box w={w} pr={pr} pl={pl}>
      <Box
        rounded={{ lg: "lg" }}
        shadow="base"
        bg={useColorModeValue("white", "gray.700")}
        w="full"
        h="auto"
        mb={{ base: "16px", lg: "24px" }}
        overflow={overflow}
        onClick={onClick}
        style={onClick ? { cursor: "pointer" } : {}}
      >
        <Box
          px="16px"
          py="3"
          minHeight="56px"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap="wrap"
        >
          <Box display="flex" alignItems="center">
            {linkBack}
            <Box mh="40px">
              <Text fontWeight="bold">{title}</Text>
              <Text fontSize="12px" lineHeight="normal">
                {cardSubtitle}
              </Text>
            </Box>
            <Box display="flex" alignItems="center">
              {Breadcrumb}
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            flexWrap="wrap"
            width={{ base: "100%", md: "auto" }}
          >
            {filters}
            {actions}
          </Box>
        </Box>
        <Divider />
        {children}
      </Box>
    </Box>
  );
};

export default DashboardCard;
