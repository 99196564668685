import { useLayoutEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { IAxisLabel } from "@amcharts/amcharts5/.internal/charts/xy/axes/AxisLabel";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import ChartTheme from "../../../theme/amcharts";
import Bucket from "../../../bucket";
import { IProbabilityOfDefaultPlottableData } from "../../types";

type Props = {
  bucketsNotInDataCube: { bucket: string; endBucket: string }[];
  data: IProbabilityOfDefaultPlottableData[];
};

const ProbabilityOfDefaultChart = ({ data, bucketsNotInDataCube }: Props) => {
  useLayoutEffect(() => {
    let root = am5.Root.new("probabilityOfDefaultChart");

    root.setThemes([am5themes_Animated.new(root), ChartTheme.new(root)]);

    let chart = root.container.children.push(am5xy.XYChart.new(root, {}));

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        numberFormat: "#'%'",
        min: 0,
        renderer: am5xy.AxisRendererY.new(root, {
          strokeOpacity: 0.5,
          strokeWidth: 1,
        }),
      })
    );

    let xRenderer = am5xy.AxisRendererX.new(root, {
      strokeOpacity: 0.5,
      strokeWidth: 1,
    });
    xRenderer.grid.template.set("visible", false);

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "bucket",
        tooltip: am5.Tooltip.new(root, {}),
        startLocation: 0.5,
        endLocation: 0.5,
        renderer: xRenderer,
      })
    );

    xAxis.get("renderer").labels.template.setAll({
      minPosition: 0.01,
      maxPosition: 0.99,
      location: 0.5,
      multiLocation: 0.5,
    });

    xAxis
      .get("renderer")
      .labels.template.adapters.add("text", function (text, target) {
        const targetAxisLabel = target as IAxisLabel | undefined;
        const dataContext = targetAxisLabel?.dataItem?.dataContext as {
          bucket: string;
        };
        if (dataContext) {
          const bucketValue = dataContext.bucket;
          return Bucket.fromDataSourceBucketString(bucketValue).formatBucket();
        }
        return text;
      });

    xAxis.data.setAll(data);

    const createSeries = (name: string, color: string) => {
      let series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          stacked: true,
          valueYField: name,
          categoryXField: "bucket",
          fill: am5.color(color),
          stroke: am5.color(color),
          tooltip: am5.Tooltip.new(root, {
            labelText: "{name}: {valueY}",
          }),
        })
      );

      series.strokes.template.setAll({
        strokeWidth: 3,
      });

      series.fills.template.setAll({
        templateField: "fillOpacitySetting",
        visible: true,
        fillOpacity: 0.3,
      });

      series.data.setAll(data);
      return series;
    };

    const createRange = (
      series: am5xy.LineSeries,
      bucketInterval: { bucket: string; endBucket: string }
    ) => {
      let rangeDataItem = xAxis.makeDataItem({
        category: bucketInterval.bucket,
        endCategory: bucketInterval.endBucket,
      });
      let range = series.createAxisRange(rangeDataItem);

      if (range.fills) {
        range.fills.template.setAll({
          fillOpacity: 0.1,
          visible: true,
        });
      }
    };

    const filteredSeries = createSeries("filtered", "#287A92");

    filteredSeries.events.on("datavalidated", function () {
      yAxis.setAll({
        max: yAxis.getPrivate("max") || 1,
        start: 0,
        end: 1,
      });
    });

    bucketsNotInDataCube.forEach((bucketInterval) => {
      createRange(filteredSeries, bucketInterval);
    });

    let cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "none",
      })
    );
    cursor.lineY.set("visible", false);

    return () => {
      root.dispose();
    };
  });

  return (
    <div
      id="probabilityOfDefaultChart"
      style={{ width: "100%", height: "400px" }}
    ></div>
  );
};

export default ProbabilityOfDefaultChart;
