import React from "react";
import { useIntl } from "react-intl";
import { Text, Box } from "@chakra-ui/react";
import RecoveriesByCohortChart from "./RecoveriesByCohortChart";
import useHasFeatureFlag from "../../../user/session/hooks/useHasFeatureFlag";
import ChartPlaceholder from "../ChartPlaceholder";

const RecoveriesByCohort = () => {
  const intl = useIntl();
  const isDemoMode = useHasFeatureFlag("demo_mode");

  return (
    <Box>
      <Text fontSize="14px" fontWeight="600" px="4" pt="4">
        {intl.formatMessage({ id: "risk_recoveries_by_cohort" })}
      </Text>
      {isDemoMode ? (
        <RecoveriesByCohortChart />
      ) : (
        <ChartPlaceholder type="lineChart" />
      )}
    </Box>
  );
};

export default RecoveriesByCohort;
