import React, { useLayoutEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { IAxisLabel } from "@amcharts/amcharts5/.internal/charts/xy/axes/AxisLabel";
import { Box, Flex, Text } from "@chakra-ui/react";
import ChartTheme from "../../../theme/amcharts";
import Bucket from "../../../bucket";
import { IRiskGroupDistributionPlottableData } from "../../types";

type Props = {
  data: IRiskGroupDistributionPlottableData[];
  title: string;
  bucketsNotInDataCube: { bucket: string; endBucket: string }[];
};

const RiskGroupDistributionChart = ({
  data,
  title,
  bucketsNotInDataCube,
}: Props) => {
  useLayoutEffect(() => {
    let root = am5.Root.new("riskGroupDistributionChart");
    let legendRoot = am5.Root.new("riskGroupDistributionChartLegend");

    root.setThemes([am5themes_Animated.new(root), ChartTheme.new(root)]);
    legendRoot.setThemes([am5themes_Animated.new(root), ChartTheme.new(root)]);

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        layout: root.verticalLayout,
      })
    );

    let xRenderer = am5xy.AxisRendererX.new(root, {
      strokeOpacity: 0.5,
      strokeWidth: 1,
    });
    xRenderer.grid.template.set("visible", false);

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "bucket",
        tooltip: am5.Tooltip.new(root, {}),
        maxDeviation: 0.8,
        startLocation: 0.5,
        endLocation: 0.5,
        renderer: xRenderer,
      })
    );

    xAxis.get("renderer").labels.template.setAll({
      minPosition: 0.01,
      maxPosition: 0.99,
      location: 0.5,
      multiLocation: 0.5,
    });

    xAxis
      .get("renderer")
      .labels.template.adapters.add("text", function (text, target) {
        const targetAxisLabel = target as IAxisLabel | undefined;
        const dataContext = targetAxisLabel?.dataItem?.dataContext as {
          bucket: string;
        };
        if (dataContext) {
          const bucketValue = dataContext.bucket;
          return Bucket.fromDataSourceBucketString(bucketValue).formatBucket();
        }
        return text;
      });

    xAxis.data.setAll(data);

    let yRenderer = am5xy.AxisRendererY.new(root, {
      strokeOpacity: 0.5,
      strokeWidth: 1,
    });

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        numberFormat: "#'%'",
        strictMinMax: true,
        calculateTotals: true,
        renderer: yRenderer,
      })
    );

    let legend = legendRoot.container.children.push(
      am5.Legend.new(legendRoot, {
        paddingTop: 15,
        useDefaultMarker: true,
        x: am5.percent(0),
        centerX: am5.percent(-100),
      })
    );

    legend.labels.template.setAll({
      fontSize: 12,
      fontWeight: "300",
      textAlign: "right",
    });

    legend.valueLabels.template.setAll({
      forceHidden: true,
    });

    function makeSeries(name: string, color: string) {
      let series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: name,
          stacked: true,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: name,
          valueYShow: "valueYTotalPercent",
          categoryXField: "bucket",
          fill: am5.color(color),
          stroke: am5.color(color),
          tooltip: am5.Tooltip.new(root, {
            labelText: "{name}: {valueY}",
          }),
        })
      );

      series.fills.template.setAll({
        fillOpacity: 0.7,
        visible: true,
      });

      series.data.setAll(data);
      legend.data.setAll(series.dataItems);

      series.appear();
      return series;
    }

    const createRange = (
      series: am5xy.LineSeries,
      bucketInterval: { bucket: string; endBucket: string }
    ) => {
      let rangeDataItem = xAxis.makeDataItem({
        category: bucketInterval.bucket,
        endCategory: bucketInterval.endBucket,
      });
      let range = series.createAxisRange(rangeDataItem);

      if (range.fills) {
        range.fills.template.setAll({
          fillOpacity: 0.3,
          visible: true,
        });
      }
    };

    let cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "none",
      })
    );
    cursor.lineY.set("visible", false);

    const aSeries = makeSeries("A", "#c6f6d5");
    const bSeries = makeSeries("B", "#bce9f0");
    const cSeries = makeSeries("C", "#fddbbb");
    const dSeries = makeSeries("D", "#ffc591");
    const eSeries = makeSeries("E", "#ffd6d7");

    bucketsNotInDataCube.forEach((bucketInterval) => {
      createRange(aSeries, bucketInterval);
      createRange(bSeries, bucketInterval);
      createRange(cSeries, bucketInterval);
      createRange(dSeries, bucketInterval);
      createRange(eSeries, bucketInterval);
    });

    legend.data.setAll(chart.series.values);

    return () => {
      root.dispose();
      legendRoot.dispose();
    };
  });

  return (
    <Box>
      <Flex>
        <Text fontSize="14px" fontWeight="600" px="4" pt="4" flexShrink={0}>
          {title}
        </Text>
        <Box flexGrow={1} id={"riskGroupDistributionChartLegend"} />
      </Flex>
      <Box
        id="riskGroupDistributionChart"
        style={{ width: "100%", height: "400px" }}
      />
    </Box>
  );
};

export default RiskGroupDistributionChart;
