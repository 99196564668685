import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../config";
import { IAlertParams, IAlertDataSet } from "./types";

export const alertsApi = createApi({
  reducerPath: "alertsApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: (builder) => ({
    getAlerts: builder.query<IAlertDataSet, IAlertParams>({
      query: (params: IAlertParams) => {
        return {
          url: `v1/originator/${params.originator_id}/alerts`,
          headers: {
            Authorization: `Bearer ${params.accessToken}`,
          },
        };
      },
    }),
  }),
});

export const { useGetAlertsQuery } = alertsApi;
