import {
  IProbabilityOfDefaultPlottableData,
  IProbabilityOfDefaultValue,
} from "../../types";

export const getPlottableData = (
  data: IProbabilityOfDefaultValue[]
): IProbabilityOfDefaultPlottableData[] => {
  let dataSource: IProbabilityOfDefaultPlottableData[] = [];
  if (!data) return [];
  data.forEach((item) => {
    let dataSourceItem = {
      bucket: item.bucket,
      in: item.in,
    } as IProbabilityOfDefaultPlottableData;

    item.series.pd.forEach((series) => {
      dataSourceItem = {
        ...dataSourceItem,
        [series.id]: series.value ? series.value * 100 : 0,
      };
    });
    dataSource.push(dataSourceItem);
  });

  return dataSource;
};
