import React from 'react'
import { FormattedNumber } from 'react-intl';

type Props = {
    value?: number
}

const Rating = ({ value = 0 }: Props) => {
    return (
        <> <FormattedNumber value={value} maximumFractionDigits={2} /> / 5</>
    )
}

export default Rating