import React from "react";
import { Badge as ChakraBadge } from "@chakra-ui/react";

type Props = {
  color: string;
  label: string;
};
const Badge = ({ color, label }: Props) => {
  return (
    <ChakraBadge fontWeight="600" colorScheme={color}>
      {label}
    </ChakraBadge>
  );
};

export default Badge;
