import React, { useLayoutEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import ChartTheme from "../../../theme/amcharts";
import { generateDataForYear } from "../../../stubs/recoveriesByCohort";

const RecoveriesByCohortChart = () => {
  useLayoutEffect(() => {
    let root = am5.Root.new("recoveriesByCohort");

    root.setThemes([am5themes_Animated.new(root), ChartTheme.new(root)]);

    let chart = root.container.children.push(am5xy.XYChart.new(root, {}));

    let yRenderer = am5xy.AxisRendererY.new(root, {
      strokeOpacity: 0.5,
      strokeWidth: 1,
    });

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        numberFormat: "#'%'",
        maxPrecision: 0,
        min: 0,
        max: 100,
        renderer: yRenderer,
      })
    );

    let xRenderer = am5xy.AxisRendererX.new(root, {
      strokeOpacity: 0.5,
      strokeWidth: 1,
      minGridDistance: 12,
    });
    xRenderer.grid.template.set("visible", false);

    let xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        maxPrecision: 0,
        min: 1,
        max: 12,
        renderer: xRenderer,
      })
    );

    let legend = chart.children.push(
      am5.Legend.new(root, {
        useDefaultMarker: true,
        centerY: am5.percent(-50),
        x: am5.percent(90),
        layout: am5.GridLayout.new(root, {
          maxColumns: 1,
          fixedWidthGrid: true,
        }),
      })
    );

    function makeSeries(year: number, color: string) {
      const dataSourceForYear = generateDataForYear(year);
      let series = chart.series.push(
        am5xy.LineSeries.new(root, {
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "rate",
          valueXField: "age",
          name: `${year}`,
          stroke: am5.color(color),
          fill: am5.color(color),
          tooltip: am5.Tooltip.new(root, {
            labelText: "{year}: {valueY}",
          }),
        })
      );

      series.strokes.template.setAll({
        strokeWidth: 3,
      });

      let cursor = chart.set(
        "cursor",
        am5xy.XYCursor.new(root, {
          behavior: "none",
        })
      );
      cursor.lineY.set("visible", false);

      series.data.setAll(dataSourceForYear);

      series.appear();

      legend.data.push(series);
    }

    makeSeries(2015, "#DC267F");
    makeSeries(2015, "#DC267F");
    makeSeries(2016, "#5A3DC8");
    makeSeries(2017, "#F9AF6D");
    makeSeries(2018, "#8AAD09");
    makeSeries(2019, "#23768F");
    makeSeries(2020, "#F66105");

    return () => {
      root.dispose();
    };
  }, []);
  return (
    <div
      id="recoveriesByCohort"
      style={{ width: "100%", height: "400px" }}
    ></div>
  );
};

export default RecoveriesByCohortChart;
