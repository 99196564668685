import { Avatar, Flex, Text, useColorModeValue as mode } from '@chakra-ui/react'
import {
  FiChevronDown
} from 'react-icons/fi'
import * as React from 'react'

interface UserProfileProps {
  name: string
  image?: string
  email: string
  initials?: string
}

export const UserProfile = (props: UserProfileProps) => {
  const { name, image, email } = props
  return (
    <Flex flexShrink={0} borderTopWidth="1px" p="4" justifyContent="space-between" alignItems="center">
      <Flex alignItems="center">
        <Avatar size="sm" name={name} src={image} mr="3" bg="gray.500" color="#fff" />
        <Flex direction="column">
          <Text fontWeight="medium">{name}</Text>
          <Text fontSize="14px" lineHeight="shorter" color={mode('gray.600', 'gray.400')}>
            {email}
          </Text>
        </Flex>
      </Flex>
      <FiChevronDown fontSize="20px" />
    </Flex>
  )
}