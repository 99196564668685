import { useLayoutEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { Box, Flex, Text } from "@chakra-ui/react";
import ChartTheme from "../../../theme/amcharts";

type Props = {
  title: string;
  dataSets: { date: number; value: number }[][];
};

const DefaultRateChart = ({ title, dataSets }: Props) => {
  useLayoutEffect(() => {
    let root = am5.Root.new("defaultRateChart");

    root.setThemes([am5themes_Animated.new(root), ChartTheme.new(root)]);

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        wheelY: "zoomX",
        layout: root.verticalLayout,
      })
    );

    let yRenderer = am5xy.AxisRendererY.new(root, {
      strokeOpacity: 0.2,
      strokeWidth: 0.5,
    });
    // yRenderer.grid.template.set("visible", false);

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        numberFormat: "#'%'",
        renderer: yRenderer,
      })
    );

    let xRenderer = am5xy.AxisRendererX.new(root, {
      strokeOpacity: 0.2,
      strokeWidth: 0.5,
    });
    // xRenderer.grid.template.set("visible", false);

    let xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: { timeUnit: "day", count: 1 },
        renderer: xRenderer,
      })
    );

    let series2 = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: `Max Default Rate`,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        valueXField: "date",
        tooltip: am5.Tooltip.new(root, {
          labelText:
            "[bold]{name}[/]\n{valueX.formatDate()}: {valueY.formatNumber('#,###.##')}%",
        }),
        // stroke: am5.color(0xff0000),
      })
    );

    series2.strokes.template.set("strokeWidth", 3);
    series2.data.setAll(dataSets[1]);

    let series1 = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: `Default Rate`,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        valueXField: "date",
        tooltip: am5.Tooltip.new(root, {
          labelText:
            "[bold]{name}[/]\n{valueX.formatDate()}: {valueY.formatNumber('#,###.##')}%",
        }),
      })
    );

    series1.strokes.template.set("strokeWidth", 1);
    series1.data.setAll(dataSets[0]);

    chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "zoomXY",
        xAxis: xAxis,
      })
    );

    xAxis.set(
      "tooltip",
      am5.Tooltip.new(root, {
        themeTags: ["axis"],
      })
    );

    // let scrollbarX = am5xy.XYChartScrollbar.new(root, {
    //   orientation: "horizontal",
    //   height: 100,
    // });

    // chart.set("scrollbarX", scrollbarX);

    // let sbxAxis = scrollbarX.chart.xAxes.push(
    //   am5xy.DateAxis.new(root, {
    //     groupData: true,
    //     groupIntervals: [{ timeUnit: "week", count: 1 }],
    //     baseInterval: { timeUnit: "day", count: 1 },
    //     renderer: am5xy.AxisRendererX.new(root, {
    //       opposite: false,
    //       strokeOpacity: 0,
    //     }),
    //   })
    // );

    // let sbyAxis = scrollbarX.chart.yAxes.push(
    //   am5xy.ValueAxis.new(root, {
    //     renderer: am5xy.AxisRendererY.new(root, {}),
    //   })
    // );

    // let sbseries1 = scrollbarX.chart.series.push(
    //   am5xy.LineSeries.new(root, {
    //     xAxis: sbxAxis,
    //     yAxis: sbyAxis,
    //     valueYField: "value",
    //     valueXField: "date",
    //   })
    // );

    // sbseries1.data.setAll(dataSets[0]);

    // let sbseries2 = scrollbarX.chart.series.push(
    //   am5xy.LineSeries.new(root, {
    //     xAxis: sbxAxis,
    //     yAxis: sbyAxis,
    //     valueYField: "value",
    //     valueXField: "date",
    //   })
    // );

    // sbseries2.data.setAll(dataSets[1]);

    return () => {
      root.dispose();
    };
  });

  return (
    <Box>
      <Flex>
        <Text fontSize="14px" fontWeight="600" px="4" pt="4" flexShrink={0}>
          {title}
        </Text>
      </Flex>
      <Box id="defaultRateChart" style={{ width: "100%", height: "400px" }} />
    </Box>
  );
};

export default DefaultRateChart;
