import { Box } from "@chakra-ui/react";
import { useIntl } from "react-intl";
import { useGetPerformanceTriggersOverTimeQuery } from "../../services";
import DefaultRateChart from "./DefaultRateChart";
import DelinquencyRateChart from "./DelinquencyRateChart";
import PortfolioMarginChart from "./PortfolioMarginChart";
import PortfolioAPRChart from "./PortfolioAPRChart";
import { IPerformanceTriggersOverTimeParams } from "../../types";
import Loader from "../../../shared/Loader";

type Props = {
  params: IPerformanceTriggersOverTimeParams;
};

interface DataSet {
  date: number;
  value: number;
}

const PerformanceTriggers = ({ params }: Props) => {
  const intl = useIntl();

  const { data, isFetching } = useGetPerformanceTriggersOverTimeQuery(params);

  const dataSource = data?.data ? data?.data : [];

  const defaultRateData: DataSet[] = [];
  const maxDefaultRateData: DataSet[] = [];
  const delinquencyRateData: DataSet[] = [];
  const maxDelinquencyRateData: DataSet[] = [];
  const portfolioMarginData: DataSet[] = [];
  const portfolioAPRData: DataSet[] = [];

  for (const data of dataSource) {
    const view_date = new Date(data.totals.view_date).getTime();
    defaultRateData.push({
      date: view_date,
      value: data.totals.default_rate * 100,
    });
    maxDefaultRateData.push({
      date: view_date,
      value: data.totals.max_default_rate * 100,
    });
    delinquencyRateData.push({
      date: view_date,
      value: data.totals.delinquency_rate * 100,
    });
    maxDelinquencyRateData.push({
      date: view_date,
      value: data.totals.max_delinquency_rate * 100,
    });
    portfolioMarginData.push({
      date: view_date,
      value: data.totals.portfolio_margin * 100,
    });
    portfolioAPRData.push({
      date: view_date,
      value: data.totals.portfolio_apr * 100,
    });
  }

  const defaultDataSets = [defaultRateData, maxDefaultRateData];
  const delinquencyDataSets = [delinquencyRateData, maxDelinquencyRateData];

  return (
    <>
      <Box
        w={{ base: "100%", lg: "50%" }}
        py="4"
        borderColor="gray.200"
        borderRightWidth={{ base: "0", lg: "1px" }}
      >
        {isFetching && <Loader />}
        {!isFetching && (
          <DefaultRateChart
            title={intl.formatMessage({
              id: "performance_triggers_default_rate",
            })}
            dataSets={defaultDataSets}
          />
        )}
      </Box>
      <Box
        w={{ base: "100%", lg: "50%" }}
        py="4"
        display="flex"
        alignItems="center"
        justifyContent="center"
        borderColor="gray.200"
        borderTopWidth={{ base: "1px", lg: "0" }}
        position="relative"
      >
        <Box w="full" borderColor="gray.200">
          {isFetching && <Loader />}
          {!isFetching && (
            <DelinquencyRateChart
              title={intl.formatMessage({
                id: "performance_triggers_delinquency_rate",
              })}
              dataSets={delinquencyDataSets}
            />
          )}
        </Box>
      </Box>
      <Box
        w={{ base: "100%", lg: "50%" }}
        py="4"
        borderColor="gray.200"
        borderRightWidth={{ base: "0", lg: "1px" }}
      >
        {isFetching && <Loader />}
        {!isFetching && (
          <PortfolioMarginChart
            title={intl.formatMessage({
              id: "performance_triggers_portfolio_margin",
            })}
            dataSet={portfolioMarginData}
          />
        )}
      </Box>
      <Box
        w={{ base: "100%", lg: "50%" }}
        py="4"
        display="flex"
        alignItems="center"
        justifyContent="center"
        borderColor="gray.200"
        borderTopWidth={{ base: "1px", lg: "0" }}
        position="relative"
      >
        <Box w="full" borderColor="gray.200">
          {isFetching && <Loader />}
          {!isFetching && (
            <PortfolioAPRChart
              title={intl.formatMessage({
                id: "performance_triggers_portfolio_apr",
              })}
              dataSet={portfolioAPRData}
            />
          )}
        </Box>
      </Box>
    </>
  );
};

export default PerformanceTriggers;
