import { useContext } from "react";
import { useGetSessionQuery } from "../service";
import { AccessTokenContext } from "../../../context/contextProvider";

const useGetFeatureFlags = () => {
  const { data } = useGetSessionQuery({
    accessToken: useContext(AccessTokenContext).accessToken,
  });
  const featureFlags = data && data.feature_flags ? data.feature_flags : [];

  return featureFlags;
};

export default useGetFeatureFlags;
