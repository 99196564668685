import { IResolution, IResolutionPrefix } from "./types";
import { DEFAULT_RESOLUTION } from "./defaults";

class Resolution {
  static years: IResolution = "years";
  static quarters: IResolution = "quarters";
  static months: IResolution = "months";
  static weeks: IResolution = "weeks";
  static days: IResolution = "days";

  static prefixToResolutionMapping = new Map<IResolutionPrefix, IResolution>([
    ["Y", this.years],
    ["Q", this.quarters],
    ["M", this.months],
    ["W", this.weeks],
    ["D", this.days],
  ]);

  static ResolutionOrder: IResolution[] = [
    this.years,
    this.quarters,
    this.months,
    this.weeks,
    this.days,
  ];

  static getZoomedInResolution(baseResolution: IResolution): IResolution {
    let zoomedInResolution;
    switch (baseResolution) {
      case this.years:
        zoomedInResolution = this.months;
        break;
      case this.quarters:
        zoomedInResolution = this.months;
        break;
      case this.months:
        zoomedInResolution = this.days;
        break;
      case this.weeks:
        zoomedInResolution = this.days;
        break;
      case this.days:
        zoomedInResolution = this.days;
        break;
      default:
        zoomedInResolution = baseResolution;
        break;
    }
    return zoomedInResolution;

    // const resolutionIdx = this.ResolutionOrder.indexOf(baseResolution);
    // if (resolutionIdx + 1 === this.ResolutionOrder.length) {
    //   return this.ResolutionOrder[resolutionIdx];
    // } else {
    //   return this.ResolutionOrder[resolutionIdx + 1];
    // }
  }

  static getZoomedOutResolution(baseResolution: IResolution): IResolution {
    let zoomedOutResolution;
    switch (baseResolution) {
      case this.days:
        zoomedOutResolution = this.months;
        break;
      case this.weeks:
        zoomedOutResolution = this.months;
        break;
      case this.months:
        zoomedOutResolution = this.years;
        break;
      case this.quarters:
        zoomedOutResolution = this.years;
        break;
      case this.years:
        zoomedOutResolution = this.years;
        break;
      default:
        zoomedOutResolution = baseResolution;
        break;
    }
    return zoomedOutResolution;
    // const resolutionIdx = this.ResolutionOrder.indexOf(baseResolution);
    // if (resolutionIdx === 0) {
    //   return this.ResolutionOrder[resolutionIdx];
    // } else {
    //   return this.ResolutionOrder[resolutionIdx - 1];
    // }
  }

  static fromPrefix(prefix: IResolutionPrefix) {
    return this.prefixToResolutionMapping.get(prefix) || DEFAULT_RESOLUTION;
  }
}

export default Resolution;
