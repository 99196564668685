import React, { useState, useContext, useRef, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Column } from "react-table";
import InfiniteScroll from "react-infinite-scroll-component";
import { AccessTokenContext } from "../../context/contextProvider";
import Table from "../../shared/Table";
import { useAppDispatch } from "../../hooks";
import { useGetLoanBookCreditEventsQuery, loanBookApi } from "../service";
import { CreditPerformance, DaysOverdue } from "../badges";
import { Money } from "../../locale/formatters";
import DateFormatter from "../../locale/formatters/DateFormatter";
import { ICreditEventsDataPoint, ILoanBookParams } from "../types";
import { cellStyles, headStyles } from "./tableStyle";
import Loader from "../../shared/Loader";

type Props = {
  params: ILoanBookParams;
  creditId: string;
};

const CreditEventsTable = ({ params, creditId }: Props) => {
  const accessToken = useContext(AccessTokenContext).accessToken;
  const dispatch = useAppDispatch();
  const intl = useIntl();

  const [tableData, setTableData] = useState<ICreditEventsDataPoint[]>([]);

  const cursorRef = useRef("");
  const isInitialFetch = useRef(true);

  const { data, isLoading } = useGetLoanBookCreditEventsQuery({
    ...params,
    accessToken,
    creditId: creditId,
  });

  useEffect(() => {
    if (data && data.data) {
      setTableData(data.data);
    }
  }, [data]);

  if (isInitialFetch.current) {
    cursorRef.current = data?.data_set?.cursor ? data.data_set.cursor : "";
  }

  const loadMoreData = () => {
    if (!cursorRef.current) {
      return;
    }
    isInitialFetch.current = false;
    dispatch(
      loanBookApi.endpoints.getLoanBookCreditEvents.initiate({
        ...params,
        accessToken,
        creditId: creditId,
        cursor: cursorRef.current,
      })
    ).then((response) => {
      cursorRef.current = response.data?.data_set.cursor
        ? response.data?.data_set.cursor
        : "";
      const paginatedData = response.data?.data ? response.data?.data : [];
      setTableData(tableData.concat(paginatedData));
    });
  };

  const columns: Column<ICreditEventsDataPoint>[] = React.useMemo(
    () => [
      {
        Header: (
          <FormattedMessage
            id="loanbook_observation_date"
            values={{ br: "" }}
          />
        ),
        accessor: "observed_at",
        Cell: ({ row, value }) =>
          value !== undefined ? <DateFormatter value={value} /> : <div>-</div>,
      },
      {
        Header: (
          <FormattedMessage id="loanbook_months_elapsed" values={{ br: "" }} />
        ),
        accessor: "months_elapsed",
      },
      {
        Header: (
          <FormattedMessage
            id="loanbook_payments_elapsed"
            values={{ br: "" }}
          />
        ),
        accessor: "payments_elapsed",
      },
      {
        Header: intl.formatMessage({ id: "loanbook_status" }),
        accessor: "credit_performance",
        Cell: ({ row, value }) =>
          value !== undefined ? (
            <CreditPerformance performance={value} />
          ) : (
            <div>-</div>
          ),
      },
      {
        Header: (
          <FormattedMessage
            id="loanbook_payments_overdue"
            values={{ br: "" }}
          />
        ),
        accessor: "payments_overdue",
        // Cell: ({ row, value }) =>
        //   value !== undefined ? <Money value={value} /> : <div>-</div>,
      },
      {
        Header: (
          <FormattedMessage
            id="loanbook_days_overdue"
            values={{ sum: "Σ", br: "" }}
          />
        ),
        accessor: "days_overdue",
        Cell: ({ row, value }) =>
          value !== undefined ? <DaysOverdue daysNo={value} /> : <div>-</div>,
      },
      {
        Header: (
          <FormattedMessage
            id="loanbook_principal_paid"
            values={{ sum: "Σ", br: "" }}
          />
        ),
        accessor: "principal_paid",
        Cell: ({ row, value }) =>
          value !== undefined ? <Money value={value} /> : <div>-</div>,
      },
      {
        Header: (
          <FormattedMessage
            id="loanbook_outstanding_balance"
            values={{ sum: "Σ", br: "" }}
          />
        ),
        accessor: "outstanding",
        Cell: ({ row, value }) =>
          value !== undefined ? <Money value={value} /> : <div>-</div>,
      },
      {
        Header: (
          <FormattedMessage
            id="loanbook_interest_paid"
            values={{ sum: "Σ", br: "" }}
          />
        ),
        accessor: "interest_paid",
        Cell: ({ row, value }) =>
          value !== undefined ? <Money value={value} /> : <div>-</div>,
      },
    ],
    [intl]
  );

  if (isLoading) {
    return <Loader />;
  }

  return (
    <InfiniteScroll
      dataLength={tableData.length}
      next={loadMoreData}
      hasMore={true}
      loader={<></>}
    >
      <Table
        columns={columns}
        data={tableData}
        cellStyles={cellStyles}
        headStyles={headStyles}
      />
    </InfiniteScroll>
  );
};

export default CreditEventsTable;
