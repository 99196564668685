import { useContext } from "react";
import { Flex } from "@chakra-ui/react";
import { MobileTopBar } from "./MobileTopBar";
import { Sidebar } from "./Sidebar";
import { OriginatorIdContext } from "../context/contextProvider";

type Props = {
  children: JSX.Element | string;
};

const AuthLayout = ({ children }: Props) => {
  const originatorId = useContext(OriginatorIdContext).originatorId;
  return (
    <>
      <Flex h="100vh" flexDirection="column">
        <MobileTopBar />
        <Flex alignItems="flex-start" flex="1">
          {originatorId && (
            <Sidebar
              display={{ base: "none", lg: "flex" }}
              minHeight="100vh"
              position="sticky"
              top="0"
            />
          )}
          <Flex
            flex="1"
            pt={{ base: "14", lg: "6" }}
            px={{ base: "0", lg: "6" }}
            alignItems="flex-start"
            flexWrap="wrap"
            justifyContent="space-between"
            height="auto"
            overflow="auto"
          >
            {children}
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default AuthLayout;
