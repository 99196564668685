import { useState, useContext } from "react";
import { useIntl } from "react-intl";
import moment from "moment";
import {
  useNavigate,
  useSearchParams,
  URLSearchParamsInit,
} from "react-router-dom";
import useQueryParams from "../../shared/hooks/useQuery";
import AuthLayout from "../../shared/AuthLayout";
import LoanBook from "../LoanBook";
import DashboardCard from "../../dashboard/DashboardCard";
import { ILoanBookParams } from "../types";
import { IconButton, Box } from "@chakra-ui/react";
import { ArrowLeft20 } from "@carbon/icons-react";
import FilterTag from "../../shared/FilterTag";
import {
  AccessTokenContext,
  OriginatorIdContext,
} from "../../context/contextProvider";
import useHasAccess from "../../user/session/hooks/useHasAccess";
import PortfolioNavigation from "../../portfolio-visualizer/PortfolioNavigation";
import { useGetLoanBookQuery } from "../service";

interface GlobalParams
  extends ILoanBookParams,
    Record<string, string | number | undefined> {}

interface IFilter {
  type: string;
  value: string | number | undefined;
}

const Loanbook = () => {
  const navigate = useNavigate();
  const intl = useIntl();

  const accessToken = useContext(AccessTokenContext).accessToken;

  const defaultParams: ILoanBookParams = {
    from: moment("2020-09-01").format("YYYY-MM-DD") + "T00:00:00",
    to: moment("2021-09-01").format("YYYY-MM-DD") + "T00:00:00",
    originatorId: useContext(OriginatorIdContext).originatorId,
  };

  const queryParams: GlobalParams = useQueryParams() as GlobalParams;

  const [, setSearchParams] = useSearchParams();
  const [params, setParams] = useState<GlobalParams>({
    ...defaultParams,
    ...queryParams,
  });

  const { data } = useGetLoanBookQuery({
    ...params,
    accessToken: accessToken,
  });

  const updated_at = data?.data_set?.updated_at
    ? moment(data.data_set.updated_at).format("YYYY-MM-DD")
    : "";

  const { originatorId, from, to, resolution, ...filtersToDisplay } = params;

  const handleRemoveFilter = (filter: IFilter) => {
    delete queryParams[filter["type"]];
    const updatedParams = { ...params };
    delete updatedParams[filter["type"]];
    setParams(updatedParams);
    setSearchParams(queryParams as URLSearchParamsInit);
  };

  const hasDashboardAccess = useHasAccess("dashboard");

  return (
    <AuthLayout>
      <DashboardCard
        linkBack={
          hasDashboardAccess ? (
            <IconButton
              colorScheme="credo"
              _hover={{ bg: "credo.10" }}
              _active={{ bg: "credo.10" }}
              variant="outline"
              mr="12px"
              aria-label="Back"
              icon={<ArrowLeft20 />}
              w="40px"
              h="40px"
              minWidth="auto"
              onClick={() => navigate("/dashboard")}
            />
          ) : undefined
        }
        title={intl.formatMessage({ id: `loanbook_page_title` })}
        cardSubtitle={`${intl.formatMessage({
          id: `loanbook_page_subtitle`,
        })} ${updated_at}`}
        w="full"
        filters={
          <>
            <Box
              width={{ base: "100%", md: "auto" }}
              mt={{ base: "8px", md: "0" }}
              overflow="auto"
              whiteSpace="nowrap"
            >
              {Object.keys(filtersToDisplay).map((param, idx) => (
                <FilterTag
                  key={idx}
                  filter={{
                    type: param,
                    value: params[param],
                    abbreviate: param === "employees",
                    hideLabel: param === "industry",
                  }}
                  onRemoveFilter={handleRemoveFilter}
                />
              ))}
            </Box>
          </>
        }
        actions={
          <PortfolioNavigation
            params={params}
            queryParams={queryParams as URLSearchParamsInit}
          />
        }
      >
        <LoanBook params={params} />
      </DashboardCard>
    </AuthLayout>
  );
};

export default Loanbook;
