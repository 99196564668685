import React, { useLayoutEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { IAxisLabel } from "@amcharts/amcharts5/.internal/charts/xy/axes/AxisLabel";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import ChartsTheme from "../../theme/amcharts";
import { IDeploymentOverTimeValue } from "../../deployment/types";
import Bucket from "../../bucket";
import { Box, Flex, Text } from "@chakra-ui/react";

type Props = {
  title: string;
  dataSet: IDeploymentOverTimeValue[];
};

const DeployedVolumeChart = ({ title, dataSet }: Props) => {
  useLayoutEffect(() => {
    let root = am5.Root.new("deployed-volumes-chart-dashboard");
    root.setThemes([am5themes_Animated.new(root), ChartsTheme.new(root)]);

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        focusable: true,
        panX: false,
        panY: false,
        wheelX: "panX",
      })
    );

    chart.zoomOutButton.set("forceHidden", true);

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        numberFormat: "#,###",
        maxPrecision: 0,
        min: 0,
        renderer: am5xy.AxisRendererY.new(root, {
          strokeOpacity: 0.5,
          strokeWidth: 1,
        }),
      })
    );

    let yRenderer = yAxis.get("renderer");
    yRenderer.labels.template.setAll({
      textAlign: "right",
    });

    let fiatLabel = am5.Label.new(root, {
      text: "EUR",
      fontWeight: "bold",
      rotation: -90,
      y: am5.p50,
      centerX: am5.p50,
    });

    yAxis.children.unshift(fiatLabel);

    let xRenderer = am5xy.AxisRendererX.new(root, {
      strokeOpacity: 0.5,
      strokeWidth: 1,
    });
    xRenderer.grid.template.set("visible", false);

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "bucket",
        tooltip: am5.Tooltip.new(root, {}),
        renderer: xRenderer,
      })
    );

    xAxis.get("renderer").labels.template.setAll({
      minPosition: 0.01,
      maxPosition: 0.99,
      location: 0.5,
      multiLocation: 0.5,
    });

    xAxis
      .get("renderer")
      .labels.template.adapters.add("text", function (text, target) {
        const targetAxisLabel = target as IAxisLabel | undefined;
        const dataContext = targetAxisLabel?.dataItem?.dataContext as {
          bucket: string;
        };
        if (dataContext) {
          const bucketValue = dataContext.bucket;
          return Bucket.fromDataSourceBucketString(bucketValue).formatBucket();
        }
        return text;
      });

    chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "none",
      })
    );

    const generateSeries = (name: string, valueType: string, color: string) => {
      const series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: valueType,
          categoryXField: "bucket",
          fill: am5.color(color),
          stroke: am5.color(color),
        })
      );

      series.columns.template.setAll({
        tooltipText: "{name}: {valueY}",
        width: am5.percent(90),
        tooltipY: 0,
      });
      return series;
    };

    const actualSeries = generateSeries("Actual", "actual", "#287A92");
    const projectedAvgSeries = generateSeries(
      "Projected average",
      "projected_avg",
      "#85CDD9"
    );

    let cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        xAxis: xAxis,
      })
    );
    cursor.lineY.set("visible", false);

    actualSeries.data.setAll(dataSet);
    projectedAvgSeries.data.setAll(dataSet);
    xAxis.data.setAll(dataSet);

    actualSeries.appear(1000, 100);
    projectedAvgSeries.appear(1000, 100);
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  });
  return (
    <Box>
      <Flex>
        <Text fontSize="14px" fontWeight="600" px="4" pt="4" flexShrink={0}>
          {title}
        </Text>
      </Flex>
      <Box
        id="deployed-volumes-chart-dashboard"
        style={{ width: "100%", height: "400px" }}
      />
    </Box>
    // <div
    //   id="deployed-volumes-chart-dashboard"
    //   style={{ width: "100%", height: "450px" }}
    // ></div>
  );
};

export default DeployedVolumeChart;
