import Bucket from "../../bucket";
// import { IResolution } from "../../bucket/types";

// const NUMBER_OF_TIME_UNITS: Record<IResolution, number> = {
//   years: 10,
//   quarters: 8,
//   months: 12,
//   weeks: 10,
//   days: 30,
// };

const getViewportFrom = (bucket: Bucket, viewPortWidth: number): string => {
  return bucket.subtractTime(viewPortWidth, bucket.resolution);
};

const getViewportTo = (bucket: Bucket, viewPortWidth: number): string => {
  return bucket.addTime(viewPortWidth, bucket.resolution);
};

export { getViewportFrom, getViewportTo };
