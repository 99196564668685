import React from 'react'
import { Box } from "@chakra-ui/react"
import {

    ErrorOutline32,

} from "@carbon/icons-react"
type Props = {}

const ErrorIndicator = (props: Props) => {
    return (
        <Box
            bg="#FEF6F6"
            mr="16px"
            borderRadius="8px"
            border="1px solid #D27B80"
            w="64px"
            minW="64px"
            h="64px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            color="#DA1E28">
            <ErrorOutline32 />
        </Box>
    )
}

export default ErrorIndicator