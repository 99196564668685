import { store } from "../../store";
import { deploymentApi } from "../service";
import {
  IDeploymentOverTimeParamsV2,
  IDeploymentOverTimeSeries,
  IDeploymentOverTimeSeriesId,
  IDeploymentOverTimeValueV2,
  IPlottableDeploymentOverTimeValue,
} from "../types";

const fetchData = (params: IDeploymentOverTimeParamsV2, accessToken: string) => {
  return store.dispatch(
    deploymentApi.endpoints.getDeploymentOverTimeV2.initiate({
      ...params,
      accessToken,
    })
  );
};

const getPlottableData = (data: IDeploymentOverTimeValueV2[]) => {
  const plottableData: IPlottableDeploymentOverTimeValue[] = [];
  const viewPortWidth = data.length;
  data.forEach((dataPoint) => {
    for (const seriesType in dataPoint.series) {
      if (seriesType === "actual") {
        let newDataPoint = {
          bucket: dataPoint["bucket"],
          in: dataPoint["in"],
          filtered: getSeriesValue(dataPoint.series.actual, "filtered"),
          remainder: getSeriesValue(dataPoint.series.actual, "remainder"),
          viewPortWidth: viewPortWidth,
        };
        plottableData.push(newDataPoint);
      }
    }
  });

  return plottableData;
};

const getSeriesValue = (series: IDeploymentOverTimeSeries[], id: IDeploymentOverTimeSeriesId) => {
  const seriesItem = series.find((item) => item.id === id);
  if (seriesItem && (seriesItem.value > 0 || seriesItem.id === "remainder")) {
    return seriesItem.value;
  }
  return 0.1;
};

export { fetchData, getPlottableData };
