import { useContext } from "react";
import moment from "moment";
import { Box, Text } from "@chakra-ui/react";
import { Document20 } from "@carbon/icons-react";
import { AccessTokenContext } from "../context/contextProvider";
import { store } from "../store";
import { dataRoomApi } from "./service";
import { IFileContent, IFileDetails } from "./types";

const formatSize = (size: number): string => {
  const ONE_KB = 1024;
  const ONE_MB = 1024 * 1024;
  let measure_unit = "";
  let value = 0;
  if (size < ONE_KB) {
    value = size;
    measure_unit = "bytes";
  } else if (size > ONE_KB && size < ONE_MB) {
    value = size / ONE_KB;
    measure_unit = "KB";
  } else {
    value = size / ONE_MB;
    measure_unit = "MB";
  }
  return value && measure_unit ? `${value.toFixed(2)} ${measure_unit}` : "-";
};

const createLinkAndTriggerDownload = (url: string) => {
  var linkElement = document.createElement("a");
  linkElement.href = url;
  linkElement.target = "_blank";
  document.body.appendChild(linkElement);
  linkElement.click();
  linkElement.remove();
};

interface Props {
  file: IFileContent;
}

const File = ({ file }: Props) => {
  const accessToken = useContext(AccessTokenContext).accessToken;

  const { name, filetype, modified, size, key, data_room_id } = file;

  const handleOnClick = () => {
    store
      .dispatch(
        dataRoomApi.endpoints.getFileDetails.initiate(
          {
            accessToken,
            data_room_id,
            key,
          },
          { forceRefetch: true }
        )
      )
      .then((response) => {
        const data = response.data as IFileDetails;
        createLinkAndTriggerDownload(data.url);
      });
  };

  return (
    <>
      <Box
        onClick={handleOnClick}
        bg="#fff"
        p="4"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        cursor="pointer"
        transition="all 120ms ease-in-out"
        fontSize="14px"
        lineHeight="normal"
        borderTop="1px solid #E2E8F0"
        _hover={{
          bg: "#F2F8FB",
        }}
      >
        <Box display="flex" alignItems="center" flex="1">
          <Document20 style={{ width: "20px", minWidth: "20px" }} />
          <Text ml="8px">{`${name}.${filetype}`}</Text>
        </Box>
        <Box
          display={{ base: "none", md: "flex" }}
          alignItems="center"
          w="180px"
          pl="4"
          justifyContent="flex-end"
          textAlign="right"
        >
          <Text>{moment(modified).format("YYYY-MM-DD, HH:MM")}</Text>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          w="100px"
          pl="4"
          justifyContent="flex-end"
          textAlign="right"
        >
          <Text>{formatSize(size)}</Text>
        </Box>
      </Box>
    </>
  );
};

export default File;
