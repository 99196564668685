import { useLayoutEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { IAxisLabel } from "@amcharts/amcharts5/.internal/charts/xy/axes/AxisLabel";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { Box, Flex, Text } from "@chakra-ui/react";
import ChartTheme from "../../../theme/amcharts";
import Bucket from "../../../bucket";

type IDataSourceItem = {
  bucket: string;
  credo: number;
  originator: number;
};

type Props = {
  title: string;
  data: IDataSourceItem[];
};

const AnnualizedDefaultRatesChart = ({ title, data }: Props) => {
  useLayoutEffect(() => {
    let root = am5.Root.new("annualizedDefaultRatesChart");
    let legendRoot = am5.Root.new("annualizedDefaultRatesChartLegend");

    root.setThemes([am5themes_Animated.new(root), ChartTheme.new(root)]);
    legendRoot.setThemes([am5themes_Animated.new(root), ChartTheme.new(root)]);

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        layout: root.verticalLayout,
      })
    );

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        numberFormat: "#'%'",
        maxPrecision: 0,
        max: 10,
        renderer: am5xy.AxisRendererY.new(root, {
          strokeOpacity: 0.5,
          strokeWidth: 1,
        }),
      })
    );

    let xRenderer = am5xy.AxisRendererX.new(root, {
      strokeOpacity: 0.5,
      strokeWidth: 1,
      minGridDistance: 10,
    });
    xRenderer.grid.template.set("visible", false);
    xRenderer.labels.template.setAll({
      rotation: -45,
    });

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "bucket",
        tooltip: am5.Tooltip.new(root, {}),
        startLocation: 0.5,
        endLocation: 0.5,
        renderer: xRenderer,
      })
    );

    xAxis.get("renderer").labels.template.setAll({
      minPosition: 0.01,
      maxPosition: 0.99,
      location: 0.5,
      multiLocation: 0.5,
    });

    xAxis
      .get("renderer")
      .labels.template.adapters.add("text", function (text, target) {
        const targetAxisLabel = target as IAxisLabel | undefined;
        const dataContext = targetAxisLabel?.dataItem?.dataContext as {
          bucket: string;
        };
        if (dataContext) {
          const bucketValue = dataContext.bucket;
          return Bucket.fromDataSourceBucketString(bucketValue).formatBucket();
        }
        return text;
      });

    xAxis.data.setAll(data);

    let legend = legendRoot.container.children.push(
      am5.Legend.new(legendRoot, {
        paddingTop: 15,
        useDefaultMarker: true,
        x: am5.percent(0),
        centerX: am5.percent(-110),
      })
    );

    legend.labels.template.setAll({
      fontSize: 12,
      fontWeight: "300",
      textAlign: "right",
    });

    legend.valueLabels.template.setAll({
      forceHidden: true,
    });

    const generateSeries = (
      name: string,
      legendName: string,
      color: string
    ) => {
      let series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: name,
          categoryXField: "bucket",
          stroke: am5.color(color),
          fill: am5.color(color),
          legendLabelText: legendName,
          tooltip: am5.Tooltip.new(root, {
            labelText: "{valueY}",
          }),
        })
      );

      series.strokes.template.setAll({
        strokeWidth: 3,
      });

      series.data.setAll(data);

      return series;
    };

    let cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "none",
      })
    );
    cursor.lineY.set("visible", false);

    generateSeries("credo", "Credo's 90 DPD", "#287A92");
    generateSeries("originator", "Originator", "#EF8129");

    legend.data.setAll(chart.series.values);

    return () => {
      root.dispose();
      legendRoot.dispose();
    };
  });

  return (
    <Box>
      <Flex>
        <Text fontSize="14px" fontWeight="600" px="4" pt="4" flexShrink={0}>
          {title}
        </Text>
        <Box flexGrow={1} id={"annualizedDefaultRatesChartLegend"} />
      </Flex>
      <Box
        id="annualizedDefaultRatesChart"
        style={{ width: "100%", height: "400px" }}
      />
    </Box>
  );
};

export default AnnualizedDefaultRatesChart;
