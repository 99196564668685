import * as React from "react";
import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Divider,
  Flex,
  FlexProps,
  Spacer,
  Stack,
  useColorModeValue as mode,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Link,
} from "@chakra-ui/react";

import {
  ChartCombo24,
  Code24,
  Email24,
  Settings20,
  TableSplit24,
  Logout20,
  ChartStacked20,
  DataTableReference24,
  Catalog24,
  InProgressWarning20,
  ChartMultiLine20,
  DecisionTree24,
  UserMultiple20,
} from "@carbon/icons-react";

import { Logo } from "./Logo";
import { NavLink } from "./NavLink";
import UserSession from "../user/session/UserSession";
import { useAuth0 } from "@auth0/auth0-react";
import { BASE_URL } from "../config";
import { useIntl } from "react-intl";
import useHasAccess from "../user/session/hooks/useHasAccess";
import { useGetApiDocsQuery } from "../apidocs/service";
import {
  AccessTokenContext,
  OriginatorIdContext,
} from "../context/contextProvider";
import { useGetOriginatorsQuery } from "../originator/services";

export const Sidebar = (props: FlexProps) => {
  const { logout } = useAuth0();
  const location = useLocation();
  const current_page = location.pathname;
  const intl = useIntl();
  const navigate = useNavigate();

  const hasAccountPermission = useHasAccess("account");
  const accessToken = useContext(AccessTokenContext).accessToken;

  const { data: apiDocsData } = useGetApiDocsQuery({
    accessToken: accessToken,
  });

  const apiDocsLink = apiDocsData?.data.url;

  const { data: originatorData } = useGetOriginatorsQuery(
    { accessToken },
    { skip: !accessToken }
  );

  const canChangeOriginator = (originatorData?.originators?.length || 0) > 1;

  const originatorName = useContext(OriginatorIdContext).originatorName;

  return (
    <Flex
      bg={mode("white", "gray.800")}
      direction="column"
      borderRightWidth="1px"
      width="69"
      {...props}
    >
      <Flex direction="column" flex="1" pt="5" pb="4" overflowY="auto" px="4">
        <Box mb="3">
          <Logo width="200px" height="74px" fill="#003342" />
        </Box>

        <Stack spacing="3" as="nav" aria-label="Sidebar Navigation">
          <Divider />
          <Box>Originator - {originatorName}</Box>
          <Stack spacing="2">
            <NavLink
              pageId="credit_product"
              to="/credit-product"
              label={intl.formatMessage({
                id: "menu_credit_product_view",
              })}
              icon={DecisionTree24}
              isActive={current_page === "/credit-product"}
            />
            <NavLink
              pageId="dashboard"
              to="/dashboard"
              label={intl.formatMessage({ id: "menu_dashboard" })}
              icon={ChartCombo24}
              isActive={
                current_page === "/dashboard" || current_page === "/callback"
              }
            />

            <NavLink
              pageId="performance_view"
              to="/portfolio-visualizer/origination-and-performance"
              label={intl.formatMessage({
                id: "menu_origination_and_performance",
              })}
              icon={ChartStacked20}
              isActive={
                current_page ===
                "/portfolio-visualizer/origination-and-performance"
              }
            />
            <NavLink
              pageId="risk_view"
              to="/portfolio-visualizer/risk"
              label={intl.formatMessage({
                id: "menu_risk",
              })}
              icon={InProgressWarning20}
              isActive={current_page === "/portfolio-visualizer/risk"}
            />
            <NavLink
              pageId="covenants_view"
              to="/portfolio-visualizer/covenants"
              label={intl.formatMessage({
                id: "menu_covenant_view",
              })}
              icon={ChartMultiLine20}
              isActive={current_page === "/portfolio-visualizer/covenants"}
            />
            <NavLink
              pageId="table_view"
              to="/loanbook"
              label={intl.formatMessage({ id: "menu_loanbook" })}
              icon={TableSplit24}
              isActive={current_page === "/loanbook"}
            />
            <NavLink
              pageId="file_upload"
              to="/data-sourcing"
              label={intl.formatMessage({ id: "menu_data_sourcing" })}
              icon={DataTableReference24}
              isActive={current_page === "/data-sourcing"}
            />

            <NavLink
              pageId="data_room"
              to="/data-room"
              label={intl.formatMessage({ id: "menu_data_room" })}
              icon={Catalog24}
              isActive={current_page === "/data-room"}
            />

            {/* <NavLink label="Users" icon={Events24} />
            <NavLink label="Data sourcing" icon={DataTableReference24} /> */}
          </Stack>

          <Divider />

          <Stack spacing="2">
            <NavLink
              pageId="contact"
              to="/contact"
              label={intl.formatMessage({ id: "menu_contact" })}
              icon={Email24}
              isActive={current_page === "/contact"}
            />
          </Stack>
        </Stack>
        <Spacer />
      </Flex>

      <Menu matchWidth>
        <MenuButton
          as={Link}
          _hover={{
            bg: mode("gray.50", "gray.700"),
          }}
        >
          <UserSession />
        </MenuButton>
        <MenuList px="2" width="90%" mx="auto">
          {hasAccountPermission && (
            <>
              <MenuItem icon={<Settings20 />} rounded="md" py="2">
                {intl.formatMessage({ id: "menu_my_account" })}
              </MenuItem>
            </>
          )}
          {canChangeOriginator && (
            <>
              <MenuItem
                icon={<UserMultiple20 />}
                rounded="md"
                py="2"
                onClick={() => {
                  navigate("/originators");
                }}
              >
                {intl.formatMessage({ id: "menu_change_originator" })}
              </MenuItem>
            </>
          )}
          {/* <MenuItem icon={<Portfolio20 />} rounded="md" py="2">
            {intl.formatMessage({ id: "menu_organization" })}
          </MenuItem> */}
          {apiDocsLink && (
            <MenuItem
              as={Link}
              href={apiDocsLink}
              isExternal
              icon={<Code24 />}
              rounded="md"
              py="2"
            >
              {intl.formatMessage({ id: "menu_api_docs" })}
            </MenuItem>
          )}
          <MenuDivider />
          <MenuItem
            icon={<Logout20 />}
            rounded="md"
            py="2"
            onClick={() => {
              logout({ returnTo: `${BASE_URL}` });
            }}
          >
            {intl.formatMessage({ id: "menu_logout" })}
          </MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  );
};
