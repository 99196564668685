import { useIntl } from "react-intl";
import { Box } from "@chakra-ui/react";
import { IRiskGroupDistributionValue } from "../../../risk/types";
import RiskGroupDistributionChart from "../../../risk/charts/RiskGroupDistribution/RiskGroupDistributionChart";
import { getPlottableData } from "../../../risk/charts/RiskGroupDistribution/chartData";
import { getBucketsNotInDataCube } from "../../../risk/utils";

type Props = {
  data: IRiskGroupDistributionValue[] | [];
};
const RiskGroupDistribution = ({ data }: Props) => {
  const intl = useIntl();

  const dataSource = getPlottableData(data);
  const bucketsNotInDataCube = getBucketsNotInDataCube(dataSource);

  return (
    <Box>
      <RiskGroupDistributionChart
        data={dataSource}
        title={intl.formatMessage({ id: "risk_risk_group_distribution" })}
        bucketsNotInDataCube={bucketsNotInDataCube}
      />
    </Box>
  );
};

export default RiskGroupDistribution;
