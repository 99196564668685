import React from "react";
import { useIntl } from "react-intl";
import { Text, Box } from "@chakra-ui/react";
import AverageRecoveryChart from "./AverageRecoveryChart";
import { generateDataForYear } from "../../../stubs/defaultCurvesForVintageYear";
import useHasFeatureFlag from "../../../user/session/hooks/useHasFeatureFlag";
import ChartPlaceholder from "../ChartPlaceholder";

const AverageRecovery = () => {
  const intl = useIntl();
  const isDemoMode = useHasFeatureFlag("demo_mode");

  const dataSource = generateDataForYear(2020, 1);

  return (
    <Box>
      <Text fontSize="14px" fontWeight="600" px="4" pt="4">
        {intl.formatMessage({ id: "risk_average_recovery" })}
      </Text>
      {isDemoMode ? (
        <AverageRecoveryChart data={dataSource} />
      ) : (
        <ChartPlaceholder type="areaChart" />
      )}
    </Box>
  );
};

export default AverageRecovery;
