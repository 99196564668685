export const translations = {
  menu_dashboard: "Dashboard",
  menu_loanbook: "Loanbook",
  menu_portfolio: "Portfolio",
  menu_origination_and_performance: "Origination & performance",
  menu_risk: "Risk",
  menu_credit_product_view: "Credit Products",
  menu_covenant_view: "Covenants",
  menu_data_sourcing: "Data sourcing",
  menu_data_room: "Documents",
  menu_contact: "Contact",
  menu_my_account: "My account",
  menu_change_originator: "Change Originator",
  menu_organization: "Organization",
  menu_api_docs: "API Documentation",
  menu_logout: "Logout",
  deployment_totals_outstanding: "Outstanding",
  deployment_totals_originated_last_month: "Originated last month",
  deployment_totals_originated_to_date: "Originated to date",
  deployment_totals_prepayment: "Prepayment",
  deployment_totals_interest: "Contracted IRR",
  deployment_totals_avg_credit_ranking: "Avg. Credit Ranking",
  deployment_totals_avg_interest_rate: "Current IRR",
  deployment_totals_avg_ticket: "Avg. ticket",
  deployment_totals_avg_term: "Avg. term",
  deployment_totals_wal: "WAL",
  deployment_totals_last_month: "last month",
  deployment_totals_last_12_months: "last 12 mo.",
  deployment_deployed_volume_back: "Back 1 level",
  deployment_deployed_volume_apply_changes: "Apply changes to the entire page",
  deployment_industry: "Industry",
  deployment_employees: "Employees",
  deployment_turnover: "Turnover",
  deployment_industry_abbreviated: "Ind",
  deployment_employees_abbreviated: "Emp",
  deployment_turnover_abbreviated: "Trn",
  deployment_industry_label: "Industry",
  deployment_employees_label: "Employees",
  deployment_turnover_label: "Turnover",
  deployment_resolution: "resolution",
  deployment_map_message: "Map view will be available soon.",
  deployment_cohort_balance: "Cohort balance",
  loanbook_page_title: "Loan book table view",
  loanbook_page_subtitle: "Last data update",
  loanbook_ancillary: "Ancillary",
  loanbook_borrower: "Borrower",
  loanbook_observation_date: "Observation {br} date",
  loanbook_months_elapsed: "Months {br} elapsed",
  loanbook_payments_elapsed: "Payments {br} elapsed",
  loanbook_status: "Status",
  loanbook_amount_overdue: "Amount overdue",
  loanbook_days_overdue: "{sum} Days {br} overdue",
  loanbook_payments_overdue: "Payments {br} overdue",
  loanbook_outstanding_balance: "{sum} Outstanding {br} balance",
  loanbook_principal_paid: "{sum} Principal {br} paid",
  loanbook_interest_paid: "{sum} Interest {br} paid",
  loanbook_product_type: "Product type",
  loanbook_industry: "Industry",
  loanbook_country: "Country",
  loanbook_region: "Region",
  loanbook_interest_rate: "Interest rate",
  loanbook_principal: "Principal",
  loanbook_maturity_date: "Maturity date",
  loanbook_credit_rating: "Credit rating",
  loanbook_PD: "PD",
  loanbook_LGD: "LGD",
  loanbook_ECL: "ECL",
  loanbook_actual_xirr: "Current IRR",
  loanbook_events: "Events",
  loanbook_loan_id: "Loan ID",
  loanbook_repaid: "Repaid",
  loanbook_time_series: "Time Series",
  loanbook_chart_view: "Chart view",
  loanbook_error_message: "Something went wrong",
  loanbook_download_ready_message: "Your file is ready",
  loanbook_download_link_label: "Download file",
  loanbook_download_generating_file: "We are generating your file",
  loanbook_download_wait_message: "Please wait",
  loanbook_download_error: "There was an error while generating your file",
  download_button_idle: "Download",
  download_folder: "Download current folder",
  download_button_generating: "Generating",
  resolution_days: "days",
  resolution_weeks: "weeks",
  resolution_months: "months",
  resolution_quarters: "quarters",
  resolution_years: "years",
  risk_avg_12_m_pd: "Avg. 12m PD",
  risk_avg_pd: "Avg. PD",
  risk_avg_lgd: "Avg. LGD",
  risk_avg_ecl: "ECL",
  risk_written_off: "Written-off (to date)",
  risk_view_page_title: "Risk",
  credit_product_view_page_title: "Credit Products",
  covenants_view_page_title: "Covenants",
  covenants_performing_outstanding_principal:
    "Performing outstanding principal",
  covenants_defualted_principal: "Last day defaulted principal",
  covenants_default_rate: "Default rate",
  covenants_max_default_rate: "Max default rate",
  covenants_phase: "Current phase",
  covenants_advance_rate: "Advance rate",
  covenants_interest_rate: "Interest rate (inc. base rate)",
  covenants_recovery_rate: "Recovery rate",
  covenants_portfolio_apr: "Portfolio APR",
  covenants_delinquency_rate: "Delinquency rate",
  covenants_max_delinquency_rate: "Max delinquency rate",
  covenants_portfolio_margin: "Portfolio Margin",
  data_room_documents: "Documents",
  data_room_header_name: "Name",
  data_room_header_date_added: "Date Added",
  data_room_header_size: "Size",
  data_room_download_btn_label: "Download current folder",
  data_room_download_error: "There was an error while generating your file",
  data_room_download_generating_file: "We are generating your file",
  data_room_download_wait_message: "Please wait",
  data_room_download_ready_message: "Your file is ready",
  data_room_download_link_label: "Download file",
  data_room_tnc_consent: "Consent",
  data_room_tnc_message:
    "In order to access the contents of this data room, you must first read our rules of access and agree with the terms of use.",
  data_room_tnc_link_label: "Terms of Use",
  data_room_tnc_checkbox_label: "I agree to Terms",
  data_room_tnc_button_label: "Continue",
  data_room_tnc_error: "Something went wrong",
  data_sourcing_drag: "Drag",
  data_sourcing_drop_files: "drop your files here",
  data_sourcing_browse: "Browse for files",
  data_sourcing_or: "or",
  data_sourcing_uploader_error:
    "There was an issue with your upload. Please try again.",
  risk_annualized_default_rates: "Annualized default rates",
  risk_average_recovery: "Average recovery, months passed default",
  risk_default_curves_per_cohort: "Default curves per cohort",
  risk_loans_with_missed_first_payments: "Loans with missed first payments",
  risk_loans_with_late_payments: "Loans with late payments",
  risk_prepayment_rates: "Prepayment rates",
  risk_prepayments_per_cohort: "Prepayments per cohort",
  risk_probability_of_default: "Probability of default",
  risk_recoveries_by_cohort: "Recoveries by cohort",
  risk_risk_group_distribution: "Risk group distribution",
  risk_chart_placeholder_message: "This chart will be available soon.",
  performance_triggers_default_rate: "Default rate comparison",
  performance_triggers_delinquency_rate: "Delinquency rate comparison",
  performance_triggers_portfolio_margin: "Portfolio margin",
  performance_triggers_portfolio_apr: "Portfolio APR",
  deployment_performing_balance: "Performing outstanding balance (total)",
  deployment_originated_per_month: "Originated per month",
  deployment_originated_per_cohort: "Originated per cohort",
};
