import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  IAllCreditProductsDataSet,
  ICreateCreditProductData,
  ICreditProductsParams,
  IProductFieldDescriptions,
} from "./types";
import { API_BASE_URL } from "../config";
import { IAuthParams } from "../auth/types";

export const creditProductApi = createApi({
  reducerPath: "creditProductApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: (builder) => ({
    getCreditProducts: builder.query<
      IAllCreditProductsDataSet,
      ICreditProductsParams
    >({
      query: (params: ICreditProductsParams) => {
        return {
          url: `v1/originator/${params.originator_id}/credit_product`,
          headers: {
            Authorization: `Bearer ${params.accessToken}`,
          },
        };
      },
    }),
    getFieldDescriptions: builder.query<IProductFieldDescriptions, IAuthParams>(
      {
        query: (params: IAuthParams) => {
          return {
            url: `v1/credit_product/field_descriptions`,
            headers: {
              Authorization: `Bearer ${params.accessToken}`,
            },
          };
        },
      }
    ),
    createCreditProduct: builder.mutation<void, ICreateCreditProductData>({
      query: (data: ICreateCreditProductData) => {
        return {
          url: `v1/originator/${data.originator_id}/credit_product`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${data.accessToken}`,
            "Content-Type": "application/json",
          },
          body: data.data,
        };
      },
    }),
  }),
});

export const {
  useGetCreditProductsQuery,
  useGetFieldDescriptionsQuery,
  useCreateCreditProductMutation,
} = creditProductApi;
