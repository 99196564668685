import { useLayoutEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { IAxisLabel } from "@amcharts/amcharts5/.internal/charts/xy/axes/AxisLabel";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import ChartTheme from "../../../theme/amcharts";
import Bucket from "../../../bucket";
import { IFirstMissedPaymentPlottableData } from "../../types";
import { Box } from "@chakra-ui/react";

type Props = {
  bucketsNotInDataCube: { bucket: string; endBucket: string }[];
  data: IFirstMissedPaymentPlottableData[];
};

const LoansWithFirstPaymentMissed = ({ data, bucketsNotInDataCube }: Props) => {
  useLayoutEffect(() => {
    let root = am5.Root.new("loansWithFirstPaymentMissed");

    root.setThemes([am5themes_Animated.new(root), ChartTheme.new(root)]);

    let chart = root.container.children.push(am5xy.XYChart.new(root, {}));

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        numberFormat: "#'%'",
        maxPrecision: 0,
        renderer: am5xy.AxisRendererY.new(root, {
          strokeOpacity: 0.5,
          strokeWidth: 1,
        }),
      })
    );

    let xRenderer = am5xy.AxisRendererX.new(root, {
      strokeOpacity: 0.5,
      strokeWidth: 1,
    });
    xRenderer.grid.template.set("visible", false);

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "bucket",
        tooltip: am5.Tooltip.new(root, {}),
        renderer: xRenderer,
      })
    );

    xAxis.get("renderer").labels.template.setAll({
      minPosition: 0.01,
      maxPosition: 0.99,
      location: 0.5,
      multiLocation: 0.5,
    });

    xAxis
      .get("renderer")
      .labels.template.adapters.add("text", function (text, target) {
        const targetAxisLabel = target as IAxisLabel | undefined;
        const dataContext = targetAxisLabel?.dataItem?.dataContext as {
          bucket: string;
        };
        if (dataContext) {
          const bucketValue = dataContext.bucket;
          return Bucket.fromDataSourceBucketString(bucketValue).formatBucket();
        }
        return text;
      });

    xAxis.data.setAll(data);

    const createSeries = (name: string, color: string) => {
      let series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: name,
          categoryXField: "bucket",
          fill: am5.color(color),
          stroke: am5.color(color),
          tooltip: am5.Tooltip.new(root, {
            labelText: "{name}: {valueY}",
          }),
        })
      );

      series.columns.template.setAll({
        width: am5.percent(90),
      });

      series.set("fill", am5.color("#16778F"));
      series.set("stroke", am5.color("#16778F"));

      series.data.setAll(data);
      return series;
    };

    createSeries("filtered", "#287A92");

    let cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "none",
      })
    );
    cursor.lineY.set("visible", false);

    return () => {
      root.dispose();
    };
  });

  return (
    <Box
      id="loansWithFirstPaymentMissed"
      style={{ width: "100%", height: "400px" }}
    />
  );
};

export default LoansWithFirstPaymentMissed;
