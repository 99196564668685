import { useContext } from "react";
import { Box, SimpleGrid, Center } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { useGetDashboardQuery } from "../service";
import { useGetAlertsQuery } from "../../alerts/service";
import DashboardCard from "../DashboardCard";
import Loader from "../../shared/Loader";
import Alert from "../../alerts/Alert";
import DeployedVolumeChart from "../charts/DeployedVolumeChart";
import DistributionOfDeploymentChart from "../../deployment/charts/DistributionOfDeploymentChart";
import AuthLayout from "../../shared/AuthLayout";
// import DashboardDeploymentTotalsView from "../DashboardDeploymentTotalsView";
import RiskTotalsView from "../riskTotals/RiskTotalsView";
import ProbabilityOfDefault from "../charts/ProbabilityOfDefault";
import RiskGroupDistribution from "../charts/RiskGroupDistribution";
import {
  AccessTokenContext,
  OriginatorIdContext,
} from "../../context/contextProvider";
import {
  IDeploymentIndustriesValue,
  IDeploymentEmployeesValue,
  IDeploymentTurnoverValue,
} from "../../deployment/types";
import { IAlertDataPoint } from "../../alerts/types";
import moment from "moment";
import DeploymentPerforming from "../../deployment/DeploymentPerforming";
import DeploymentTotals from "../../deployment/DeploymentTotals";

const Dashboard = () => {
  const navigate = useNavigate();
  const intl = useIntl();

  const accessToken = useContext(AccessTokenContext).accessToken;
  const originatorId = useContext(OriginatorIdContext).originatorId;

  const { data: alertData } = useGetAlertsQuery({
    originator_id: originatorId,
    accessToken: accessToken,
  });

  const { data, isFetching, error } = useGetDashboardQuery({
    originator_id: originatorId,
    accessToken: accessToken,
  });

  const params = {
    accessToken: accessToken,
    originatorId: originatorId,
    asset_class: "all",
    from: moment().subtract(5, "years").format("YYYY-MM-DD") + "T00:00:00",
    to: moment().format("YYYY-MM-DD") + "T00:00:00",
  };

  const performingParams = {
    ...params,
    viewport_from:
      moment().subtract(5, "years").format("YYYY-MM-DD") + "T00:00:00",
    viewport_to: moment().format("YYYY-MM-DD") + "T00:00:00",
  };

  const alerts =
    alertData?.data && alertData?.data.length > 0 ? alertData?.data : [];

  const translateDistributionChartData = (
    data:
      | IDeploymentIndustriesValue[]
      | IDeploymentEmployeesValue[]
      | IDeploymentTurnoverValue[],
    by: string
  ) => {
    return data.map((item) => ({
      ...item,
      translated_bucket: intl.formatMessage({ id: `${by}_${item.bucket}` }),
    }));
  };

  const deployedVolumeDataSet = data?.data.deployment_over_time || [];

  return (
    <>
      <AuthLayout>
        <>
          {alerts.length > 0 && (
            <DashboardCard title="Alerts" w="full">
              <Box px="4" pt="2" pb="4">
                {alerts.map((item: IAlertDataPoint, idx: number) => (
                  <Alert key={idx} status="warning" alertCode={item.alert} />
                ))}
              </Box>
            </DashboardCard>
          )}

          {isFetching && !data && <Loader />}

          {!isFetching && error && (
            <Box>
              There's an error. Team is already notified about the error.
            </Box>
          )}

          {!isFetching && !error && data && (
            <>
              <DashboardCard
                title="Origination and performance"
                w="full"
                onClick={() =>
                  navigate("/portfolio-visualizer/origination-and-performance")
                }
              >
                <>
                  {/* <DashboardDeploymentTotalsView
                    totals={data?.data.deployment_totals}
                  /> */}
                  <DeploymentTotals params={params} />

                  <Box
                    w="full"
                    display="flex"
                    flexWrap="wrap"
                    borderColor="gray.200"
                    borderBottomWidth="1px"
                    cursor="pointer"
                  >
                    <Box
                      w={{ base: "100%", lg: "50%" }}
                      pt="4"
                      borderColor="gray.200"
                      borderRightWidth={{ base: "0", lg: "1px" }}
                    >
                      <DeployedVolumeChart
                        title={intl.formatMessage({
                          id: "deployment_originated_per_month",
                        })}
                        dataSet={deployedVolumeDataSet}
                      />
                    </Box>
                    <Box
                      w={{ base: "100%", lg: "50%" }}
                      p="4"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderColor="gray.200"
                      borderTopWidth={{ base: "1px", lg: "0" }}
                      position="relative"
                    >
                      <DeploymentPerforming params={performingParams} />
                    </Box>
                  </Box>

                  <SimpleGrid
                    columns={{ base: 1, lg: 3 }}
                    textAlign="center"
                    cursor="pointer"
                  >
                    <Center
                      borderColor="gray.200"
                      borderLeftWidth={{ base: "0", lg: "0" }}
                      borderBottomWidth={{ base: "1px", lg: "0" }}
                    >
                      <DistributionOfDeploymentChart
                        data={
                          data?.data
                            ? translateDistributionChartData(
                                data.data.deployment_industries,
                                "industry"
                              )
                            : []
                        }
                        by={"industry"}
                        label={intl.formatMessage({
                          id: `deployment_industry_label`,
                        })}
                      />
                    </Center>
                    <Center
                      borderColor="gray.200"
                      borderLeftWidth={{ base: "0", lg: "1px" }}
                      borderBottomWidth={{ base: "1px", lg: "0" }}
                    >
                      <DistributionOfDeploymentChart
                        data={
                          data?.data
                            ? translateDistributionChartData(
                                data.data.deployment_employees,
                                "employees"
                              )
                            : []
                        }
                        by={"employees"}
                        label={intl.formatMessage({
                          id: `deployment_employees_label`,
                        })}
                      />
                    </Center>
                    <Center
                      borderColor="gray.200"
                      borderLeftWidth={{ base: "0", lg: "1px" }}
                      borderBottomWidth={{ base: "0", lg: "0" }}
                    >
                      <DistributionOfDeploymentChart
                        data={
                          data?.data
                            ? translateDistributionChartData(
                                data.data.deployment_turnover,
                                "turnover"
                              )
                            : []
                        }
                        by={"turnover"}
                        label={intl.formatMessage({
                          id: `deployment_turnover_label`,
                        })}
                      />
                    </Center>
                  </SimpleGrid>
                </>
              </DashboardCard>

              <DashboardCard title="Credit risk" w="full">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  flexWrap="wrap"
                >
                  <RiskTotalsView totals={data?.data.deployment_totals} />
                  <SimpleGrid
                    columns={{ base: 1, lg: 2, xl: 2 }}
                    width={{ base: "100%", xl: "100%" }}
                  >
                    <Box
                      w="full"
                      borderColor="gray.200"
                      borderBottomWidth={{ base: "1px", lg: "0", xl: "0" }}
                    >
                      <ProbabilityOfDefault
                        data={data?.data ? data.data.pd_by_age : []}
                      />
                    </Box>
                    <Box
                      w="full"
                      borderColor="gray.200"
                      borderLeftWidth={{ base: "0", lg: "1px", xl: "1px" }}
                    >
                      <RiskGroupDistribution
                        data={
                          data?.data ? data.data.credit_risk_distribution : []
                        }
                      />
                    </Box>
                  </SimpleGrid>
                </Box>
              </DashboardCard>
            </>
          )}
        </>
      </AuthLayout>
    </>
  );
};

export default Dashboard;
