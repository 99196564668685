import React, { useContext, useState } from "react";
import { useIntl } from "react-intl";
import {
  Button,
  Box,
  Modal,
  ModalBody,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  useDisclosure,
  Spinner,
  Link,
  InputGroup,
  Input,
  InputLeftAddon,
  SimpleGrid,
  useToast,
  Text,
} from "@chakra-ui/react";

import {
  DocumentDownload20,
  Data_220 as Data220,
  Table20,
  CheckmarkOutline32,
  Error32,
} from "@carbon/icons-react";
import { FormattedMessage } from "react-intl";
import {
  AccessTokenContext,
  OriginatorIdContext,
} from "../context/contextProvider";
import useGetDownloadLink from "./useGetDownloadLink";
import moment from "moment";
import { ILoanBookFilters } from "../loanbook/types";

interface IProps {
  filters: ILoanBookFilters;
  hasRoundedBorder?: boolean;
}

const DownloadButton = ({ filters, hasRoundedBorder }: IProps) => {
  const intl = useIntl();

  const accessToken = useContext(AccessTokenContext).accessToken;
  const originatorId = useContext(OriginatorIdContext).originatorId;

  const {
    isOpen: isModalOpen,
    onOpen: openModal,
    onClose: closeModal,
  } = useDisclosure();
  const [fileName, setFileName] = useState(
    `credo-data-${moment().format("YYYY-MM-DD")}`
  );
  const toast = useToast();
  const hasDisplayedError = React.useRef(false);
  const hasDisplayedLoader = React.useRef(false);
  const hasDisplayedSuccess = React.useRef(false);

  const closeAllToasts = () => {
    toast.closeAll();
  };
  const cleanToastsState = () => {
    hasDisplayedError.current = false;
    hasDisplayedLoader.current = false;
    hasDisplayedSuccess.current = false;
  };
  const { initDownload, status, downloadUrl, isGenerating } =
    useGetDownloadLink(originatorId, accessToken);

  if (downloadUrl && !hasDisplayedSuccess.current && !isGenerating) {
    closeAllToasts();
    hasDisplayedSuccess.current = true;
    toast({
      position: "bottom",
      duration: 5000,
      isClosable: false,
      render: () => {
        return (
          <Box
            shadow="base"
            color="#fff"
            p={3}
            bg="#38A169"
            borderRadius="8"
            display="flex"
            alignItems="center"
          >
            <Box mr="2" display="flex">
              <CheckmarkOutline32 />
            </Box>
            <Box>
              <Text fontWeight="700">
                {intl.formatMessage({ id: "loanbook_download_ready_message" })}
              </Text>
              <Link
                onClick={closeAllToasts}
                textDecoration="underline"
                href={downloadUrl}
                target="_blank"
                rel="noreferrer"
              >
                {intl.formatMessage({ id: "loanbook_download_link_label" })}
              </Link>
            </Box>
          </Box>
        );
      },
    });
  }
  if (status === "generating" && !hasDisplayedLoader.current) {
    closeAllToasts();
    cleanToastsState();
    hasDisplayedLoader.current = true;
    toast({
      position: "bottom",
      duration: null,
      isClosable: true,
      render: () => {
        return (
          <Box
            shadow="base"
            color="#fff"
            p={3}
            bg="blue.500"
            borderRadius="8"
            display="flex"
            alignItems="center"
          >
            <Box mr="2" display="flex">
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="blue.500"
                color="#fff"
                size="lg"
              />
            </Box>
            <Box>
              <Text fontWeight="700">
                {intl.formatMessage({
                  id: "loanbook_download_generating_file",
                })}
              </Text>
              <Text>
                {intl.formatMessage({
                  id: "loanbook_download_wait_message",
                })}
                ...
              </Text>
            </Box>
          </Box>
        );
      },
    });
  }

  if (status === "error" && !hasDisplayedError.current) {
    closeAllToasts();
    cleanToastsState();
    hasDisplayedError.current = true;
    toast({
      position: "bottom",
      duration: 4000,
      isClosable: true,
      render: () => {
        return (
          <Box
            shadow="base"
            color="#fff"
            p={3}
            bg="red"
            borderRadius="8"
            display="flex"
            alignItems="center"
          >
            <Box mr="2" display="flex">
              <Error32 />
            </Box>
            <Box>
              <Text fontWeight="700">
                {intl.formatMessage({
                  id: "loanbook_download_error",
                })}
              </Text>
            </Box>
          </Box>
        );
      },
    });
  }

  const startDownload = (fileType: "xls" | "csv") => {
    initDownload({ originatorId, accessToken, fileName, fileType, filters });
    closeModal();
    cleanToastsState();
  };

  const downloadBtnHandler = () => {
    openModal();
  };
  const onFileNameChange = (e: any) => {
    setFileName(e.target.value);
  };

  return (
    <>
      <Button
        onClick={downloadBtnHandler}
        height="40px"
        minWidth="40px"
        width={{ base: "25%", md: "auto" }}
        padding={{ base: "0 12px", md: "0 12px" }}
        fontSize="14px"
        _hover={{ bg: "credo.10" }}
        _active={{ bg: "credo.10" }}
        borderRadius={
          hasRoundedBorder
            ? { base: "8px", md: "8px" }
            : { base: "0 8px 8px 0", md: "0 8px 8px 0" }
        }
      >
        <DocumentDownload20 />
        {/* <Box display={{ base: "block", md: "block" }}>
          <FormattedMessage id="download_button_idle" />
        </Box> */}
      </Button>

      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent maxW="400px">
          <ModalHeader
            fontSize="16px"
            p="16px"
            display="flex"
            alignItems="center"
          >
            <DocumentDownload20 />
            &nbsp;
            <FormattedMessage id="download_button_idle" />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody p="4" borderTop="1px solid #E9EAEC">
            <InputGroup>
              <InputLeftAddon children="File name" />
              <Input
                type="text"
                onChange={onFileNameChange}
                required
                placeholder="Add desired file name"
                value={fileName}
              />
            </InputGroup>
            <SimpleGrid columns={2} spacing={2} mt="4">
              <Button
                onClick={() => startDownload("csv")}
                minWidth="40px"
                padding={{ base: "0 12px", md: "0 12px" }}
                fontSize="14px"
                _hover={{ bg: "credo.10" }}
                _active={{ bg: "credo.10" }}
                leftIcon={<Data220 />}
              >
                CSV
              </Button>
              <Button
                onClick={() => startDownload("xls")}
                minWidth="40px"
                padding={{ base: "0 12px", md: "0 12px" }}
                fontSize="14px"
                _hover={{ bg: "credo.10" }}
                _active={{ bg: "credo.10" }}
                leftIcon={<Table20 />}
              >
                XLS
              </Button>
            </SimpleGrid>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DownloadButton;
