import * as am5xy from "@amcharts/amcharts5/xy";
import { AxisRenderer } from "@amcharts/amcharts5/.internal/charts/xy/axes/AxisRenderer";
import { ICategoryAxisPrivate } from "@amcharts/amcharts5/.internal/charts/xy/axes/CategoryAxis";
import { IPlottableDeploymentOverTimeValue } from "../types";

const getCategoryAxis = (chart: am5xy.XYChart) => {
  return chart.xAxes.values[0] as am5xy.CategoryAxis<AxisRenderer>;
};

const getCategoryAxisPrivateSettings = (
  chart: am5xy.XYChart
): ICategoryAxisPrivate => {
  const axis = getCategoryAxis(chart);
  return axis._privateSettings;
};

const getCategoryAxisDataPoint = (chart: am5xy.XYChart, index: number) => {
  const axis = getCategoryAxis(chart);
  return axis.data.getIndex(index) as
    | IPlottableDeploymentOverTimeValue
    | undefined;
};

const getCategoryAxisData = (chart: am5xy.XYChart) => {
  const axis = getCategoryAxis(chart);
  return axis.data.values as IPlottableDeploymentOverTimeValue[];
};

const getRelativeZoomPositionOfCategoryAxis = (chart: am5xy.XYChart) => {
  const axis = getCategoryAxis(chart);
  let start = axis.get("start");
  let end = axis.get("end");
  return { start, end };
};

const updateCategoryAxisZoomLevel = (
  chart: am5xy.XYChart,
  start: number,
  end: number
) => {
  const axis = getCategoryAxis(chart);
  axis.set("start", start);
  axis.set("end", end);
};

const resetCategoryAxisZoomLevel = (chart: am5xy.XYChart) => {
  const axis = getCategoryAxis(chart);
  axis.set("start", 0);
  axis.set("end", 1);
};

const maintainCategoryAxisZoomLevel = (
  chart: am5xy.XYChart,
  firstVisibleBucketStr: string,
  lastVisibleBucketStr: string
) => {
  const axis = getCategoryAxis(chart);
  const data = axis.data.values as IPlottableDeploymentOverTimeValue[];

  if (data.length === 0) return;

  const firstVisibleIdx = data.findIndex(
    (item) => item.bucket === firstVisibleBucketStr
  );
  const lastVisibleIdx = data.findIndex(
    (item) => item.bucket === lastVisibleBucketStr
  );

  const startZoom = (1 / data.length) * firstVisibleIdx;
  const endZoom = (1 / data.length) * (lastVisibleIdx + 1);

  axis.set("start", startZoom);
  axis.set("end", endZoom);
};

export {
  getCategoryAxis,
  getCategoryAxisPrivateSettings,
  getCategoryAxisDataPoint,
  getCategoryAxisData,
  getRelativeZoomPositionOfCategoryAxis,
  updateCategoryAxisZoomLevel,
  resetCategoryAxisZoomLevel,
  maintainCategoryAxisZoomLevel,
};
