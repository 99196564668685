import { useRef, useContext } from "react";
import { useIntl } from "react-intl";
import { Button, Box, useToast, Text, Link, Spinner } from "@chakra-ui/react";
import { AccessTokenContext } from "../context/contextProvider";
import { CheckmarkOutline32, Download20, Error32 } from "@carbon/icons-react";
import useGetDownloadLink from "./useGetDownloadLink";

type Props = { dataRoomId: string; state: string };

const DownloadButton = ({ dataRoomId, state }: Props) => {
  const intl = useIntl();

  const accessToken = useContext(AccessTokenContext).accessToken;
  const toast = useToast();

  const hasDisplayedError = useRef(false);
  const hasDisplayedLoader = useRef(false);
  const hasDisplayedSuccess = useRef(false);

  const { createArchive, status, downloadUrl, isGenerating } =
    useGetDownloadLink(dataRoomId, state, accessToken);

  const closeAllToasts = () => {
    toast.closeAll();
  };

  const cleanToastsState = () => {
    hasDisplayedError.current = false;
    hasDisplayedLoader.current = false;
    hasDisplayedSuccess.current = false;
  };

  const handleStartDownload = () => {
    createArchive({
      data_room_id: dataRoomId,
      state,
      accessToken,
    });
    cleanToastsState();
  };

  if (downloadUrl && !hasDisplayedSuccess.current && !isGenerating) {
    closeAllToasts();
    hasDisplayedSuccess.current = true;
    toast({
      position: "bottom",
      duration: 5000,
      isClosable: false,
      render: () => {
        return (
          <Box
            shadow="base"
            color="#fff"
            p={3}
            bg="#38A169"
            borderRadius="8"
            display="flex"
            alignItems="center"
          >
            <Box mr="2" display="flex">
              <CheckmarkOutline32 />
            </Box>
            <Box>
              <Text fontWeight="700">
                {intl.formatMessage({ id: "data_room_download_ready_message" })}
              </Text>
              <Link
                onClick={closeAllToasts}
                textDecoration="underline"
                href={downloadUrl}
                target="_blank"
                rel="noreferrer"
              >
                {intl.formatMessage({ id: "data_room_download_link_label" })}
              </Link>
            </Box>
          </Box>
        );
      },
    });
  }
  if (status === "generating" && !hasDisplayedLoader.current) {
    closeAllToasts();
    cleanToastsState();
    hasDisplayedLoader.current = true;
    toast({
      position: "bottom",
      duration: null,
      isClosable: true,
      render: () => {
        return (
          <Box
            shadow="base"
            color="#fff"
            p={3}
            bg="blue.500"
            borderRadius="8"
            display="flex"
            alignItems="center"
          >
            <Box mr="2" display="flex">
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="blue.500"
                color="#fff"
                size="lg"
              />
            </Box>
            <Box>
              <Text fontWeight="700">
                {intl.formatMessage({
                  id: "data_room_download_generating_file",
                })}
              </Text>
              <Text>
                {intl.formatMessage({ id: "data_room_download_wait_message" })}
                ...
              </Text>
            </Box>
          </Box>
        );
      },
    });
  }

  if (status === "error" && !hasDisplayedError.current) {
    closeAllToasts();
    cleanToastsState();
    hasDisplayedError.current = true;
    toast({
      position: "bottom",
      duration: 4000,
      isClosable: true,
      render: () => {
        return (
          <Box
            shadow="base"
            color="#fff"
            p={3}
            bg="red"
            borderRadius="8"
            display="flex"
            alignItems="center"
          >
            <Box mr="2" display="flex">
              <Error32 />
            </Box>
            <Box>
              <Text fontWeight="700">
                {intl.formatMessage({ id: "data_room_download_error" })}
              </Text>
            </Box>
          </Box>
        );
      },
    });
  }

  return (
    <>
      <Button
        onClick={handleStartDownload}
        variant="outline"
        colorScheme="credo"
        height="40px"
        p="12px"
        minWidth="40px"
        mt={{ base: "12px", xl: "0" }}
        width={{ base: "100%", xl: "auto" }}
        fontSize="14px"
        _hover={{ bg: "credo.10" }}
        _active={{ bg: "credo.10" }}
        leftIcon={<Download20 />}
      >
        <Box>{intl.formatMessage({ id: "data_room_download_btn_label" })}</Box>
      </Button>
    </>
  );
};

export default DownloadButton;
