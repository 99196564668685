import React from "react";
import {
  URLSearchParamsInit,
  useNavigate,
  createSearchParams,
  useLocation,
} from "react-router-dom";
import { Button, ButtonGroup } from "@chakra-ui/react";
import {
  TableSplit20,
  InProgressWarning20,
  ChartStacked20,
  ChartMultiLine20
} from "@carbon/icons-react";
import DownloadButton from "../download/DownloadButton";
import useHasAccess from "../user/session/hooks/useHasAccess";
import { ILoanBookFilters } from "../loanbook/types";

interface IPortfolioParams extends ILoanBookFilters {}

type Props = {
  params: IPortfolioParams;
  queryParams: URLSearchParamsInit;
};

const PortfolioNavigation = ({ params, queryParams }: Props) => {
  const navigate = useNavigate();

  const location = useLocation();
  const current_page = location.pathname;

  const hasTableViewAccess = useHasAccess("table_view");
  const hasRiskViewAccess = useHasAccess("risk_view");
  const hasPerformanceViewAccess = useHasAccess("performance_view");
  const hasCovenantsViewAccess = useHasAccess("covenants_view");

  const isActive = (buttonType: "performance" | "risk" | "loanbook" | "covenants") => {
    const pathName = {
      performance: "/portfolio-visualizer/origination-and-performance",
      risk: "/portfolio-visualizer/risk",
      loanbook: "/loanbook",
      covenants: "/portfolio-visualizer/covenants",
    };
    return current_page === pathName[buttonType];
  };

  return (
    <ButtonGroup
      variant="outline"
      colorScheme="credo"
      spacing="0"
      width={{ base: "100%", md: "auto" }}
      mt={{ base: "8px", md: "0" }}
    >
      {hasPerformanceViewAccess && (
        <Button
          height="40px"
          minWidth="40px"
          width={{ base: "25%", md: "auto" }}
          padding={{ base: "0 12px", md: "0 12px" }}
          fontSize="14px"
          bg={isActive("performance") ? "credo.600" : "#fff"}
          _hover={{ bg: "credo.10" }}
          _active={{ bg: "credo.10" }}
          borderRadius={{ base: "8px 0 0 8px", md: "8px 0 0 8px" }}
          mr={{ base: "-px", md: "-px" }}
          onClick={() =>
            navigate({
              pathname: "/portfolio-visualizer/origination-and-performance",
              search: `?${createSearchParams(queryParams)}`,
            })
          }
        >
          <ChartStacked20
            color={isActive("performance") ? "#fff" : "credo.600"}
          />
        </Button>
      )}
      {hasRiskViewAccess && (
        <Button
          height="40px"
          minWidth="40px"
          width={{ base: "25%", md: "auto" }}
          padding={{ base: "0 12px", md: "0 12px" }}
          fontSize="14px"
          bg={isActive("risk") ? "credo.600" : "#fff"}
          _hover={{ bg: "credo.10" }}
          _active={{ bg: "credo.10" }}
          borderRadius={
            hasPerformanceViewAccess
              ? { base: "0", md: "0" }
              : { base: "8px 0 0 8px", md: "8px 0 0 8px" }
          }
          mr={{ base: "-px", md: "-px" }}
          onClick={() =>
            navigate({
              pathname: "/portfolio-visualizer/risk",
              search: `?${createSearchParams(queryParams)}`,
            })
          }
        >
          <InProgressWarning20
            color={isActive("risk") ? "#fff" : "credo.600"}
          />
        </Button>
      )}
      {hasCovenantsViewAccess && (
        <Button
          height="40px"
          minWidth="40px"
          width={{ base: "25%", md: "auto" }}
          padding={{ base: "0 12px", md: "0 12px" }}
          fontSize="14px"
          bg={isActive("covenants") ? "credo.600" : "#fff"}
          _hover={{ bg: "credo.10" }}
          _active={{ bg: "credo.10" }}
          borderRadius={
            hasPerformanceViewAccess
              ? { base: "0", md: "0" }
              : { base: "8px 0 0 8px", md: "8px 0 0 8px" }
          }
          mr={{ base: "-px", md: "-px" }}
          onClick={() =>
            navigate({
              pathname: "/portfolio-visualizer/covenants",
              search: `?${createSearchParams(queryParams)}`,
            })
          }
        >
          <ChartMultiLine20
            color={isActive("covenants") ? "#fff" : "credo.600"}
          />
        </Button>
      )}
      {hasTableViewAccess && (
        <Button
          height="40px"
          minWidth="40px"
          width={{ base: "25%", md: "auto" }}
          padding={{ base: "0 12px", md: "0 12px" }}
          fontSize="14px"
          bg={isActive("loanbook") ? "credo.600" : "#fff"}
          _hover={{ bg: "credo.10" }}
          _active={{ bg: "credo.10" }}
          borderRadius={
            !hasRiskViewAccess && !hasPerformanceViewAccess
              ? { base: "8px 0 0 8px", md: "8px 0 0 8px" }
              : { base: "0", md: "0" }
          }
          mr={{ base: "-px", md: "-px" }}
          onClick={() =>
            navigate({
              pathname: "/loanbook",
              search: `?${createSearchParams(queryParams)}`,
            })
          }
        >
          <TableSplit20 color={isActive("loanbook") ? "#fff" : "credo.600"} />
        </Button>
      )}
      <DownloadButton filters={params} hasRoundedBorder={!hasTableViewAccess} />
    </ButtonGroup>
  );
};

export default PortfolioNavigation;
