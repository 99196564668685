import { IResolution, IResolutionPrefix, IBucketInterval } from "./types";
import moment from "moment";
import Resolution from "./Resolution";
import { DATE_FORMAT, DATE_AND_TIME_FORMAT } from "./defaults";
import { IAnyBucket } from "./types";

abstract class Bucket {
  resolution: IResolution;
  bucketName: string;
  bucketDateStr: string;

  constructor(bucketName: string) {
    this.bucketName = bucketName;
    this.resolution = Resolution.fromPrefix(bucketName[0] as IResolutionPrefix);
    this.bucketDateStr = bucketName.substring(1);
  }

  static fromDataSourceBucketString(bucketName: string) {
    const resolution = Resolution.fromPrefix(
      bucketName[0] as IResolutionPrefix
    );
    switch (resolution) {
      case Resolution.years:
        return new YearsBucket(bucketName);
      case Resolution.quarters:
        return new QuartersBucket(bucketName);
      case Resolution.months:
        return new MonthsBucket(bucketName);
      case Resolution.weeks:
        return new WeeksBucket(bucketName);
      case Resolution.days:
        return new DaysBucket(bucketName);
      default:
        throw Error("Unknown resolution");
    }
  }

  getZoomedOutResolution(): IResolution {
    return Resolution.getZoomedOutResolution(this.resolution);
  }

  getZoomedInResolution(): IResolution {
    return Resolution.getZoomedInResolution(this.resolution);
  }

  isBefore(bucket: IAnyBucket) {
    return this.getDateFromBucket().isBefore(bucket.getDateFromBucket());
  }

  isAfter(bucket: IAnyBucket) {
    return this.getDateFromBucket().isAfter(bucket.getDateFromBucket());
  }

  isSameOrBefore(bucket: IAnyBucket) {
    return this.getDateFromBucket().isSameOrBefore(bucket.getDateFromBucket());
  }

  isSameOrAfter(bucket: IAnyBucket) {
    return this.getDateFromBucket().isSameOrAfter(bucket.getDateFromBucket());
  }

  addTime(numberOfUnits: number, resolution: IResolution) {
    return (
      this.getDateFromBucket()
        .add(numberOfUnits, resolution)
        .format("YYYY-MM-DD") + "T00:00:00"
    );
  }

  subtractTime(numberOfUnits: number, resolution: IResolution) {
    return (
      this.getDateFromBucket()
        .subtract(numberOfUnits, resolution)
        .format("YYYY-MM-DD") + "T00:00:00"
    );
  }

  abstract formatBucket(): string;

  abstract getDateFromBucket(): moment.Moment;

  abstract getDateStrFromBucket(): string;

  abstract getBoundaries(): IBucketInterval;
}

class YearsBucket extends Bucket {
  formatBucket() {
    return this.bucketDateStr;
  }

  getDateFromBucket() {
    const year = this.formatBucket();
    return moment(`${year}-01-01`, DATE_FORMAT);
  }

  getDateStrFromBucket() {
    return this.getDateFromBucket().format(DATE_AND_TIME_FORMAT) + "T00:00:00";
  }

  getPrevBucketDateStr() {
    let date = this.getDateFromBucket();
    return date.subtract(1, "years").format(DATE_AND_TIME_FORMAT) + "T00:00:00";
  }

  getNextBucketDateStr() {
    let date = this.getDateFromBucket();
    return date.add(1, "years").format(DATE_AND_TIME_FORMAT) + "T00:00:00";
  }

  getBoundaries() {
    let date = this.getDateFromBucket();
    return {
      from: date.format(DATE_AND_TIME_FORMAT) + "T00:00:00",
      to: date.endOf("year").format(DATE_AND_TIME_FORMAT) + "T00:00:00",
    };
  }
}

class QuartersBucket extends Bucket {
  year: string;
  quarterNo: string;

  constructor(bucketName: string) {
    super(bucketName);
    const [year, quarterNo] = this.bucketDateStr.split("-");
    this.year = year;
    this.quarterNo = quarterNo;
  }

  formatBucket() {
    return `Q${this.quarterNo} ${this.year}`;
  }

  getDateFromBucket() {
    const [monthName, year] = moment(this.bucketDateStr, "YYYY-Q")
      .format("MMM YYYY")
      .split(" ");
    const month = moment().month(monthName).format("MM");
    return moment(`${year}-${month}-01`, DATE_FORMAT);
  }

  getDateStrFromBucket() {
    return this.getDateFromBucket().format(DATE_AND_TIME_FORMAT) + "T00:00:00";
  }

  getPrevBucketDateStr() {
    const date = this.getDateFromBucket();
    return (
      date.subtract(1, "quarters").format(DATE_AND_TIME_FORMAT) + "T00:00:00"
    );
  }

  getNextBucketDateStr() {
    const date = this.getDateFromBucket();
    return date.add(1, "quarters").format(DATE_AND_TIME_FORMAT) + "T00:00:00";
  }

  getBoundaries() {
    const date = this.getDateFromBucket();
    return {
      from: date.startOf("quarter").format(DATE_AND_TIME_FORMAT) + "T00:00:00",
      to: date.endOf("quarter").format(DATE_AND_TIME_FORMAT) + "T00:00:00",
    };
  }
}

class MonthsBucket extends Bucket {
  formatBucket() {
    return moment(this.bucketDateStr, "YYYY-MM").format("MMM YYYY");
  }

  getDateFromBucket() {
    const [year, month] = this.bucketDateStr.split("-");
    return moment(`${year}-${month}-01`, DATE_FORMAT);
  }

  getDateStrFromBucket() {
    return this.getDateFromBucket().format(DATE_AND_TIME_FORMAT) + "T00:00:00";
  }

  getPrevBucketDateStr() {
    const date = this.getDateFromBucket();
    return (
      date.subtract(1, "months").format(DATE_AND_TIME_FORMAT) + "T00:00:00"
    );
  }

  getNextBucketDateStr() {
    const date = this.getDateFromBucket();
    return date.add(1, "months").format(DATE_AND_TIME_FORMAT) + "T00:00:00";
  }

  getBoundaries() {
    const date = this.getDateFromBucket();
    return {
      from: date.startOf("month").format(DATE_AND_TIME_FORMAT) + "T00:00:00",
      to: date.endOf("month").format(DATE_AND_TIME_FORMAT) + "T00:00:00",
    };
  }
}

class WeeksBucket extends Bucket {
  year: string;
  week: string;

  constructor(bucketName: string) {
    super(bucketName);
    const [year, week] = this.bucketDateStr.split("-");
    this.year = year;
    this.week = week;
  }

  formatBucket() {
    return `W${this.week} ${this.year}`;
  }

  getDateFromBucket() {
    const date = getFormattedDateOfISOWeek(
      parseInt(this.week),
      parseInt(this.year)
    );
    const [year, month, day] = date.split("-");
    return moment(`${year}-${month}-${day}`, DATE_FORMAT);
  }

  getDateStrFromBucket() {
    return this.getDateFromBucket().format(DATE_AND_TIME_FORMAT) + "T00:00:00";
  }

  getPrevBucketDateStr() {
    const date = this.getDateFromBucket();
    return date.subtract(1, "weeks").format(DATE_AND_TIME_FORMAT) + "T00:00:00";
  }

  getNextBucketDateStr() {
    const date = this.getDateFromBucket();
    return date.add(1, "weeks").format(DATE_AND_TIME_FORMAT) + "T00:00:00";
  }

  getBoundaries() {
    const date = this.getDateFromBucket();
    return {
      from: date.startOf("isoWeek").format(DATE_AND_TIME_FORMAT) + "T00:00:00",
      to: date.endOf("isoWeek").format(DATE_AND_TIME_FORMAT) + "T00:00:00",
    };
  }
}

class DaysBucket extends Bucket {
  formatBucket() {
    return this.bucketDateStr;
  }

  getDateFromBucket() {
    const [year, month, day] = this.formatBucket().split("-");
    return moment(`${year}-${month}-${day}`, DATE_FORMAT);
  }

  getDateStrFromBucket() {
    return this.getDateFromBucket().format(DATE_AND_TIME_FORMAT) + "T00:00:00";
  }

  getPrevBucketDateStr() {
    const date = this.getDateFromBucket();
    return date.subtract(1, "days").format(DATE_AND_TIME_FORMAT) + "T00:00:00";
  }

  getNextBucketDateStr() {
    const date = this.getDateFromBucket();
    return date.add(1, "days").format(DATE_AND_TIME_FORMAT) + "T00:00:00";
  }

  getBoundaries() {
    const date = this.getDateFromBucket();
    return {
      from: date.startOf("day").format(DATE_AND_TIME_FORMAT) + "T00:00:00",
      to: date.endOf("day").format(DATE_AND_TIME_FORMAT) + "T00:00:00",
    };
  }
}

export default Bucket;
export { YearsBucket, QuartersBucket, MonthsBucket, WeeksBucket, DaysBucket };

export type { IAnyBucket };

//TODO: move to bucket/dateutils.ts
const getYear = (date: Date): number => date.getFullYear();

const getDay = (date: Date): string => `${date.getDate()}`.padStart(2, "0");

const getMonth = (date: Date) => `${date.getMonth() + 1}`.padStart(2, "0");

const getDateOfISOWeek = (w: number, y: number) => {
  var simple = new Date(y, 0, 1 + (w - 1) * 7);
  var dow = simple.getDay();
  var ISOweekStart = simple;
  if (dow <= 4) ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
  else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
  return ISOweekStart;
};

const getFormattedDateOfISOWeek = (w: number, y: number) => {
  const date = getDateOfISOWeek(w, y);
  return `${getYear(date)}-${getMonth(date)}-${getDay(date)}`;
};
