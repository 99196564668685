import { useContext } from "react";
import { IDeploymentTotalsParams } from "../types";
import { useGetDeploymentTotalsQuery } from "../service";
import { AccessTokenContext } from "../../context/contextProvider";
import DeploymentTotalsView from "./DeploymentTotalsView";

type Props = {
  params: IDeploymentTotalsParams;
};

const DeploymentTotals = ({ params }: Props) => {
  const { data } = useGetDeploymentTotalsQuery({
    ...params,
    accessToken: useContext(AccessTokenContext).accessToken,
  });
  const totals = data?.data;

  return <DeploymentTotalsView totals={totals} />;
};

export default DeploymentTotals;
