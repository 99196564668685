type IDataSourceItem = {
  age: number;
  year: number;
  rate: number;
};

const randomIntFromInterval = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

const generateDataSource = () => {
  let dataSource: IDataSourceItem[] = [];
  const years = [2015, 2016, 2017, 2018, 2019, 2020];
  years.forEach((year) => {
    dataSource.push(...generateDataForYear(year));
  });
  return dataSource;
};

const generateDataForYear = (year: number, monthsIncrement: number = 5) => {
  const dataForYear = [];
  const MAX = monthsIncrement * 12;

  let minRate = 2;
  let maxRate = 4;

  for (let i = 1; i <= MAX; i = i + monthsIncrement) {
    const rate = randomIntFromInterval(minRate, maxRate);
    dataForYear.push({ rate: rate, age: i, year: year });
    minRate = i < 20 ? i : i >= 20 && i < 30 ? i - 10 : 15;
    maxRate = i < 20 ? 10 : i >= 20 && i < 30 ? 15 : 25;
  }

  return dataForYear;
};

export { generateDataSource, generateDataForYear };
