import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { Fonts } from "./shared/Fonts";
import theme from "./theme/chakra";
import CreditProduct from "./credit-product/routes/CreditProduct";
import CreateCreditProduct from "./credit-product/CreateCreditProduct";
import Dashboard from "./dashboard/routes/Dashboard";
import LoanBook from "./loanbook/routes/LoanBook";
import SignIn from "./auth/routes/SignIn";
import AuthCallback from "./auth/routes/AuthCallback";
import ProtectedComponent from "./auth/ProtectedComponent";
import OriginationAndPerformance from "./portfolio-visualizer/routes/OriginationAndPerformance";
import DataIngestion from "./data-sourcing/routes/DataIngestion";
import Contact from "./contact/routes/Contact";
import DataRoom from "./data-room/routes/DataRoom";
import Originators from "./originator/originators";
import ContextProvider from "./context/contextProvider";

import { IntlProvider } from "react-intl";
import getTranslations from "./locale";
import Risk from "./risk/routes/Risk";
import Covenants from "./risk/routes/Covenants";

function App(): JSX.Element {
  let location = useLocation();
  const currentLanguage = "en"; //This should be derived from app state when implementing multi language

  return (
    <ChakraProvider theme={theme}>
      <Fonts />
      <IntlProvider
        messages={getTranslations(currentLanguage)}
        locale={currentLanguage}
        defaultLocale="en"
      >
        <Routes location={location}>
          <Route path="/" element={<SignIn />} />
          <Route path="/auth/login" element={<SignIn />} />
          <Route path="/callback" element={<AuthCallback />} />
          <Route
            path="/originators"
            element={
              <ContextProvider>
                <Originators />
              </ContextProvider>
            }
          />
          <Route
            path="/credit-product"
            element={
              <ContextProvider>
                <ProtectedComponent pageId="credit_product">
                  <CreditProduct />
                </ProtectedComponent>
              </ContextProvider>
            }
          />
          <Route
            path="/credit-product/create"
            element={
              <ContextProvider>
                <ProtectedComponent pageId="credit_product">
                  <CreateCreditProduct />
                </ProtectedComponent>
              </ContextProvider>
            }
          />
          <Route
            path="/dashboard"
            element={
              <ContextProvider>
                <ProtectedComponent pageId="dashboard">
                  <Dashboard />
                </ProtectedComponent>
              </ContextProvider>
            }
          />

          <Route
            path="/loanbook"
            element={
              <ContextProvider>
                <ProtectedComponent pageId="table_view">
                  <LoanBook />
                </ProtectedComponent>
              </ContextProvider>
            }
          />
          <Route
            path="/portfolio-visualizer/origination-and-performance"
            element={
              <ContextProvider>
                <ProtectedComponent pageId="performance_view">
                  <OriginationAndPerformance />
                </ProtectedComponent>
              </ContextProvider>
            }
          />
          <Route
            path="/portfolio-visualizer/risk"
            element={
              <ContextProvider>
                <ProtectedComponent pageId="risk_view">
                  <Risk />
                </ProtectedComponent>
              </ContextProvider>
            }
          />
          <Route
            path="/portfolio-visualizer/covenants"
            element={
              <ContextProvider>
                <ProtectedComponent pageId="covenants_view">
                  <Covenants />
                </ProtectedComponent>
              </ContextProvider>
            }
          />

          <Route
            path="/contact"
            element={
              <ContextProvider>
                <ProtectedComponent pageId="contact">
                  <Contact />
                </ProtectedComponent>
              </ContextProvider>
            }
          />
          <Route
            path="/data-room"
            element={
              <ContextProvider>
                <ProtectedComponent pageId="data_room">
                  <DataRoom />
                </ProtectedComponent>
              </ContextProvider>
            }
          />
          <Route
            path="/data-sourcing"
            element={
              <ContextProvider>
                <ProtectedComponent pageId="file_upload">
                  <DataIngestion />
                </ProtectedComponent>
              </ContextProvider>
            }
          />
        </Routes>
      </IntlProvider>
    </ChakraProvider>
  );
}

export default App;
