import { useIntl } from "react-intl";
import DefaultCurvesPerCohortChart from "./DefaultCurvesPerCohortChart";
import { Text, Box } from "@chakra-ui/react";
import useHasFeatureFlag from "../../../user/session/hooks/useHasFeatureFlag";
import ChartPlaceholder from "../ChartPlaceholder";

const DefaultCurvesPerCohort = () => {
  const intl = useIntl();
  const isDemoMode = useHasFeatureFlag("demo_mode");

  return (
    <Box>
      <Text fontSize="14px" fontWeight="600" px="4" pt="4">
        {intl.formatMessage({ id: "risk_default_curves_per_cohort" })}
      </Text>
      {isDemoMode ? (
        <DefaultCurvesPerCohortChart />
      ) : (
        <ChartPlaceholder type="lineChart" />
      )}
    </Box>
  );
};

export default DefaultCurvesPerCohort;
