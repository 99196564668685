import { useState, useRef, useContext } from "react";
import { useIntl } from "react-intl";
import {
  useNavigate,
  useSearchParams,
  URLSearchParamsInit,
} from "react-router-dom";
import { IconButton, Box, SimpleGrid } from "@chakra-ui/react";
import { ArrowLeft20 } from "@carbon/icons-react";
import useQueryParams from "../../shared/hooks/useQuery";
import useHasAccess from "../../user/session/hooks/useHasAccess";
import AuthLayout from "../../shared/AuthLayout";
import {
  AccessTokenContext,
  OriginatorIdContext,
} from "../../context/contextProvider";
import DashboardCard from "../../dashboard/DashboardCard";
import FilterTag from "../../shared/FilterTag";
import { IRiskFilters } from "../types";
import moment from "moment";
import DistributionOfDeployments from "../../deployment/DistributionOfDeployments";
import LoansWithFirstPaymentMissed from "../charts/LoansWithFirstPaymentMissed";
import LoansWithLatePayments from "../charts/LoansWithLatePayments";
import AnnualizedDefaultRates from "../charts/AnnualizedDefaultRates";
import DefaultCurvesPerCohort from "../charts/DefaultCurvesPerCohort";
import ProbabilityOfDefault from "../charts/ProbabilityOfDefault";
import RiskGroupDistribution from "../charts/RiskGroupDistribution";
import AverageRecovery from "../charts/AverageRecovery";
import RecoveriesByCohort from "../charts/RecoveriesByCohort";
import PrepaymentsPerCohort from "../charts/PrepaymentsPerCohort";
import PrepaymentRates from "../charts/PrepaymentRates";
import RiskTotals from "../RiskTotals/";
import PortfolioNavigation from "../../portfolio-visualizer/PortfolioNavigation";
import LoansNo from "../../deployment/LoansNo";

interface GlobalParams
  extends IRiskFilters,
    Record<string, string | number | undefined> {}

type ISegment = {
  type: string;
  value: string | undefined;
};

interface IFilter {
  type: string;
  value: string | number | undefined;
}

const Risk = () => {
  const navigate = useNavigate();
  const intl = useIntl();

  const defaultParams: IRiskFilters = {
    from: moment().subtract(2, "years").format("YYYY-MM-DD") + "T00:00:00",
    to: moment().format("YYYY-MM-DD") + "T00:00:00",
    originatorId: useContext(OriginatorIdContext).originatorId,
    accessToken: useContext(AccessTokenContext).accessToken,
    asset_class: "all",
  };

  const queryParams: GlobalParams = useQueryParams() as GlobalParams;

  const [, setSearchParams] = useSearchParams();
  const [params, setParams] = useState<GlobalParams>({
    ...defaultParams,
    ...queryParams,
  });

  const {
    originatorId,
    accessToken,
    from,
    to,
    asset_class,
    resolution,
    ...filtersToDisplay
  } = params;

  const prevParams = useRef<ISegment | null>(null);

  const onSegmentChange = (segment: ISegment) => {
    if (segment.value === undefined) {
      delete queryParams[segment.type];
      const updatedParams = { ...params };
      delete updatedParams[segment.type];
      setParams(updatedParams);
    } else {
      queryParams[segment.type] = segment.value;
      setParams((prevState) => ({ ...prevState, ...queryParams }));
    }
    prevParams.current = { type: segment.type, value: segment.value };
    setSearchParams(queryParams as URLSearchParamsInit);
  };

  const shouldSkipDataFetching = (
    prevParams: ISegment | null,
    by: "industry" | "employees" | "turnover"
  ) => {
    let shouldSkip = false;
    if (prevParams !== null) {
      shouldSkip = prevParams.type === by;
    }
    return shouldSkip;
  };

  const handleRemoveFilter = (filter: IFilter) => {
    delete queryParams[filter["type"]];
    const updatedParams = { ...params };
    delete updatedParams[filter["type"]];
    setParams(updatedParams);
    setSearchParams(queryParams as URLSearchParamsInit);
  };

  const hasDashboardAccess = useHasAccess("dashboard");

  return (
    <AuthLayout>
      <DashboardCard
        overflow="hidden"
        w="full"
        title={intl.formatMessage({ id: `risk_view_page_title` })}
        cardSubtitle={<LoansNo params={params} />}
        linkBack={
          hasDashboardAccess ? (
            <IconButton
              colorScheme="credo"
              _hover={{ bg: "credo.10" }}
              _active={{ bg: "credo.10" }}
              variant="outline"
              mr="12px"
              aria-label="Back"
              icon={<ArrowLeft20 />}
              w="40px"
              h="40px"
              minWidth="auto"
              onClick={() => navigate("/dashboard")}
            />
          ) : undefined
        }
        filters={
          <>
            <Box
              width={{ base: "100%", md: "auto" }}
              mt={{ base: "8px", md: "0" }}
              overflow="auto"
              whiteSpace="nowrap"
            >
              {Object.keys(filtersToDisplay).map((param, idx) => (
                <FilterTag
                  key={idx}
                  filter={{
                    type: param,
                    value: params[param],
                    abbreviate: param === "employees",
                    hideLabel: param === "industry",
                  }}
                  onRemoveFilter={handleRemoveFilter}
                />
              ))}
            </Box>
          </>
        }
        actions={
          <PortfolioNavigation
            params={params}
            queryParams={queryParams as URLSearchParamsInit}
          />
        }
      >
        <>
          <RiskTotals params={params} />
          <SimpleGrid
            columns={{ base: 1, md: 3 }}
            bg={{ base: "#fff", md: "#FBFDFE" }}
            borderBottomWidth="1px"
            borderColor="gray.200"
          >
            <Box
              p="0"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <DistributionOfDeployments
                params={params}
                by="industry"
                onSegmentChange={onSegmentChange}
                shouldSkipFetch={shouldSkipDataFetching(
                  prevParams.current,
                  "industry"
                )}
              />
            </Box>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              p="0"
              borderColor="gray.200"
              borderTopWidth={{ base: "1px", md: "0px" }}
              borderBottomWidth={{ base: "1px", md: "0px" }}
              borderLeftWidth={{ base: "0", md: "1px" }}
              borderRightWidth={{ base: "0", md: "1px" }}
            >
              <DistributionOfDeployments
                params={params}
                by="employees"
                onSegmentChange={onSegmentChange}
                shouldSkipFetch={shouldSkipDataFetching(
                  prevParams.current,
                  "employees"
                )}
              />
            </Box>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              p="0"
            >
              <DistributionOfDeployments
                params={params}
                by="turnover"
                onSegmentChange={onSegmentChange}
                shouldSkipFetch={shouldSkipDataFetching(
                  prevParams.current,
                  "turnover"
                )}
              />
            </Box>
          </SimpleGrid>
          <Box
            w="full"
            display="flex"
            flexWrap="wrap"
            borderColor="gray.200"
            borderBottomWidth="1px"
          >
            <Box
              mt="4"
              w="full"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box
                p="8px 16px"
                minW="170px"
                borderRadius="24px"
                fontWeight="600"
                bg="#FBFDFE"
                borderColor="gray.200"
                borderWidth="1px"
                textAlign="center"
              >
                Risk Profile
              </Box>
            </Box>
            <Box
              w={{ base: "100%", lg: "50%" }}
              py="4"
              borderColor="gray.200"
              borderRightWidth={{ base: "0", lg: "1px" }}
            >
              <ProbabilityOfDefault params={params} />
            </Box>
            <Box
              w={{ base: "100%", lg: "50%" }}
              py="4"
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderColor="gray.200"
              borderTopWidth={{ base: "1px", lg: "0" }}
              position="relative"
            >
              <Box w="full" borderColor="gray.200">
                <RiskGroupDistribution params={params} />
              </Box>
            </Box>
          </Box>
          <Box
            w="full"
            display="flex"
            flexWrap="wrap"
            borderColor="gray.200"
            borderBottomWidth="1px"
          >
            <Box
              mt="4"
              w="full"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box
                p="8px 16px"
                minW="170px"
                borderRadius="24px"
                fontWeight="600"
                bg="#FBFDFE"
                borderColor="gray.200"
                borderWidth="1px"
                textAlign="center"
              >
                Delinquencies
              </Box>
            </Box>
            <Box
              w={{ base: "100%", lg: "50%" }}
              py="4"
              borderColor="gray.200"
              borderRightWidth={{ base: "0", lg: "1px" }}
            >
              <Box w="full" borderColor="gray.200">
                <LoansWithLatePayments params={params} />
              </Box>
            </Box>
            <Box
              w={{ base: "100%", lg: "50%" }}
              py="4"
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderColor="gray.200"
              borderTopWidth={{ base: "1px", lg: "0" }}
              position="relative"
            >
              <Box w="full" borderColor="gray.200">
                <LoansWithFirstPaymentMissed params={params} />
              </Box>
            </Box>
          </Box>
          <Box
            w="full"
            display="flex"
            flexWrap="wrap"
            borderColor="gray.200"
            borderBottomWidth="1px"
          >
            <Box
              mt="4"
              w="full"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box
                p="8px 16px"
                minW="170px"
                borderRadius="24px"
                fontWeight="600"
                bg="#FBFDFE"
                borderColor="gray.200"
                borderWidth="1px"
                textAlign="center"
              >
                Defaults
              </Box>
            </Box>
            <Box
              w={{ base: "100%", lg: "50%" }}
              py="4"
              borderColor="gray.200"
              borderRightWidth={{ base: "0", lg: "1px" }}
            >
              <Box w="full" borderColor="gray.200">
                <AnnualizedDefaultRates />
              </Box>
            </Box>
            <Box
              w={{ base: "100%", lg: "50%" }}
              py="4"
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderColor="gray.200"
              borderTopWidth={{ base: "1px", lg: "0" }}
              position="relative"
            >
              <Box w="full" borderColor="gray.200">
                <DefaultCurvesPerCohort />
              </Box>
            </Box>
          </Box>
          <Box
            w="full"
            display="flex"
            flexWrap="wrap"
            borderColor="gray.200"
            borderBottomWidth="1px"
          >
            <Box
              mt="4"
              w="full"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box
                p="8px 16px"
                minW="170px"
                borderRadius="24px"
                fontWeight="600"
                bg="#FBFDFE"
                borderColor="gray.200"
                borderWidth="1px"
                textAlign="center"
              >
                Prepayments
              </Box>
            </Box>
            <Box
              w={{ base: "100%", lg: "50%" }}
              py="4"
              borderColor="gray.200"
              borderRightWidth={{ base: "0", lg: "1px" }}
            >
              <Box w="full" borderColor="gray.200">
                <PrepaymentRates />
              </Box>
            </Box>
            <Box
              w={{ base: "100%", lg: "50%" }}
              py="4"
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderColor="gray.200"
              position="relative"
            >
              <Box w="full" borderColor="gray.200">
                <PrepaymentsPerCohort params={params} />
              </Box>
            </Box>
          </Box>

          <Box
            w="full"
            display="flex"
            flexWrap="wrap"
            borderColor="gray.200"
            borderBottomWidth="1px"
          >
            <Box
              mt="4"
              w="full"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box
                p="8px 16px"
                minW="170px"
                borderRadius="24px"
                fontWeight="600"
                bg="#FBFDFE"
                borderColor="gray.200"
                borderWidth="1px"
                textAlign="center"
              >
                Recoveries
              </Box>
            </Box>
            <Box
              w={{ base: "100%", lg: "50%" }}
              py="4"
              borderColor="gray.200"
              borderRightWidth={{ base: "0", lg: "1px" }}
            >
              <Box w="full" borderColor="gray.200">
                <RecoveriesByCohort />
              </Box>
            </Box>
            <Box
              w={{ base: "100%", lg: "50%" }}
              py="4"
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderColor="gray.200"
              position="relative"
            >
              <Box
                w="full"
                borderColor="gray.200"
                borderTopWidth={{ base: "1px", lg: "0", xl: "0" }}
              >
                <AverageRecovery />
              </Box>
            </Box>
          </Box>
        </>
      </DashboardCard>
    </AuthLayout>
  );
};

export default Risk;
