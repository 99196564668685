import React from "react";
import { Text, Tag, TagLabel, TagCloseButton, Tooltip } from "@chakra-ui/react";
import { useIntl } from "react-intl";

type IFilter = {
  type: string;
  value: string | number | undefined;
  abbreviate?: boolean
  hideLabel?: boolean
};

type Props = {
  filter: IFilter;
  onRemoveFilter: (filter: IFilter) => void;
};

const FilterTag = ({ filter, onRemoveFilter }: Props) => {
  const intl = useIntl();

  const translatedType = intl.formatMessage({
    id: `deployment_${filter.type}${filter.abbreviate ? "_abbreviated" : ""}`,
  });
  const translatedValue = intl.formatMessage({
    id: `${filter.type}_${filter.value}`,
  });
  let tagValue = translatedValue
  if (!filter.hideLabel) {
    tagValue = `${translatedType}: ${translatedValue}`
  }
  return (
    <Tag
      size="lg"
      borderRadius="8px"
      fontSize="14px"
      lineHeight="normal"
      fontWeight="normal"
      height="40px"
      pr="0"
      pl="12px"
      mr={{ base: "8px", md: "12px" }}
    >
      <Tooltip label={`${translatedType}: ${translatedValue}`} placement='bottom'>
        <TagLabel>
          <Text isTruncated maxWidth="100px">
            {tagValue}
          </Text>
        </TagLabel>
      </Tooltip>
      <TagCloseButton
        w="40px"
        h="40px"
        borderRadius="0 8px 8px 0"
        bg="#D8E3EE"
        m="0"
        marginInlineEnd="0"
        ml="12px"
        onClick={() => onRemoveFilter(filter)}
      />
    </Tag>
  );
};

export default FilterTag;
