import {
  IFirstMissedPaymentPlottableData,
  IFirstMissedPaymentValue,
} from "../../types";

export const getPlottableData = (
  data: IFirstMissedPaymentValue[]
): IFirstMissedPaymentPlottableData[] => {
  let dataSource: IFirstMissedPaymentPlottableData[] = [];
  if (!data) return [];
  data.forEach((item) => {
    let dataSourceItem = {
      bucket: item.bucket,
      in: item.in,
    } as IFirstMissedPaymentPlottableData;

    item.series.first_missed_payment.forEach((series) => {
      dataSourceItem = {
        ...dataSourceItem,
        [series.id]: series.value ? series.value * 100 : 0,
      };
    });
    dataSource.push(dataSourceItem);
  });

  return dataSource;
};
