import React from "react";
import {
  Alert as ChakraAlert,
  AlertIcon,
  AlertTitle,
} from "@chakra-ui/react";
import { useIntl } from "react-intl";

type Props = {
  alertCode: string;
  status: "info" | "warning" | "error" | "success"
};
const StatusColorMapping = {
  "info": { bgColor: '#D5EDFB', color: '#2D3748' },
  "warning": { bgColor: '#FEEFD2', color: '#50270C' },
  "error": { bgColor: '#FEE6E6', color: '#3C0E0E' },
  "success": { bgColor: '#D4F6DE', color: '#07170C' },
}


const Alert = ({ status, alertCode }: Props) => {
  const intl = useIntl();
  let translatedMessage = intl.formatMessage({ id: alertCode });
  translatedMessage = `${translatedMessage.charAt(0).toLocaleUpperCase()}${translatedMessage.slice(1)}`
  return (
    <>
      <ChakraAlert
        status="warning"
        rounded={{ lg: "lg" }}
        background={StatusColorMapping[status]['bgColor']}
        color={StatusColorMapping[status]['color']}
        mt="2"
        fontSize="14px"
        p="2"
      >
        <AlertIcon mr={2} />
        <AlertTitle fontWeight="500">{translatedMessage}</AlertTitle>
      </ChakraAlert>

    </>
  );
};

export default Alert;
