import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { IDataSet, IUserSessionParams } from "./types";
import { API_BASE_URL } from "../../config";

export const userSessionApi = createApi({
  reducerPath: "userSessionApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: (builder) => ({
    getSession: builder.query<IDataSet, IUserSessionParams>({
      query: (params: IUserSessionParams) => {
        return {
          url: `v1/session`,
          headers: {
            Authorization: `Bearer ${params.accessToken}`,
          },
        };
      },
    }),
  }),
});

export const { useGetSessionQuery } = userSessionApi;
