import { useAuth0 } from "@auth0/auth0-react";


interface IProps { }
const SignIn = (props: IProps) => {
    const { loginWithRedirect } = useAuth0();
    loginWithRedirect()
    return (
        <>
        </>
    )
}
export default SignIn;