import {
  IPrepaymentsPerCohortPlottableData,
  IPrepaymentsPerCohortValue,
} from "../../types";

export const getPlottableData = (
  data: IPrepaymentsPerCohortValue[]
): IPrepaymentsPerCohortPlottableData[] => {
  let dataSource: IPrepaymentsPerCohortPlottableData[] = [];
  if (!data) return [];

  const MONTHS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  data.forEach((dataItem) => {
    MONTHS.forEach((month: number, idx: number) => {
      const series = dataItem.series.pp[idx];
      dataSource.push({
        bucket: dataItem.bucket,
        in: dataItem.in,
        value: series ? series.value * 100 : 0,
        month: `${month}`.padStart(2, "0"),
      });
    });
  });

  return dataSource;
};
