import * as am5 from "@amcharts/amcharts5";

class ChartsTheme extends am5.Theme {
  protected setupDefaultRules(): void {
    this.rule("ColorSet").set("colors", [
      am5.color("#003344"), 
      am5.color("#6B8992"),
      am5.color("#1B4858"),
      am5.color("#869EA6"),
      am5.color("#365E6B"),
      am5.color("#A1B4B9"),
      am5.color("#51737F"),
      am5.color("#BCC9CD"),
    ]);
    this.rule("RoundedRectangle", ["legend", "marker", "rectangle"]).setAll({
      cornerRadiusTL: 0,
      cornerRadiusTR: 0,
      cornerRadiusBL: 0,
      cornerRadiusBR: 0,
    });

    this.rule("Label", ["legend", "label"]).setAll({
      marginRight: 0,
      fontSize: 12,
      fill: am5.color(0x596375),
    });

    this.rule("Label", ["legend", "label", "value"]).setAll({
      forceHidden: true,
    });

    this.rule("Label", ["axis"]).setAll({ fontSize: 12, fill: am5.color(0x596375) });

    this.rule("Label", ["vertical-yAxis-label"]).setAll({
      rotation: -90,
      y: am5.p50,
      centerX: am5.p50,
      fontWeight: "bold",
      fontSize: 12,
      fill: am5.color(0x000000),
    });
  }
}
export default ChartsTheme;
