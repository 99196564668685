import { useLayoutEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { Box, Flex, Text } from "@chakra-ui/react";
import ChartTheme from "../../../theme/amcharts";

type Props = {
  title: string;
  dataSet: { date: number; value: number }[];
};

const PortfolioAPRChart = ({ title, dataSet }: Props) => {
  useLayoutEffect(() => {
    let root = am5.Root.new("portfolioAPRChart");

    root.setThemes([am5themes_Animated.new(root), ChartTheme.new(root)]);

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        wheelY: "zoomX",
        layout: root.verticalLayout,
      })
    );

    let yRenderer = am5xy.AxisRendererY.new(root, {
      strokeOpacity: 0.5,
      strokeWidth: 1,
    });
    // yRenderer.grid.template.set("visible", false);

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        numberFormat: "#'%'",
        renderer: yRenderer,
      })
    );

    let xRenderer = am5xy.AxisRendererX.new(root, {
      strokeOpacity: 0.5,
      strokeWidth: 1,
    });
    // xRenderer.grid.template.set("visible", false);

    let xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: { timeUnit: "day", count: 1 },
        renderer: xRenderer,
      })
    );
    xAxis.set("tooltip", am5.Tooltip.new(root, {}));

    // xAxis.get("dateFormats")["day"] = "MM/dd";
    // xAxis.get("periodChangeDateFormats")["day"] = "MMMM";

    let series1 = chart.series.push(
      am5xy.LineSeries.new(root, {
        // stacked: true,
        name: `Portfolio APR`,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        valueXField: "date",
        tooltip: am5.Tooltip.new(root, {
          labelText:
            "[bold]{name}[/]\n{valueX.formatDate()}: {valueY.formatNumber('#,###.##')}%",
        }),
      })
    );

    series1.data.setAll(dataSet);

    chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "zoomXY",
        xAxis: xAxis,
      })
    );

    return () => {
      root.dispose();
    };
  });

  return (
    <Box>
      <Flex>
        <Text fontSize="14px" fontWeight="600" px="4" pt="4" flexShrink={0}>
          {title}
        </Text>
      </Flex>
      <Box id="portfolioAPRChart" style={{ width: "100%", height: "400px" }} />
    </Box>
  );
};

export default PortfolioAPRChart;
