import { useRef } from "react";

import { useGetDownloadProcessQuery, useInitDownloadMutation } from "./service";
// type IDownloadStatus = "idle" | "generating" | "ready_to_poll" | "pooling" | "error" | "success";

const useGetDownloadLink = (originatorId: string, accessToken: string) => {
  const statusRef = useRef("idle");
  let downloadUrl = "";
  const [
    initDownload,
    { data: process, isLoading: isGenerating, isSuccess: generationSuccess, isError: isGenerationError },
  ] = useInitDownloadMutation();

  if (isGenerating) {
    statusRef.current = "generating";
  }
  if (isGenerationError) {
    statusRef.current = "error";
  }

  if (generationSuccess && !isGenerating && ["polling", "finished", "error"].indexOf(statusRef.current) === -1) {
    statusRef.current = "polling";
  }

  const process_id = process?.process_id ? process.process_id : "";

  const { data, isFetching, error } = useGetDownloadProcessQuery(
    { originatorId: originatorId, process_id, accessToken },
    { skip: statusRef.current !== "polling", pollingInterval: 3000 }
  );
  if (error) {
    statusRef.current = "finished";
  }
  if ((data?.state === "stopped" || data?.state === "completed") && !isFetching && statusRef.current !== "generating") {
    statusRef.current = "finished";
    if (data?.download_url) {
      downloadUrl = data.download_url;
    }
  }

  return { initDownload, status: statusRef.current, downloadUrl, isGenerating };
};

export default useGetDownloadLink;
