import { useContext, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { Link as RouteLink } from "react-router-dom";
import {
  Breadcrumb as ChakraBreadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from "@chakra-ui/react";
import { ChevronRight16 } from "@carbon/icons-react";
import { AccessTokenContext } from "../context/contextProvider";
import { useGetDataRoomContentQuery } from "./service";
import { ILocation } from "./types";

type Props = {
  dataRoomId: string;
  state?: string;
};

const Breadcrumb = ({ dataRoomId, state }: Props) => {
  const intl = useIntl();
  const accessToken = useContext(AccessTokenContext).accessToken;

  const [breadcrumbs, setBreadcrumbs] = useState<ILocation[]>([]);

  const { data } = useGetDataRoomContentQuery(
    {
      accessToken,
      data_room_id: dataRoomId,
      state,
    },
    { skip: !dataRoomId }
  );

  const locations = useMemo(() => data?.location || [], [data]);

  useEffect(() => {
    if (!dataRoomId) {
      setBreadcrumbs([]);
    } else {
      setBreadcrumbs(locations);
    }
  }, [dataRoomId, state, locations]);

  const urlQueryParams = (dataRoomId: string, state?: string) => {
    const params: Record<string, string> = {
      data_room_id: dataRoomId,
    };
    if (state) {
      params.state = state;
    }
    return new URLSearchParams(params).toString();
  };

  return (
    <ChakraBreadcrumb
      spacing="8px"
      separator={<ChevronRight16 color="gray.500" />}
    >
      <BreadcrumbItem fontWeight="bold">
        <BreadcrumbLink as={RouteLink} to="/data-room">
          {intl.formatMessage({ id: "data_room_documents" })}
        </BreadcrumbLink>
      </BreadcrumbItem>
      {breadcrumbs.map((location, idx) => {
        return (
          <BreadcrumbItem key={idx} fontWeight="bold">
            <BreadcrumbLink
              as={RouteLink}
              to={`/data-room?${urlQueryParams(dataRoomId, location.state)}`}
            >
              {location.name}
            </BreadcrumbLink>
          </BreadcrumbItem>
        );
      })}
    </ChakraBreadcrumb>
  );
};

export default Breadcrumb;
