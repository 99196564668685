import React from "react";
import {
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
} from "@chakra-ui/react";
import { ITrend } from "./trend";

interface Props {
  label: string;
  value: string | JSX.Element;
  deltaValue?: string | JSX.Element;
  trend?: ITrend;
  w?: any;
  p?: any;
  borderColor?: any;
  borderTop?: any;
  borderBottom?: any;
  borderLeft?: any;
  borderRight?: any;
}

const DataStat = ({
  label,
  value,
  deltaValue,
  trend,
  w,
  p,
  borderColor,
  borderTop,
  borderBottom,
  borderLeft,
  borderRight,
}: Props) => {
  return (
    <>
      <Stat
        w={w}
        p={p}
        borderTopWidth={borderTop}
        borderBottomWidth={borderBottom}
        borderLeftWidth={borderLeft}
        borderRightWidth={borderRight}
        borderColor={borderColor}
        lineHeight="normal"
        fontFamily="condensed"
      >
        <StatLabel fontWeight="500">{label}</StatLabel>
        <StatNumber
          fontWeight="600"
          fontSize={{ base: "20px", lg: "20px" }}
          mt="3px"
        >
          {value}
        </StatNumber>
        {deltaValue && !trend && (
          <StatHelpText mb="0" mt="3px">
            {deltaValue}
          </StatHelpText>
        )}
        {deltaValue && trend && (
          <>
            {trend === "up" && (
              <StatHelpText mb="0" mt="3px">
                <StatArrow type="increase" color="#198038" />
                {deltaValue}
              </StatHelpText>
            )}
            {trend === "down" && (
              <StatHelpText mb="0" mt="3px">
                <StatArrow type="decrease" color="#DA1E28" />
                {deltaValue}
              </StatHelpText>
            )}
            {trend === "eq" && (
              <StatHelpText mb="0" mt="3px">
                <StatArrow type="increase" color="#198038" />
                {deltaValue}
              </StatHelpText>
            )}
          </>
        )}
      </Stat>
      {/* <Box
                w={w}
                p={p}
                borderTopWidth={borderTop}
                borderBottomWidth={borderBottom}
                borderLeftWidth={borderLeft}
                borderRightWidth={borderRight}
                borderColor={borderColor}
                lineHeight="normal">
                <Text fontSize="14px">{label}</Text>
                <Text fontSize={{ base: "16px", lg: "20px" }} fontWeight="bold" margin="3px 0">{value}</Text>
                {deltaValue && !trend && <Text fontSize="12px" fontWeight="600">{deltaValue}</Text>}
                {deltaValue && trend &&
                    <Box display="inline-flex" fontWeight="600" fontSize="12px" verticalAlign="top" lineHeight="normal" mt="6px">
                        {trend === 'up' && <Box display="inline-block" mt="-3px" ml="-6px"><CaretUp24 color="#198038" /></Box>}
                        {trend === 'down' && <Box display="inline-block" mt="-4px" ml="-6px"><CaretDown24 color="#DA1E28" /></Box>}
                        {trend === 'eq' && <Box display="inline-block" mt="-2px" ml="-2px" mr="4px"><PanHorizontal20 color="#198038" /></Box>}
                        <Text display="inline-block">{deltaValue}</Text>
                    </Box>
                }
            </Box> */}
    </>
  );
};

export default DataStat;
